
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Tpprogram = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
  }, []);

  const data = [
    {
      img: 'images/school_t.jpg',
      title: 'School Training',
      link: '/training',
      hoverContent: [
        'Crafting user-friendly mobile applications tailored to your needs, ensuring seamless functionality and engaging user experiences, and deploy changes with ease.',
      ],
    },
    {
      img: 'images/collage_t.jpg',
      title: 'College Training',
      link: '/training',
      hoverContent: [
        'Deliver great service experiences fast - without the complexity of traditional ITSM solutions. Accelerate critical development work, eliminate toil, and deploy changes with ease.',
      ],
    },
    {
      img: 'images/online_t.jpg',
      title: 'Online Training',
      link: '/training',
      hoverContent: [
        'Deliver great service experiences fast - without the complexity of traditional ITSM solutions. Accelerate critical development work, eliminate toil, and deploy changes with ease.',
      ],
    },
    {
      img: 'images/final_p.jpg',
      title: 'Project Training',
      link: '/training',
      hoverContent: [
       'Deliver fast, efficient service experiences without the complexity of traditional ITSM solutions. Streamline critical development tasks eliminate toil, and deploy changes with ease.',
      ],
    },
  ];

  return (
    <section className="py-4 bg-gray-100 px-4 sm:px-8 lg:px-16 ">
      <div className="py-8 md:py-12 bg-gray-100 place-items-center pt-2 lg:pt-2 mx-6">
        <h1 className="text-2xl md:text-4xl text-[#17212E] text-center py-1 font-times mb-10 mt-10 border-b-2 shadow-lg">
          TRAINING PROGRAM
        </h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 max-w-screen-xl mx-auto">
        {data.map((item, index) => (
          <div
            key={index}
            className="relative rounded-lg overflow-hidden transition duration-300 ease-in-out transform hover:scale-105"
            data-aos="fade-up"
          >
            <img className="object-cover w-full h-64" src={item.img} alt={item.title} />
            <div className="absolute inset-0 bg-gray-900 bg-opacity-90 text-white opacity-0 hover:opacity-100 transition-opacity duration-300 ease-in-out flex flex-col items-center justify-center p-6">
              <ul className="text-center space-y-2">
                {item.hoverContent.map((content, i) => (
                  <li key={i}>{content}</li>
                ))}
              </ul>
              <div className="mt-4 text-center">
                <Link to={item.link}>
                  <button className="px-4 py-2 bg-[#17212E] text-[#F5CF6B] ounded-lg hover:bg-[#F5CF6B] hover:text-black transition duration-300">
                    More →
                  </button>
                </Link>
              </div>
            </div>
            <div className="p-6 bg-white">
              <h2 className="text-xl font-bold text-[#17212E] mb-2 text-center">{item.title}</h2>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Tpprogram;

