import React from "react";
import { useParams } from "react-router-dom";

function SinglePrice() {
  const { id } = useParams();
  console.log(id);
  return (
    <div className="font-times md:pt-[50px]">
      {/* <h1 className='pt-[100px]'>hello</h1> */}

      {/* price card-1 */}
      {id === "1" && (
        <div className="grid grid-cols-3 max-sm:grid-cols-1 m-2  ">
          <div className="pt-[100px] flex justify-center ">
            <div className=" w-[350px] border-2   border-gray  mt-2 mb-2 max-sm:w-[320px] bg-white rounded-md p-2  transform transition-transform duration-500 ease-in-out hover:scale-105 shadow-lg overflow-hidden">
              {/* side compo */}
              <div>
                <h1 className="text-xl font-bold text-[#f5cf6b] bg-[#17212e] rounded-md mb-4 text-center">
                  Student Basic Plan{" "}
                </h1>
                <p className="text-gray-600 pb-4 text-justify ">
                  This package is mainly customized for students who willing to
                  do the courses like web development and app development
                </p>
                <p className="text-[#f5cf6b] bg-[#17212e] rounded-md text-lg font-bold mb-2 text-center">
                  What's Included
                </p>
                <h1 className="flex justify-between pl-2">
                  Website Development<span className="pr-2 ">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Website management<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Testing <span className="pr-2">✓</span>
                </h1>
                {/* <h1 className='flex justify-between pl-2'>Frontend development<span className='pr-2'>✓</span></h1>
              <h1 className='flex justify-between pl-2'>Backend development<span className='pr-2'>✓</span></h1>
              <h1 className='flex justify-between pl-2'>Deployment <span className='pr-2'>✓</span ></h1> */}
              </div>

              {/* rate card */}

              <div className="border-2 border-[#17212e] mt-[70px] text-center pt-[20px] bg-[#17212e] rounded-md">
                <div>
                  <h2 className="font-bold text-base text-white mb-6">
                    Get Your Package Right Now
                  </h2>
                  <h1 className="font-bold text-4xl text-[#f5cf6b] mb-12">
                    $50
                  </h1>
                  <button className="border-2 border-[#f5cf6b] text-lg mb-4 font-semibold rounded w-1/3 bg-[#f5cf6b] text-[#17212e]">
                    Subscribe
                  </button>
                  <p className="font-normal text-sm text-white mb-6">
                    you will get access to full Package{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-[100px] max-sm:pt-[20px] flex justify-center font-times">
            <div className=" w-1/3 border-2  border-gray  mb-2 mt-2 max-sm:w-[320px] bg-white rounded-md p-2 w-11/12 transform transition-transform duration-500 ease-in-out hover:scale-105 shadow-lg overflow-hidden">
              {/* side compo */}
              <div>
              <h1 className="text-xl font-bold text-[#f5cf6b] bg-[#17212e] rounded-md mb-4 text-center">
                  Student Professional Plan
                </h1>
                <p className="text-gray-600 pb-4 text-justify">
                  This package is mainly customized for students who willing to
                  do the courses like web development and app development
                </p>
                <p className="text-[#f5cf6b] bg-[#17212e] rounded-md text-lg font-semibold mb-2 text-center">
                  What's Included
                </p>
                <h1 className="flex justify-between pl-2">
                  Website Development<span className="pr-2 ">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Website management<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Testing <span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Frontend development<span className="pr-2">✓</span>
                </h1>
                {/* <h1 className='flex justify-between pl-2'>Backend development<span className='pr-2'>✓</span></h1>
             <h1 className='flex justify-between pl-2'>Deployment <span className='pr-2'>✓</span ></h1> */}
              </div>

              {/* rate card */}

              <div className="border-2 border-[#17212e] mt-[50px]  text-center pt-[20px] bg-[#17212e] rounded-md">
                <div>
                  <h2 className="font-bold text-base text-white mb-6">
                    Get Your Package Right Now
                  </h2>
                  <h1 className="font-bold text-4xl text-[#f5cf6b] mb-12">
                    $150
                  </h1>
                  <button className="border-2 border-[#f5cf6b] text-lg mb-4 font-semibold rounded w-1/3 bg-[#f5cf6b] text-[#17212e]">
                    Subscribe
                  </button>
                  <p className="font-normal text-sm text-white mb-6">
                    you will get access to full Package{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-[100px] max-sm:pt-[20px] flex justify-center font-times">
            <div className=" w-1/3 border-2  border-gray  mb-2 mt-2 bg-white rounded-md p-2 w-11/12 transform transition-transform duration-500 ease-in-out hover:scale-105 shadow-lg overflow-hidden">
              {/* side compo */}
              <div>
                <h1 className="text-xl font-bold text-[#f5cf6b] rounded-md bg-[#17212e] mb-4 text-center">
                  Student Premium Plan{" "}
                </h1>
                <p className="text-gray-600 pb-4 text-justify">
                  This package is mainly customized for students who willing to
                  do the courses like web development and app development
                </p>
                <p className="text-[#f5cf6b] bg-[#17212e] rounded-md text-lg font-semibold mb-2 text-center">
                  What's Included
                </p>
                <h1 className="flex justify-between pl-2">
                  Website Development<span className="pr-2 ">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Website management<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Testing <span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Frontend development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Backend development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Deployment <span className="pr-2">✓</span>
                </h1>
              </div>

              {/* rate card */}

              <div className="border-2 border-[#17212e]  text-center pt-[20px] bg-[#17212e] rounded-md">
                <div>
                  <h2 className="font-bold text-base text-white mb-6">
                    Get Your Package Right Now
                  </h2>
                  <h1 className="font-bold text-4xl text-[#f5cf6b] mb-12">
                    $250
                  </h1>
                  <button className="border-2 border-[#f5cf6b] text-lg mb-4 font-semibold rounded w-1/3 bg-[#f5cf6b] text-[#17212e]">
                    Subscribe
                  </button>
                  <p className="font-normal text-sm text-white mb-6">
                    you will get access to full Package{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {id === "2" && (
        <div className="grid grid-cols-3 max-sm:grid-cols-1 m-2">
          <div className="pt-[100px] flex justify-center">
            <div className=" w-[360px] border-2 border-gray  ml-2 mt-2 mb-2 bg-white rounded-md p-2  transform transition-transform duration-500 ease-in-out hover:scale-105 shadow-lg overflow-hidden">
              {/* side compo */}
              <div>
                <h1 className="text-xl font-bold text-[#f5cf6b] rounded-md bg-[#17212e] mb-4 text-center">
                  MSME Basic Plan{" "}
                </h1>
                <p className="text-gray-600 pb-4 text-justify">
                  This package is mainly customized for MSME company who
                  interested in web development and app development
                </p>
                <p className="text-[#f5cf6b] bg-[#17212e] rounded-md text-lg font-semibold mb-2 text-center">
                  What's Included
                </p>
                <h1 className="flex justify-between pl-2">
                  Website Development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Website management<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Testing <span className="pr-2">✓</span>
                </h1>
                {/* <h1 className='flex justify-between pl-2'>Frontend development<span className='pr-2'>✓</span></h1>
             <h1 className='flex justify-between pl-2'>Backend development<span className='pr-2'>✓</span></h1>
             <h1 className='flex justify-between pl-2'>Deployment <span className='pr-2'>✓</span ></h1> */}
              </div>

              {/* rate card */}

              <div className="border-2 border-[#17212e]  mt-[70px] text-center pt-[20px] bg-[#17212e] rounded-md">
                <div>
                  <h2 className="font-bold text-base text-white mb-6">
                    Build your site right now
                  </h2>
                  <h1 className="font-bold text-4xl text-[#f5cf6b] mb-12">
                    $150
                  </h1>
                  <button className="border-2 border-[#f5cf6b] text-lg mb-4 font-semibold rounded w-1/3 bg-[#f5cf6b] text-[#17212e]">
                    Subscribe
                  </button>
                  <p className="font-normal text-sm text-white mb-6">
                    you will be able get fully developed application{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-[100px] flex justify-center max-sm:pt-[20px]">
            <div className="w-[360px]  border-2 border-gray  ml-2 mt-2 mb-2 bg-white rounded-md p-2 w-11/12 transform transition-transform duration-500 ease-in-out hover:scale-105 shadow-lg overflow-hidden">
              {/* side compo */}
              <div>
                <h1 className="text-xl font-bold text-[#f5cf6b] rounded-md bg-[#17212e] mb-4 text-center">
                  MSME Professional Plan{" "}
                </h1>
                <p className="text-gray-600 pb-4 text-justify">
                  This package is mainly customized for MSME company who
                  interested in web development and app development
                </p>
                <p className="text-[#f5cf6b] bg-[#17212e] rounded-md text-lg font-semibold mb-2 text-center">
                  What's Included
                </p>
                <h1 className="flex justify-between pl-2">
                  Website Development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Website management<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Testing <span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Frontend development<span className="pr-2">✓</span>
                </h1>
                {/* <h1 className='flex justify-between pl-2'>Backend development<span className='pr-2'>✓</span></h1>
             <h1 className='flex justify-between pl-2'>Deployment <span className='pr-2'>✓</span ></h1> */}
              </div>

              {/* rate card */}

              <div className="border-2 border-[#17212e]  mt-[50px] text-center pt-[20px] bg-[#17212e] rounded-md">
                <div>
                  <h2 className="font-bold text-base text-white mb-6">
                    Build your site right now
                  </h2>
                  <h1 className="font-bold text-4xl text-[#f5cf6b] mb-12">
                    $250
                  </h1>
                  <button className="border-2 border-[#f5cf6b] text-lg mb-4 font-semibold rounded w-1/3 bg-[#f5cf6b] text-[#17212e]">
                    Subscribe
                  </button>
                  <p className="font-normal text-sm text-white mb-6">
                    you will be able get fully developed application{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-[100px] flex justify-center max-sm:pt-[20px]">
            <div className="w-[360px]  border-2 border-gray  ml-2 mt-2 mb-2 bg-white rounded-md p-2 w-11/12 transform transition-transform duration-500 ease-in-out hover:scale-105 shadow-lg overflow-hidden">
              {/* side compo */}
              <div>
                <h1 className="text-xl font-bold text-[#f5cf6b] rounded-md bg-[#17212e] mb-4 text-center">
                  MSEM Premium Plan{" "}
                </h1>
                <p className="text-gray-600 pb-4 text-justify">
                  This package is mainly customized for MSME company who
                  interested in web development and app development
                </p>
                <p className="text-[#f5cf6b] bg-[#17212e] rounded-md text-lg font-semibold mb-2 text-center">
                  What's Included
                </p>
                <h1 className="flex justify-between pl-2">
                  Website Development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Website management<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Testing <span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Frontend development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Backend development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Deployment <span className="pr-2">✓</span>
                </h1>
              </div>

              {/* rate card */}

              <div className="border-2 border-[#17212e]  text-center pt-[20px] bg-[#17212e] rounded-md">
                <div>
                  <h2 className="font-bold text-base text-white mb-6">
                    Build your site right now
                  </h2>
                  <h1 className="font-bold text-4xl text-[#f5cf6b] mb-12">
                    $350
                  </h1>
                  <button className="border-2 border-[#f5cf6b] text-lg mb-4 font-semibold rounded w-1/3 bg-[#f5cf6b] text-[#17212e]">
                    Subscribe
                  </button>
                  <p className="font-normal text-sm text-white mb-6">
                    you will be able get fully developed application{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {id === "3" && (
        <div className="grid grid-cols-3 max-sm:grid-cols-1 m-2">
          <div className="pt-[100px] flex justify-center">
            <div className=" w-[350px] border-2 border-gray  m-2 bg-white rounded-md p-2 w-11/12 transform transition-transform duration-500 ease-in-out hover:scale-105 shadow-lg overflow-hidden">
              {/* side compo */}
              <div>
                <h1 className="text-xl font-bold text-[#f5cf6b] rounded-md bg-[#17212e] mb-4 text-center">
                  Corporate-Basic Plan
                </h1>
                <p className="text-gray-600 pb-4 text-justify">
                  This package is mainly customized for Corporate company who
                  willing to do the courses like web development and app
                  development
                </p>
                <p className="text-[#f5cf6b] bg-[#17212e] rounded-md text-lg font-semibold mb-2 text-center">
                  What's Included
                </p>
                <h1 className="flex justify-between pl-2">
                  Website Development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Website management<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Testing <span className="pr-2">✓</span>
                </h1>
                {/* <h1 className='flex justify-between pl-2'>App development<span className='pr-2'>✓</span></h1>
              <h1 className='flex justify-between pl-2'>App testing<span className='pr-2'>✓</span></h1>
              <h1 className='flex justify-between pl-2'>Digitalmarketing <span className='pr-2'>✓</span ></h1> */}
              </div>

              {/* rate card */}

              <div className="border-2 border-[#17212e]  mt-[70px] text-center pt-[20px] bg-[#17212e] rounded-md">
                <div>
                  <h2 className="font-bold text-base text-white mb-6">
                    Get Your Package Right Now
                  </h2>
                  <h1 className="font-bold text-4xl text-[#f5cf6b] mb-12">
                    $250
                  </h1>
                  <button className="border-2 border-[#f5cf6b] text-lg mb-4 font-semibold rounded w-1/3 bg-[#f5cf6b] text-[#17212e]">
                    Subscribe
                  </button>
                  <p className="font-normal text-sm text-white mb-6">
                    you will get all in one package{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-[100px] flex justify-center max-sm:pt-[20px]">
            <div className=" w-1/3 border-2 border-gray  m-2 bg-white rounded-md p-2 w-11/12 transform transition-transform duration-500 ease-in-out hover:scale-105 shadow-lg overflow-hidden">
              {/* side compo */}
              <div>
                <h1 className="text-xl font-bold text-[#f5cf6b] rounded-md bg-[#17212e] mb-4 text-center">
                  Corporate Professional Plan{" "}
                </h1>
                <p className="text-gray-600 pb-4 text-justify">
                  This package is mainly customized for Corporate company who
                  willing to do the courses like web development and app
                  development
                </p>
                <p className="text-[#f5cf6b] bg-[#17212e] rounded-md text-lg font-semibold mb-2 text-center">
                  What's Included
                </p>
                <h1 className="flex justify-between pl-2">
                  Website Development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Website management<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Testing <span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  App development<span className="pr-2">✓</span>
                </h1>
                {/* <h1 className='flex justify-between pl-2'>App testing<span className='pr-2'>✓</span></h1>
              <h1 className='flex justify-between pl-2'>Digitalmarketing <span className='pr-2'>✓</span ></h1>
            */}
              </div>

              {/* rate card */}

              <div className="border-2 border-[#17212e]  mt-[50px] text-center pt-[20px] bg-[#17212e] rounded-md">
                <div>
                  <h2 className="font-bold text-base text-white mb-6">
                    Get Your Package Right Now
                  </h2>
                  <h1 className="font-bold text-4xl text-[#f5cf6b] mb-12">
                    $250
                  </h1>
                  <button className="border-2 border-[#f5cf6b] text-lg mb-4 font-semibold rounded w-1/3 bg-[#f5cf6b] text-[#17212e]">
                    Subscribe
                  </button>
                  <p className="font-normal text-sm text-white mb-6">
                    you will get all in one package{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-[100px] flex justify-center max-sm:pt-[20px]">
            <div className=" w-1/3  border-2 border-gray  m-2 bg-white rounded-md p-2 w-11/12 transform transition-transform duration-500 ease-in-out hover:scale-105 shadow-lg overflow-hidden">
              {/* side compo */}
              <div>
                <h1 className="text-xl font-bold text-[#f5cf6b] rounded-md bg-[#17212e] mb-4 text-center">
                  Corporate Premium Plan{" "}
                </h1>
                <p className="text-gray-600 pb-4 text-justify">
                  This package is mainly customized for Corporate company who
                  willing to do the courses like web development and app
                  development
                </p>
                <p className="text-[#f5cf6b] bg-[#17212e] rounded-md text-lg font-semibold mb-2 text-center">
                  What's Included
                </p>
                <h1 className="flex justify-between pl-2">
                  Website Development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Website management<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Testing <span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  App development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  App testing<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Digitalmarketing <span className="pr-2">✓</span>
                </h1>
              </div>

              {/* rate card */}

              <div className="border-2 border-[#17212e]  text-center pt-[20px] bg-[#17212e] rounded-md">
                <div>
                  <h2 className="font-bold text-base text-white mb-6">
                    Get Your Package Right Now
                  </h2>
                  <h1 className="font-bold text-4xl text-[#f5cf6b] mb-12">
                    $250
                  </h1>
                  <button className="border-2 border-[#f5cf6b] text-lg mb-4 font-semibold rounded w-1/3 bg-[#f5cf6b] text-[#17212e]">
                    Subscribe
                  </button>
                  <p className="font-normal text-sm text-white mb-6">
                    you will get all in one package{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {id === "4" && (
        <div className="grid grid-cols-3 max-sm:grid-cols-1 m-2">
          <div className="pt-[100px] flex justify-center">
            <div className=" w-[350px] border-2 border-gray  m-2 bg-white rounded-md p-2 w-11/12 transform transition-transform duration-500 ease-in-out hover:scale-105 shadow-lg overflow-hidden">
              {/* side compo */}
              <div>
                <h1 className="text-xl font-bold text-[#f5cf6b] rounded-md bg-[#17212e] mb-4 text-center">
                  Startup Basic Plan{" "}
                </h1>
                <p className="text-gray-600 pb-4 text-justify">
                  This package is mainly customized for Startup who willing to
                  do the courses like web development and app development
                </p>
                <p className="text-[#f5cf6b] bg-[#17212e] rounded-md text-lg font-semibold mb-2 text-center">
                  What's Included
                </p>
                <h1 className="flex justify-between pl-2">
                  Website Development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Website management<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Testing <span className="pr-2">✓</span>
                </h1>
                {/* <h1 className='flex justify-between pl-2'>App development<span className='pr-2'>✓</span></h1>
              <h1 className='flex justify-between pl-2'>Digital marketing<span className='pr-2'>✓</span></h1>
              <h1 className='flex justify-between pl-2'>Deployment <span className='pr-2'>✓</span ></h1>
            */}
              </div>

              {/* rate card */}

              <div className="border-2 border-[#17212e] mt-[70px] text-center pt-[20px] bg-[#17212e] rounded-md">
                <div>
                  <h2 className="font-bold text-base text-white mb-6">
                    Get Your Package Right Now
                  </h2>
                  <h1 className="font-bold text-4xl text-[#f5cf6b] mb-12">
                    $350
                  </h1>
                  <button className="border-2 border-[#f5cf6b] text-lg mb-4 font-semibold rounded w-1/3 bg-[#f5cf6b] text-[#17212e]">
                    Subscribe
                  </button>
                  <p className="font-normal text-sm text-white mb-6">
                    you will get all the startup package{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-[100px] flex justify-center max-sm:pt-[20px]">
            <div className=" w-1/3  border-2 border-gray  m-2 bg-white rounded-md p-2 w-11/12 transform transition-transform duration-500 ease-in-out hover:scale-105 shadow-lg overflow-hidden">
              {/* side compo */}
              <div>
                <h1 className="text-xl font-bold text-[#f5cf6b] rounded-md bg-[#17212e] mb-4 text-center">
                  Startup Professional Plan{" "}
                </h1>
                <p className="text-gray-600 pb-4 text-justify">
                  This package is mainly customized for Startup who willing to
                  do the courses like web development and app development
                </p>
                <p className="text-[#f5cf6b] bg-[#17212e] rounded-md text-lg font-semibold mb-2 text-center">
                  What's Included
                </p>
                <h1 className="flex justify-between pl-2">
                  Website Development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Website management<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Testing <span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  App development<span className="pr-2">✓</span>
                </h1>
                {/* <h1 className='flex justify-between pl-2'>Digital marketing<span className='pr-2'>✓</span></h1>
              <h1 className='flex justify-between pl-2'>Deployment <span className='pr-2'>✓</span ></h1> */}
              </div>

              {/* rate card */}

              <div className="border-2 border-[#17212e] mt-[50px] text-center pt-[20px] bg-[#17212e] rounded-md">
                <div>
                  <h2 className="font-bold text-base text-white mb-6">
                    Get Your Package Right Now
                  </h2>
                  <h1 className="font-bold text-4xl text-[#f5cf6b] mb-12">
                    $350
                  </h1>
                  <button className="border-2 border-[#f5cf6b] text-lg mb-4 font-semibold rounded w-1/3 bg-[#f5cf6b] text-[#17212e]">
                    Subscribe
                  </button>
                  <p className="font-normal text-sm text-white mb-6">
                    you will get all the startup package{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-[100px] flex justify-center max-sm:pt-[20px]">
            <div className=" w-1/3 border-2 border-gray  m-2 bg-white rounded-md p-2 w-11/12 transform transition-transform duration-500 ease-in-out hover:scale-105 shadow-lg overflow-hidden">
              {/* side compo */}
              <div>
                <h1 className="text-xl font-bold text-[#f5cf6b] rounded-md bg-[#17212e] mb-4 text-center">
                  Startup Premium Plan{" "}
                </h1>
                <p className="text-gray-600 pb-4 text-justify">
                  This package is mainly customized for Startup who willing to
                  do the courses like web development and app development
                </p>
                <p className="text-[#f5cf6b] bg-[#17212e] rounded-md text-lg font-semibold mb-2 text-center">
                  What's Included
                </p>
                <h1 className="flex justify-between pl-2">
                  Website Development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Website management<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Testing <span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  App development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Digital marketing<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Deployment <span className="pr-2">✓</span>
                </h1>
              </div>

              {/* rate card */}

              <div className="border-2 border-[#17212e]  text-center pt-[20px] bg-[#17212e] rounded-md">
                <div>
                  <h2 className="font-bold text-base text-white mb-6">
                    Get Your Package Right Now
                  </h2>
                  <h1 className="font-bold text-4xl text-[#f5cf6b] mb-12">
                    $350
                  </h1>
                  <button className="border-2 border-[#f5cf6b] text-lg mb-4 font-semibold rounded w-1/3 bg-[#f5cf6b] text-[#17212e]">
                    Subscribe
                  </button>
                  <p className="font-normal text-sm text-white mb-6">
                    you will get all the startup package{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {id === "5" && (
        <div className="grid grid-cols-3 max-sm:grid-cols-1 m-2">
          <div className="pt-[100px] flex justify-center">
            <div className="w-[350px] border-2 border-gray m-2 bg-white rounded-md p-2 w-11/12 transform transition-transform duration-500 ease-in-out hover:scale-105 shadow-lg overflow-hidden">
              {/* side compo */}
              <div>
                <h1 className="text-xl font-bold text-[#f5cf6b] rounded-md bg-[#17212e] mb-4 text-center">
                  Business Basic Plan{" "}
                </h1>
                <p className="text-gray-600 pb-4 text-justify">
                  This package is mainly customized for Business who likely to
                  purchase website and mobile applications
                </p>
                <p className="text-[#f5cf6b] bg-[#17212e] rounded-md text-lg font-semibold mb-2 text-center">
                  What's Included
                </p>
                <h1 className="flex justify-between pl-2">
                  Website Development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Website management<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Testing <span className="pr-2">✓</span>
                </h1>
                {/* <h1 className='flex justify-between pl-2'>App development<span className='pr-2'>✓</span></h1>
              <h1 className='flex justify-between pl-2'>Graphic design<span className='pr-2'>✓</span></h1>
              <h1 className='flex justify-between pl-2'>Deployment <span className='pr-2'>✓</span ></h1> */}
              </div>

              {/* rate card */}

              <div className="border-2 border-[#17212e]  mt-[70px] text-center pt-[20px] bg-[#17212e] rounded-md">
                <div>
                  <h2 className="font-bold text-base text-white mb-6">
                    Get Your Package Right Now
                  </h2>
                  <h1 className="font-bold text-4xl text-[#f5cf6b] mb-12">
                    $450
                  </h1>
                  <button className="border-2 border-[#f5cf6b] text-lg mb-4 font-semibold rounded w-1/3 bg-[#f5cf6b] text-[#17212e]">
                    Subscribe
                  </button>
                  <p className="font-normal text-sm text-white mb-6">
                    you will everything in this packages{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-[100px] flex justify-center max-sm:pt-[20px]">
            <div className=" w-1/3 border-2 border-gray  m-2 bg-white rounded-md p-2 w-11/12 transform transition-transform duration-500 ease-in-out hover:scale-105 shadow-lg overflow-hidden">
              {/* side compo */}
              <div>
                <h1 className="text-xl font-bold text-[#f5cf6b] rounded-md bg-[#17212e] mb-4 text-center">
                  Business Professional Plan
                </h1>
                <p className="text-gray-600 pb-4 text-justify">
                  This package is mainly customized for Business who likely to
                  purchase website and mobile applications
                </p>
                <p className="text-[#f5cf6b] bg-[#17212e] rounded-md text-lg font-semibold mb-2 text-center">
                  What's Included
                </p>
                <h1 className="flex justify-between pl-2">
                  Website Development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Website management<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Testing <span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  App development<span className="pr-2">✓</span>
                </h1>
                {/* <h1 className='flex justify-between pl-2'>Graphic design<span className='pr-2'>✓</span></h1>
              <h1 className='flex justify-between pl-2'>Deployment <span className='pr-2'>✓</span ></h1> */}
              </div>

              {/* rate card */}

              <div className="border-2 border-[#17212e] mt-[50px] text-center pt-[20px] bg-[#17212e] rounded-md">
                <div>
                  <h2 className="font-bold text-base text-white mb-6">
                    Get Your Package Right Now
                  </h2>
                  <h1 className="font-bold text-4xl text-[#f5cf6b] mb-12">
                    $450
                  </h1>
                  <button className="border-2 border-[#f5cf6b] text-lg mb-4 font-semibold rounded w-1/3 bg-[#f5cf6b] text-[#17212e]">
                    Subscribe
                  </button>
                  <p className="font-normal text-sm text-white mb-6">
                    you will everything in this packages{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-[100px] flex justify-center max-sm:pt-[20px]">
            <div className="w-1/3 border-2 border-gray max-sm:flex max-sm:flex-col m-2 bg-white rounded-md p-2 w-11/12 transform transition-transform duration-500 ease-in-out hover:scale-105 shadow-lg overflow-hidden">
              {/* side compo */}
              <div>
                <h1 className="text-xl font-bold text-[#f5cf6b] rounded-md bg-[#17212e] mb-4 text-center">
                  Business Premium Plan{" "}
                </h1>
                <p className="text-gray-600 pb-4 text-justify">
                  This package is mainly customized for Business who likely to
                  purchase website and mobile applications
                </p>
                <p className="text-[#f5cf6b] bg-[#17212e] rounded-md text-lg font-semibold mb-2 text-center">
                  What's Included
                </p>
                <h1 className="flex justify-between pl-2">
                  Website Development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Website management<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Testing <span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  App development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Graphic design<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Deployment <span className="pr-2">✓</span>
                </h1>
              </div>

              {/* rate card */}

              <div className="border-2 border-[#17212e]  text-center pt-[20px] bg-[#17212e] rounded-md">
                <div>
                  <h2 className="font-bold text-base text-white mb-6">
                    Get Your Package Right Now
                  </h2>
                  <h1 className="font-bold text-4xl text-[#f5cf6b] mb-12">
                    $450
                  </h1>
                  <button className="border-2 border-[#f5cf6b] text-lg mb-4 font-semibold rounded w-1/3 bg-[#f5cf6b] text-[#17212e]">
                    Subscribe
                  </button>
                  <p className="font-normal text-sm text-white mb-6">
                    you will everything in this packages{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {id === "6" && (
        <div className="grid grid-cols-3 max-sm:grid-cols-1 m-2">
          <div className="pt-[100px] flex justify-center">
            <div className=" w-[350px] border-2 border-gray  m-2 bg-white rounded-md p-2 w-11/12 transform transition-transform duration-500 ease-in-out hover:scale-105 shadow-lg overflow-hidden">
              {/* side compo */}
              <div>
                <h1 className="text-xl font-bold text-[#f5cf6b] rounded-md bg-[#17212e] mb-4 text-center">
                  Enterprise Package For you{" "}
                </h1>
                <p className="text-gray-600 pb-4 text-justify">
                  This package is mainly customized for students who willing to
                  do the courses like web development and app development
                </p>
                <p className="text-[#f5cf6b] bg-[#17212e] rounded-md text-lg font-semibold mb-2 text-center">
                  What's Included
                </p>
                <h1 className="flex justify-between pl-2">
                  Website development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Website management<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Testing <span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  App development<span className="pr-2">✓</span>
                </h1>
                {/* <h1 className='flex justify-between pl-2'>App testing<span className='pr-2'>✓</span></h1>
              <h1 className='flex justify-between pl-2'>Deployment <span className='pr-2'>✓</span ></h1>
              <h1 className='flex justify-between pl-2'>Devops <span className='pr-2'>✓</span ></h1>
              <h1 className='flex justify-between pl-2'>Graphic design <span className='pr-2'>✓</span ></h1> */}
              </div>

              {/* rate card */}

              <div className="border-2 border-[#17212e] mt-[100px] text-center pt-[20px] bg-[#17212e] rounded-md">
                <div>
                  <h2 className="font-bold text-base text-white mb-6">
                    Get Your Package Right Now
                  </h2>
                  <h1 className="font-bold text-4xl text-[#f5cf6b] mb-12">
                    $450
                  </h1>
                  <button className="border-2 border-[#f5cf6b] text-lg mb-4 font-semibold rounded w-1/3 bg-[#f5cf6b] text-[#17212e]">
                    Subscribe
                  </button>
                  <p className="font-normal text-sm text-white mb-6">
                    you will get all the offer{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-[100px] flex justify-center max-sm:pt-[20px]">
            <div className=" w-1/3 border-2 border-gray  m-2 bg-white rounded-md p-2 w-11/12 transform transition-transform duration-500 ease-in-out hover:scale-105 shadow-lg overflow-hidden">
              {/* side compo */}
              <div>
                <h1 className="text-xl font-bold text-[#f5cf6b] rounded-md bg-[#17212e] mb-4 text-center">
                  Enterprise Package For you{" "}
                </h1>
                <p className="text-gray-600 pb-4 text-justify">
                  This package is mainly customized for students who willing to
                  do the courses like web development and app development
                </p>
                <p className="text-[#f5cf6b] bg-[#17212e] rounded-md text-lg font-semibold mb-2 text-center">
                  What's Included
                </p>
                <h1 className="flex justify-between pl-2">
                  Website development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Website management<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Testing <span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  App development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  App testing<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Deployment <span className="pr-2">✓</span>
                </h1>
                {/* <h1 className='flex justify-between pl-2'>Devops <span className='pr-2'>✓</span ></h1>
              <h1 className='flex justify-between pl-2'>Graphic design <span className='pr-2'>✓</span ></h1> */}
              </div>

              {/* rate card */}

              <div className="border-2 border-[#17212e] mt-[50px]  text-center pt-[20px] bg-[#17212e] rounded-md">
                <div>
                  <h2 className="font-bold text-base text-white mb-6">
                    Get Your Package Right Now
                  </h2>
                  <h1 className="font-bold text-4xl text-[#f5cf6b] mb-12">
                    $450
                  </h1>
                  <button className="border-2 border-[#f5cf6b] text-lg mb-4 font-semibold rounded w-1/3 bg-[#f5cf6b] text-[#17212e]">
                    Subscribe
                  </button>
                  <p className="font-normal text-sm text-white mb-6">
                    you will get all the offer{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-[100px] flex justify-center max-sm:pt-[20px]">
            <div className=" w-1/3 border-2 border-gray max-sm:flex max-sm:flex-col m-2 bg-white rounded-md p-2 w-11/12 transform transition-transform duration-500 ease-in-out hover:scale-105 shadow-lg overflow-hidden">
              {/* side compo */}
              <div>
                <h1 className="text-xl font-bold text-[#f5cf6b] rounded-md bg-[#17212e] mb-4 text-center">
                  Enterprise Package For you{" "}
                </h1>
                <p className="text-gray-600 pb-4 text-justify">
                  This package is mainly customized for students who willing to
                  do the courses like web development and app development
                </p>
                <p className="text-[#f5cf6b] bg-[#17212e] rounded-md text-lg font-semibold mb-2 text-center">
                  What's Included
                </p>
                <h1 className="flex justify-between pl-2">
                  Website development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Website management<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Testing <span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  App development<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  App testing<span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Deployment <span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Devops <span className="pr-2">✓</span>
                </h1>
                <h1 className="flex justify-between pl-2">
                  Graphic design <span className="pr-2">✓</span>
                </h1>
              </div>

              {/* rate card */}

              <div className="border-2 border-[#17212e]  text-center pt-[20px] bg-[#17212e] rounded-md">
                <div>
                  <h2 className="font-bold text-base text-white mb-6">
                    Get Your Package Right Now
                  </h2>
                  <h1 className="font-bold text-4xl text-[#f5cf6b] mb-12">
                    $450
                  </h1>
                  <button className="border-2 border-[#f5cf6b] text-lg mb-4 font-semibold rounded w-1/3 bg-[#f5cf6b] text-[#17212e]">
                    Subscribe
                  </button>
                  <p className="font-normal text-sm text-white mb-6">
                    you will get all the offer{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SinglePrice;
