import React from "react";
import { Link } from "react-router-dom";

const SchoolCarousel = ({ slides }) => {
  return (
    <section className="py-8 bg-gray-100 px-4 sm:px-8 lg:px-16">
      <div className="text-center mb-12">
        <h1 className="text-2xl md:text-4xl font-extrabold text-[#17212E] font-times mb-8">
          School Training
        </h1>
        <p className="text-lg  md:font-semibold  text-gray-700 mb-6 mx-8 mt-4">
          Deliver great service experiences fast - without the complexity of
          traditional ITSM solutions. Accelerate critical development work,
          eliminate toil, and deploy changes with ease.
        </p>
        <div className="flex justify-center">
          <Link to="/form">
            <button className="mt-4 bg-[#17212E] text-[#F5CF6B] font-bold py-2 px-4 rounded-lg hover:bg-[#F5CF6B] hover:text-black">
              Explore Services
            </button>
          </Link>
        </div>
      </div>

      {/* Side-by-Side Section */}
      <div className="max-w-screen-xl mx-auto">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`flex flex-col md:flex-row items-center mb-10 ${
              index % 2 === 0 ? "md:flex-row-reverse" : "md:flex-row"
            }`}
            data-aos="fade-up"
          >
            <div className="w-full md:w-1/2 p-4">
              <img
                className="object-cover w-full h-64 md:h-80 rounded-lg shadow-lg"
                src={slide.image}
                alt={slide.title}
              />
            </div>
            <div className="w-full md:w-1/2 p-4 bg-white shadow-lg rounded-lg text-center md:text-left">
              <h2 className="text-2xl font-bold text-[#17212E] mb-2">
                {slide.title}
              </h2>
              <p className="text-center text-[#17212E] mb-4">{slide.description}</p>
              <Link to={slide.register}>
                <button className="px-4 py-2 bg-[#17212E] text-[#F5CF6B] rounded-lg hover:bg-[#F5CF6B] hover:text-black transition duration-300">
                  More →
                </button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SchoolCarousel;
