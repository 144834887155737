// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";

// const StepsNavigation = () => {
//   const data = [
//     {
//       to: "/IdeatoProduct",
//       title: "Idea To Product Development",
//       desc: "Transform your innovative ideas into tangible products through structured development processes.",
     
//     },
//     {
//       to: "/PatentandCopyrights",
//       title: "Patent And Copyrights",
//       desc: "Secure your intellectual property rights by obtaining patents and copyrights for your innovations.",
//     },
//     {
//       to: "/Registrations",
//       title: "Company Registration and Other Registration",
//       desc: "Register your company and other necessary registrations to establish a legal framework for your business.",
//     },
//     {
//       to: "/Fundings",
//       title: "Funding Preparation",
//       desc: "Prepare for funding rounds by organizing your financials and crafting compelling pitches for investors.",
//     },
//     {
//       to: "/InvestorConnect",
//       title: "Investor Connect",
//       desc: "Connect with potential investors to pitch your business idea and secure funding for growth.",
//     },
//   ];

//   const [currentIndices, setCurrentIndices] = useState([0, 1, 2]);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentIndices(([a, b, c]) => [
//         (a + 1) % data.length,
//         (b + 1) % data.length,
//         (c + 1) % data.length
//       ]);
//     }, 3000);

//     return () => clearInterval(interval); // Clean up the interval on component unmount
//   }, [data.length]);

//   return (
//     <section className="overflow-hidden py-8 dark:bg-dark lg:py-[120px]">
//       <div className="mx-auto px-4 sm:container">
//         <div className="bg-[#17212E] mb-14 py-4 px-20">
//           <h1 className="text-3xl text-[#F5CF6B] text-center font-times mb-0">LIST OF SERVICES</h1>
//         </div>
//         <div className="flex flex-wrap justify-center gap-4">
//           {currentIndices.map((index) => (
//             <div key={index} className="w-80 h-80 rounded-lg shadow-md p-4 bg-gradient-to-br from-black to-blue-300 hover:from-blue-100 hover:to-blue-400">
//               <SingleStepLink
//                 to={data[index].to}
//                 title={data[index].title}
//                 desc={data[index].desc}
//               />
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// const SingleStepLink = ({ to, title, desc }) => {
//   return (
//     <div className="bg-white rounded-lg shadow-md p-6 h-full flex flex-col justify-between">
//       <div>
//         <h2 className="text-lg font-bold text-2xl text-[#001a57] text-center mb-4">{title}</h2>
//         <div className="mb-4 mx-auto w-full h-1 bg-blue-900"></div>
//         <p className="text-sm text-center text-gray-700 text-justify">{desc}</p>
//       </div>
//       <div className="text-center mt-6">
//         <Link to={to}>
//           <button className="px-4 py-2 bg-[#001a57] text-white rounded-lg hover:bg-blue-800 transition duration-300">
//             Learn more
//           </button>
//         </Link>
//       </div>
//     </div>
//   );
// };

// export default StepsNavigation;
// import React from 'react';
// import { Link } from 'react-router-dom';
// import { FaLightbulb, FaCopyright, FaBuilding, FaMoneyCheck, FaHandshake } from 'react-icons/fa';

// const data = [
//   {
//     to: "/IdeatoProduct",
//     title: "Idea To Product Development",
//     desc: "Transform your innovative ideas into tangible products through structured development processes.",
//     icon: <FaLightbulb />
//   },
//   {
//     to: "/PatentandCopyrights",
//     title: "Patent And Copyrights",
//     desc: "Secure your intellectual property rights by obtaining patents and copyrights for your innovations.",
//     icon: <FaCopyright />
//   },
//   {
//     to: "/Registrations",
//     title: "Company Registration and Other Registration",
//     desc: "Register your company and other necessary registrations to establish a legal framework for your business.",
//     icon: <FaBuilding />
//   },
//   {
//     to: "/Fundings",
//     title: "Funding Preparation",
//     desc: "Prepare for funding rounds by organizing your financials and crafting compelling pitches for investors.",
//     icon: <FaMoneyCheck />
//   },
//   {
//     to: "/InvestorConnect",
//     title: "Investor Connect",
//     desc: "Connect with potential investors to pitch your business idea and secure funding for growth.",
//     icon: <FaHandshake />
//   },
// ];

// const ServiceCards = () => {
//   return (
//     <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
//         {data.map((service, index) => (
//           <Link
//             key={index}
//             to={service.to}
//             className="bg-white border border-gray-200 rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl"
//           >
//             <div className="p-8 flex flex-col items-center text-center">
//               <div className="text-indigo-500 mb-4 text-4xl">
//                 {service.icon}
//               </div>
//               <h2 className="text-xl font-semibold mb-2">{service.title}</h2>
//               <p className="text-gray-600 mb-4">{service.desc}</p>
//               <div className="text-indigo-500 hover:text-indigo-400 transition-colors">
//                 Learn More &rarr;
//               </div>
//             </div>
//           </Link>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ServiceCards;
import React from 'react';
import { Link } from 'react-router-dom';
import { FaRocket, FaBalanceScale, FaFileAlt, FaChartLine, FaUsers } from 'react-icons/fa';
import 'aos/dist/aos.css';
import AOS from 'aos';

// Initialize AOS
AOS.init();

const data = [
  {
    to: "/IdeatoProduct",
    title: "Idea To Product Development",
    desc: "Transform your innovative ideas into tangible products through structured development processes.",
    icon: <FaRocket />,
  },
  {
    to: "/PatentandCopyrights",
    title: "Patent And Copyrights",
    desc: "Secure your intellectual property rights by obtaining patents and copyrights for your innovations.",
    icon: <FaBalanceScale />,
  },
  {
    to: "/Registrations",
    title: "Company Registration and Other Registration",
    desc: "Register your company and other necessary registrations to establish a legal framework for your business.",
    icon: <FaFileAlt />,
  },
  {
    to: "/Fundings",
    title: "Funding Preparation",
    desc: "Prepare for funding rounds by organizing your financials and crafting compelling pitches for investors.",
    icon: <FaChartLine />,
  },
  {
    to: "/InvestorConnect",
    title: "Investor Connect",
    desc: "Connect with potential investors to pitch your business idea and secure funding for growth.",
    icon: <FaUsers />,
  },
];

const ServiceZigZag = () => {
  return (
    <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div className="space-y-12">
        {data.map((service, index) => (
          <div
            key={index}
            className={`flex flex-col md:flex-row ${index % 2 === 0 ? '' : 'md:flex-row-reverse'} items-center`}
            data-aos="fade-up" // AOS animation
            data-aos-duration="1000" // Duration of the animation
            data-aos-delay={`${index * 200}`} // Delay based on index
          >
            <div className="text-[#17212E] text-4xl md:text-6xl lg:text-7xl p-4 md:p-6 lg:p-8 flex-shrink-0">
              {service.icon}
            </div>
            <div className="md:w-1/2 p-4 md:p-6 lg:p-8 text-center md:text-left">
              <Link to={service.to} className="block">
                <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-2">{service.title}</h2>
                <p className="text-gray-600 text-base md:text-lg lg:text-xl mb-4">{service.desc}</p>
                <div className="text-[#F5CF6B] font extra-bold  transition-colors text-sm md:text-base lg:text-lg">
                  Learn More &rarr;
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceZigZag;
