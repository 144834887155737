import React, { useEffect } from 'react';

const LoadingScreen = ({ setLoading }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [setLoading]);

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="loader">Loading...</div>
    </div>
  );
};

export default LoadingScreen;
