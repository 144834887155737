import React from 'react';

const MainPage = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="flex flex-col space-y-10 sm:space-y-0 sm:flex-row sm:space-x-10 p-10 bg-white shadow-md rounded-md animate-fadeIn">
        <div className="w-80 p-5 bg-Darkblue rounded-md">
          <h2 className="text-2xl font-bold mb-4 text-Yellow">Login</h2>
          <form>
            <input type="text" placeholder="Username" className="mb-3 p-2 w-full rounded" />
            <input type="password" placeholder="Password" className="mb-3 p-2 w-full rounded" />
            <button className="p-2 w-full bg-Yellow text-Darkblue rounded">Login</button>
          </form>
        </div>
        <div className="w-80 p-5 bg-Darkblue rounded-md">
          <h2 className="text-2xl font-bold mb-4 text-Yellow">Sign In</h2>
          <form>
            <input type="text" placeholder="Username" className="mb-3 p-2 w-full rounded" />
            <input type="password" placeholder="Password" className="mb-3 p-2 w-full rounded" />
            <input type="password" placeholder="Confirm Password" className="mb-3 p-2 w-full rounded" />
            <button className="p-2 w-full bg-Yellow text-Darkblue rounded">Sign In</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
