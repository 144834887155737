import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import Aos from "aos";
import "aos/dist/aos.css";
import {
  FaUserGraduate,
  FaBriefcase,
  FaMoneyBillWave,
  FaBuilding,
  FaSuitcase,
  FaArrowLeft,
  FaArrowRight,
} from "react-icons/fa";
import { CiCircleCheck } from "react-icons/ci";
import enterprise from "../pageimg/enterprise.jpg";
import benefit from "../pageimg/benefit.png";
import management from "../pageimg/management.png";
import analyse from "../pageimg/analyse.png";
import Student from "../pageimg/Student.png";
import startuppp from "../pageimg/startuppp.jpg";
import enhance from "../pageimg/enhance2.jpg";
import specialised from "../pageimg/specialised.webp";
import understand from "../pageimg/understand.webp";
import ss from "../pageimg/ss.jpg";
import MSME from "../pageimg/MSME.jpg";
import corporatee from "../pageimg/corporatee.jpg";
import Headlinepackage from "./Headlinepackage";
import Footer from "../UIcomp/Footer";
import cc from "./cc.jpg";
import "./Package.css"; // Import your custom CSS for styling
import img from "../pageimg/edit.jpg";
import g from "../pageimg/business.jpg";
import h from "../pageimg/bus.jpg";
import b from "../pageimg/b.jpg";
import { BrowserRouter, Route, Router, Link } from "react-router-dom";
const Package = () => {
  const sliderRef = useRef(null);
  useEffect(() => {
    Aos.init();
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows:false,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024, // screens larger than 1024px
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // screens larger than 768px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          fade: true,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };
  // text-5xl mt-3 font-bold py-60 text-[#f5cf6b]  text-center py-6
  const pricingSection = [
    {
      id: 1,
      rate: 50,
      name: "Student",
      offers: ["website development", "website management", "Testing"],
      img: Student,
    },
    {
      id: 2,
      rate: 150,
      name: "MSME Company",
      offers: ["website development", "website management", "Testing"],
      img: MSME,
    },
    {
      id: 3,
      rate: 250,
      name: "Corporate",
      offers: ["website development", "website management", "Testing"],
      img: corporatee,
    },
    {
      id: 4,
      rate: 350,
      name: "Startup",
      offers: ["website development", "website management", "Testing"],
      img: startuppp,
    },
    {
      id: 5,
      rate: 450,
      name: "Business",
      offers: ["website development", "website management", "Testing"],
      img: ss,
    },
    {
      id: 6,
      rate: 450,
      name: "Enterprise",
      offers: ["website development", "website management", "Testing"],
      img: enterprise,
    },
  ];
  return (
    <section className="package-section text-justify grid md:pt-[100px]">
      <div className="parallax-container ">
        <div className="parallax-content text-white ">
          <h1 className="heroText1 text-[#f5cf6b] text-center">
            Design & Development
          </h1>
          <p className=" heroText2 text-[#ffffff] ">
            Explore Our Ranges Of Specialized Packages
          </p>
          {/* <p className="text-lg  text-white  heroText3 ">
            Being a Mobile app,Web-development company,we help Startups,
            Mid-Size, and Enterprise businesses to get their digital products
            done the right way on time, in budget.
          </p> */}
        </div>
        {/* Assume cc is your background image imported */}
        <div
          className="parallax-bg "
          // style={{ backgroundImage: `url(${cc})` }}
        ></div>
      </div>

      {/* card section */}
      {/* className="grid grid-cols-1 md:grid-cols-3 sm:flex gap-4 lg:flex justify-evenly max-sm:ml-5  gap-5 mt-10 mb-10" */}

      {/* <div
        data-aos="fade-up"
        className="grid grid-cols-1 md:grid-cols-3 sm:flex gap-4 lg:flex justify-evenly max-sm:ml-5  gap-5 mt-10 mb-10 ml-2 mr-2"
      >
        <div className="package-card cursor-pointer  rounded-lg overflow-hidden  shadow-lg transform transition-transform duration-500 ease-in-out hover:scale-105 ">
          <img
            src={g}
            alt="Image 1"
            className="w-full h-48 md:h-56 object-cover"
          />
          <div className="p-4">
            <h2 className="text-xl text-[#f5cf6b] font-bold text-center mb-2 pb-6">
              Discover the Perfect Package for You
            </h2>
            <p className="text-sm text-gray-600 text-justify text-white">
              Explore our diverse range of tailored packages designed to meet
              your unique needs. Whether you're a student, startup, or
              established enterprise, find the ideal solution that fits your
              requirements and budget.
            </p>
          </div>
        </div>

        <div className="package-card cursor-pointer  rounded-lg overflow-hidden  shadow-lg transform transition-transform duration-500 ease-in-out hover:scale-105">
          <img
            src={h}
            alt="Image 2"
            className="w-full h-48 md:h-56 object-cover"
          />
          <div className="p-4">
            <h2 className="text-xl text-[#f5cf6b] font-bold text-center mb-2">
              Customizable Service Packages for you
            </h2>
            <p className="text-sm text-gray-600 text-justify text-white">
              Tailor your services precisely to your business needs with our
              customizable packages. From basic essentials to advanced
              solutions, choose the features and options that best suit your
              goals and objectives.
            </p>
          </div>
        </div>

        <div className="package-card cursor-pointer  rounded-lg overflow-hidden  shadow-lg transform transition-transform duration-500 ease-in-out hover:scale-105">
          <img
            src={b}
            alt="Image 3"
            className="w-full h-48 md:h-56 object-cover"
          />
          <div className="p-4">
            <h2 className="text-xl text-[#f5cf6b] font-bold text-center mb-2 pb-6">
              Complete Solutions in One Package
            </h2>
            <p className="text-sm text-gray-600 text-justify text-white">
              Simplify your operations with our comprehensive packages that
              offer everything you need in one convenient solution. Enjoy
              seamless integration and expert support to enhance your business
              efficiency and growth.
            </p>
          </div>
        </div>
      </div> */}

      {/* <Slider {...settings}>
        <div className="bg-gray-100 cursor-pointer  rounded-lg overflow-hidden  shadow-lg transform transition-transform duration-500 ease-in-out hover:scale-105 md:h-90 w-80 font-times">
          <img
            src={g}
            alt="Image 1"
            className="w-full h-48 md:h-56 object-cover"
          />
          <div className="p-4">
            <h2 className="text-xl text-[#17212e] font-bold text-center mb-2">
              Discover the Perfect Package for You
            </h2>
            <p className="text-sm text-gray-600 text-justify">
              Explore our diverse range of tailored packages designed to meet
              your unique needs. Whether you're a student, startup, or
              established enterprise, find the ideal solution that fits your
              requirements and budget.
            </p>
          </div>
        </div>

      
        </Slider>
        {/* <div className="bg-gray-100 rounded-lg  cursor-pointer overflow-hidden shadow-lg  transform transition-transform duration-500 ease-in-out hover:scale-105 md:h-90 w-80"> */}
      {/* <img
            src={h}
            alt="Image 2"
            className="w-full h-48 md:h-56 object-cover"
          />
          <div className="p-4">
            <h2 className="text-xl text-[#17212e] font-bold text-center mb-2">
              Customizable Service Packages for you
            </h2>
            <p className="text-sm text-gray-600 text-justify">
              Tailor your services precisely to your business needs with our
              customizable packages. From basic essentials to advanced
              solutions, choose the features and options that best suit your
              goals and objectives.
            </p>
          </div>
        </div> */}

      {/* <div className="bg-gray-100 rounded-lg  cursor-pointer overflow-hidden shadow-lg  transform transition-transform duration-500 ease-in-out hover:scale-105 md:h-90 w-80">
          <img
            src={b}
            alt="Image 3"
            className="w-full h-48 md:h-56 object-cover"
          />
          <div className="p-4">
            <h2 className="text-xl text-[#17212e] font-bold text-center mb-2">
              Complete Solutions in One Package
            </h2>
            <p className="text-sm text-gray-600 text-justify">
              Simplify your operations with our comprehensive packages that
              offer everything you need in one convenient solution. Enjoy
              seamless integration and expert support to enhance your business
              efficiency and growth.
            </p>
          </div>
        </div> */}
      <div className="mt-4 grid ">
        {" "}
        <h1 className="bg-[#17212e] text-[#f5cf6b] text-xl py-2 font-bold text-center rounded-md">
          How Do We Proceed
        </h1>
        <div className="flex justify-evenly  mt-10 mb-6   cursor-pointer  max-sm:flex-col max-sm:ml-[30px] ">
          <div className=" flex h-[20px] w-[300px] transform transition-transform duration-500 ease-in-out hover:scale-105 ">
            <img src={understand} className="proceedImg mb-2" />
            <p className="text-[#17212e] text-md font-semibold mt-2 ">
              Understand your needs and based on your requirements research will
              be done{" "}
            </p>
          </div>
          <div className="flex h-[20px] w-[300px] max-sm:mt-[100px] transform transition-transform duration-500 ease-in-out hover:scale-105 ">
            <img src={analyse} className="proceedImg bg-white mb-2" />
            <p className="text-[#17212e] text-md font-semibold mt-2">
              Analyse our research and develope your application with our
              specialised team{" "}
            </p>
          </div>
          {/* <div className="w-[200px] h-[100px] shadow-xl mb-2">
            <img  src={management} className="bg-white rounded-md" />
            <p className="text-[#17212e] text-md font-semibold">Keep on monitoring your application and Manage it to fulfill your needs </p>
          </div>  */}
          <div className="flex  h-[20px] w-[300px] mb-2 max-sm:mt-[100px] transform transition-transform duration-500 ease-in-out hover:scale-105">
            <img src={enhance} className=" proceedImg mb-2" />
            <p className="text-[#17212e] text-md font-semibold mt-2">
              Enhance your growth in the digital platform by delivering you a
              suitable Package{" "}
            </p>
          </div>
        </div>
      </div>

      {/* parallax image */}
      <div className="parallax2 mt-20">
        <div className="aboutDesign">
          <h1 className="aboutDesign1" data-aos="fade-up">
            Design is not just how it looks like and feels like.
          </h1>
          <h1 className="aboutDesign2" data-aos="fade-right">
            Design is how it Works
          </h1>
        </div>
        <h1 className="aboutDesign3" data-aos="fade-down">
          Time to make your visions grow and develope in the Digital platform
        </h1>
      </div>
      {/* package section */}

      {/* <div className="relative hidden md:inline-block">
        <div className="flex items-center justify-between mb-4 ml-10">
          <button
            className="bg-gray-500 text-white px-3 py-2 rounded-md shadow-md mr-4 transition duration-300"
            onClick={goToPrev}
          >
            <FaArrowLeft className="text-xl" />
          </button>
          <button
            className="bg-gray-500 text-white px-3 py-2 rounded-md shadow-md transition duration-300 ml-[1350px]"
            onClick={goToNext}
          >
            <FaArrowRight className="text-xl" />
          </button>
        </div>
      </div> */}

      {/* Pricing section */}

      <div className="pricingSection">
        <div className="priceTitle ">
          <h1 className="text-xl font-bold py-2 text-[#f5cf6b] ">
            List Of Packages
          </h1>
          {/* <p className="slogan">
            We provide fair and actual price for the package you choose and we
            will engage in building your requirements with more inttelligent and
            experienced team members
          </p> */}
        </div>
      </div>
      {/* price card section */}
      {/* <div className="priceContainer grid lg:grid-cols-3 md:grid-cols-2 mt-[10px] ml-[10px] mb-[20px] max-sm:ml-[20px]"> */}
      <div className="container mx-auto  my-10  shadow-lg priceCard rounded-lg  ">
        {/* price-1 */}
        <Slider {...settings}>
          {pricingSection.map((price) => {
            return (
              <div
                className=" bg-[#17212e] rounded-xl shadow-xl"
                key={price.id}
                data-aos="fade-right"
              >
                {/* <button className=" cursor-text popu">Popular</button> */}
                {/* <h1 className="offer">50% OFF</h1> */}
                <div className="priceCard2">
                  <div className="priceTop text-center ">
                    <img
                      src={price.img}
                      className="object-cover w-full  h-48 "
                    />
                    <h1 className="mt-[10px] text-[30px] font-bold text-[#f5cf6b] max-sm:text-[20px]">
                      {price.name}
                    </h1>
                  </div>
                  <div className="priceBody  flex justify-center ">
                    <Link to={`/Package/singlePrice/${price.id}`}>
                      {" "}
                      <button className="text-md font-semibold cursor-pointer border-2px border-[#f5cf6b] bg-[#f5cf6b] rounded-2xl h-[30px]  px-2 w-[200px] mb-[10px] mt-[20px]">
                        View More...{" "}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>

      {/* <h1 className="bg-[#17212e] text-[#f5cf6b] text-xl py-2 font-bold text-center rounded-md">
        What do you benefit from us
      </h1> */}
      <div className="parallax3  ">
      <h1 className=" text-[#f5cf6b] text-4xl  font-bold text-center mt-[20px] max-sm:text-xl max-sm:font-normal " data-aos="fade-down">
        What do you benefit from us
      </h1>
     
        <p className="font-bold text-lg text-center text-[#ffffff] max-sm:text-sm max-sm:font-thin mb-[30px] max-sm:mb-[10px] " data-aos="fade-right">
          We have a huge team of IT specialists, designers, developers, and
          engineers those who make your application Professional.
        </p>
        {/* <p className="font-bold text-lg text-center text-[#ffffff] mb-[30px] max-sm:text-sm max-sm:font-thin ">experience in software design,
          development, testing, quality maintenance, and research in several
          industries.</p> */}
          
        <div className="ml-[20px] max-sm:ml-[0px] flex justify-evenly max-sm:flex-col max-sm:items-center" data-aos="fade-up" data-aos-duration="2000">
          <div className="border-2 border-[#f5cf6b] max-sm:w-[250px] font-semibold bg-[#f5cf6b] text-[#17212e] text-center flex items-center justify-center h-[80px] w-[150px] rounded-md max-sm:mb-[10px]">
            <p>Relaible Services</p>
          </div>
          <div className="border-2 border-[#f5cf6b] max-sm:w-[250px] font-semibold bg-[#f5cf6b] text-[#17212e] text-center flex items-center justify-center h-[80px] w-[150px] rounded-md max-sm:mb-[10px]">
            <p>Accelerated Process</p>
          </div>
          <div className="border-2 border-[#f5cf6b] max-sm:w-[250px] font-semibold bg-[#f5cf6b] text-[#17212e] text-center flex items-center justify-center h-[80px] w-[150px] rounded-md max-sm:mb-[10px]">
            <p>Professional Developers</p>
          </div>
          <div className="border-2 border-[#f5cf6b] max-sm:w-[250px] font-semibold bg-[#f5cf6b] text-[#17212e] text-center flex items-center justify-center h-[80px] w-[150px] rounded-md max-sm:mb-[10px]">
            <p>Customized Package</p>
          </div>
          <div className="border-2 border-[#f5cf6b] max-sm:w-[250px] font-semibold bg-[#f5cf6b] text-[#17212e] text-center flex items-center justify-center h-[80px] w-[150px] rounded-md max-sm:mb-[10px]">
            <p>Scalable Solutions</p>
          </div>
          <div className="border-2 border-[#f5cf6b] max-sm:w-[250px] font-semibold bg-[#f5cf6b] text-[#17212e] text-center flex items-center justify-center h-[80px] w-[150px] rounded-md max-sm:mb-[10px]">
            <p>Modular Designs</p>
          </div>
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default Package;
