
import { Link } from "react-router-dom";

const ParallaxEffect = () => {
	return (
		<div className="w-full bg-parallaxImg bg-cover bg-no-repeat bg-center bg-fixed h-[400px] font-times" style={{ marginTop: "0px" }}>
			<div className="h-full bg-[#738fd67a] flex flex-col items-center justify-center">
				{/* Title */}
				<h1 className="text-3xl sm:text-5xl md:text-6xl  text-[#FFFFFF] mb-4">
					Our Services
				</h1>
				
				{/* Existing Content */}
				<p className="text-[#17212E]  mt-4 font-bold  text-2xl sm:text-4xl text-2xl text-center ">
					We provide high quality services & innovative solutions for the
					reliable growth
				</p>
				<Link to={"/Altruistyservices"}>
					<button className="px-6 h-8 sm:pb-2 mr-2  md:h-10 bg-[#17212E] mt-16 rounded-lg text-xl text-[#F5CF6B]  font-semibold hover:bg-[#F5CF6B] hover:text-[#17212E]  font-times">
						Explore our Services
					</button>
				</Link>
			</div>
		</div>
	);
};

export default ParallaxEffect;
