import React, { useEffect, useState } from "react";
import WhatsAppStickyButton from "../chunkpages/WhatsappStickyButton";
import CardSlider from "./Events and Competitions/CardSlider";
import { createClient } from "contentful";
import { Link } from "react-router-dom";
import { HostAnEvent } from "./Events and Competitions/HostAnEvent";
import Footer from "../UIcomp/Footer";

function EventsAndCompetition() {
	const [upcomingEvents, setUpcomingEvents] = useState([]);
	const [pastEvents, setPastEvents] = useState([]);

	const client = createClient({
		space: process.env.REACT_APP_SPACE_ID,
		accessToken: process.env.REACT_APP_ACCESS_TOKEN,
	});

	useEffect(() => {
		const getAllEntries = async () => {
			try {
				const entries = await client.getEntries();
				const currentDate = new Date();
				const upcoming = [];
				const past = [];
				entries.items.forEach((event) => {
					const eventDate = new Date(event.fields.eventDate);
					if (eventDate > currentDate) {
						upcoming.push(event);
					} else {
						past.push(event);
					}
				});

				setUpcomingEvents(upcoming);
				setPastEvents(past);
			} catch (error) {
				console.log(`Error fetching events: ${error}`);
			}
		};
		getAllEntries();
	}, []);

	function formatDate(timestamp) {
		const date = new Date(timestamp);
		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		const day = date.getDate();

		const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
			day < 10 ? "0" : ""
		}${day}`;

		return formattedDate;
	}

	return (
		<div className=" font-times">
			<CardSlider />

			<div className="text-black  text-center px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-semibold font-mono">
				The <span className="text-[#001a57]">Events</span> Competition{" "}
				<span className="text-[#001a57]">Arena</span>
			</div>

			<div className="text-white flex justify-center mt-4 gap-8 font-semibold px-4 md:px-12 lg:px-24">
				<Link to={"/allUpcomingEvents"}>
					<button className="border-white bg-[#001a57] border-2 p-2 w-auto rounded-lg hover:bg-slate-200 hover:bg-opacity-30 sm:w-56">
						Upcoming Events
					</button>
				</Link>
				<Link to={"/allPastEvents"}>
					<button className="border-white bg-[#001a57] border-2 p-2 w-auto rounded-lg hover:bg-slate-200 hover:bg-opacity-30 sm:w-56">
						View All Past Events
					</button>
				</Link>
			</div>

			<section className="py-10">
				<div className="relative mx-auto px-4 md:px-12 lg:px-24 ">
					<div className="text-3xl font-semibold text-[#001a57] py-4 text-center mt-8 font-mono">
						Upcoming Events
					</div>
					<div className="flex flex-col gap-8 justify-center items-center lg:flex-row ">
						{upcomingEvents.slice(0, 2).map((event) => (
							<div
								key={event.sys.id}
								className="bg-slate-100 rounded-lg xl:w-[45%] w-full h-48"
							>
								<div className="flex">
									<img
										className="h-48 object-cover w-5/12 rounded-l-lg rounded-b-none md:rounded-b-lg"
										src={event.fields.eventImage.fields.file.url}
										alt={event.fields.title}
									/>
									<div className="p-4 w-7/12">
										<div className="uppercase tracking-wide text-sm text-[#001a57] font-semibold">
											{event.fields.title}
										</div>
										<div className="tracking-wide text-xs text-gray-500 font-semibold">
											{formatDate(event.fields.eventDate)}
										</div>
										<p className="mt-3 leading-tight text-black text-sm line-clamp-6">
											{event.fields.description}
										</p>
									</div>
								</div>
							</div>
						))}
					</div>
					{upcomingEvents.length > 2 && (
						<div className="flex justify-center w-full">
							<Link to={"/allUpcomingEvents"}>
								<button className="bg-[#001a57] text-black hover:bg-opacity-30 rounded-lg m-8 h-10 w-32 border border-black font-semibold">
									See More
								</button>
							</Link>
						</div>
					)}
					{upcomingEvents.length < 1 && (
						<div className="text-lg font-semibold text-[#001a57] py-4 text-center font-mono">
							No Events , Visit us Later
						</div>
					)}
				</div>

				<div className="text-3xl font-semibold text-[#001a57] py-4 text-center mt-14 font-mono">
					Past Events
				</div>
				<div className="relative mx-auto px-4 md:px-12 lg:px-24 ">
					<div className="flex flex-col gap-8 justify-center items-center lg:flex-row">
						{pastEvents.slice(0, 2).map((event) => (
							<div
								key={event.sys.id}
								className="bg-slate-100 rounded-lg xl:w-[45%] w-full h-48"
							>
								<div className="flex">
									<img
										className="h-48 object-cover w-5/12 rounded-l-lg rounded-b-none md:rounded-b-lg"
										src={event.fields.eventImage.fields.file.url}
										alt={event.fields.title}
									/>
									<div className="p-4 w-7/12">
										<div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
											{event.fields.title}
										</div>
										<div className="tracking-wide text-xs text-gray-500 font-semibold">
											{formatDate(event.fields.eventDate)}
										</div>
										<p className="mt-3 leading-tight text-black text-sm line-clamp-6">
											{event.fields.description}
										</p>
									</div>
								</div>
							</div>
						))}
					</div>
					{pastEvents.length > 2 && (
						<div className="flex justify-center w-full">
							<Link to={"/allPastEvents"}>
								<button className="text-white bg-[#001a57]  hover:bg-opacity-30 rounded-lg m-8 h-10 w-32 border border-black font-semibold">
									See More
								</button>
							</Link>
						</div>
					)}
					{pastEvents.length < 1 && (
						<div className="text-lg font-semibold text-black py-4 text-center font-mono">
							No Past Events , Visit us Later
						</div>
					)}
				</div>

				<HostAnEvent />
				<WhatsAppStickyButton />
				<Footer/>
			</section>
		</div>
	);
}

export default EventsAndCompetition;
