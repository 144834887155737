// import React, { useEffect, useState } from 'react';
// import backgroundImage from './trainbanner.png'; // Adjust the path based on your project structure

// function Banner() {
//   const [firstWordLetters, setFirstWordLetters] = useState([]);
//   const [secondWordLetters, setSecondWordLetters] = useState([]);

//   useEffect(() => {
//     const firstWord = "Training";
//     const secondWord = "Program";

//     const firstWordArray = firstWord.split('').map((char, index) => ({
//       char,
//       style: { animationDelay: `${index * 0.1}s` },
//     }));

//     const secondWordArray = secondWord.split('').map((char, index) => ({
//       char,
//       style: { animationDelay: `${(firstWord.length + index) * 0.1}s` },
//     }));

//     setFirstWordLetters(firstWordArray);
//     setSecondWordLetters(secondWordArray);
//   }, []);

//   return (
//     <div className="relative mt-1 w-full h-[300px] md:h-[480px] bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: `url(${backgroundImage})` }}>
//       <div className="absolute inset-0 bg-slate-900 opacity-50"></div>
//       <div className="absolute w-full text-center">
//         <h1 className="text-4xl md:text-7xl md:mb-3 font-extrabold text-[#FFFF] flex justify-center">
//           {firstWordLetters.map((letter, index) => (
//             <span key={index} className="inline-block opacity-0 animate-fade-in" style={letter.style}>
//               {letter.char}
//             </span>
//           ))}
//           <span className="mx-2 md:mx-3"></span>
//           {secondWordLetters.map((letter, index) => (
//             <span key={index} className="inline-block opacity-0 animate-fade-in" style={letter.style}>
//               {letter.char}
//             </span>
//           ))}
//         </h1>
//       </div>
//     </div>
//   );
// }

// export default Banner;

import { Link } from "react-router-dom";
import backgroundImage from './trainbanner.png';

const ServiceBanner = () => {
  const firstWord = "Training";
  const secondWord = "Program";


  const createLetterObjects = (word) => {
    return word.split("").map((char, index) => ({
      char,
      style: {
        animationDelay: `${index * 0.1}s`,
      },
    }));
  };

  const firstWordLetters = createLetterObjects(firstWord);
  const secondWordLetters = createLetterObjects(secondWord);


  return (
    <div className="relative w-full md:h-[450px] h-[300px] md:h-[750px] flex items-center  justify-center overflow-hidden">
      <div className="absolute w-full h-full bg-cover bg-center animate-zoomIn" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
      <div className="absolute w-full h-full bg-black opacity-40 backdrop-blur-md"></div>
      <div className="absolute w-full text-center z-10">
        <h1 className="md:mt-0 mt-7 text-2xl md:text-7xl md:mb-36 pb-16  font-extrabold font-times text-white flex justify-center">
          {firstWordLetters.map((letter, index) => (
            <span key={index} className="inline-block opacity-0 animate-fade-in" style={letter.style}>
              {letter.char}
            </span>
          ))}
          <span className="mx-2 md:mx-3"></span>
          {secondWordLetters.map((letter, index) => (
            <span key={index} className="inline-block opacity-0 animate-fade-in" style={letter.style}>
              {letter.char}
            </span>
          ))}
         
        
        </h1>
       
     
      </div>
    </div>
  );
};

export default ServiceBanner;