
import React, { useState, useEffect } from "react";
import img1 from "../pageimg/re.png";
import img2 from "../pageimg/image (24).png";
import img3 from "../pageimg/image (25).png";
import img4 from "../pageimg/image (26).png";

export default function Endofhome() {
  const [activeCard, setActiveCard] = useState(0);
  const cards = [
    { image: img1 },
    { image: img2 },
    { image: img3 },
    { image: img4 },
  ];

  const numberOfVisibleCards = 3; // Number of cards to display at a time
  const autoplayInterval = 2800; // Autoplay interval in milliseconds

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveCard((prevCard) =>
        prevCard === cards.length - 1 ? 0 : prevCard + 1
      );
    }, autoplayInterval);

    return () => clearInterval(timer);
  }, [cards.length]);

  const getVisibleCards = () => {
    const endIndex = activeCard + numberOfVisibleCards;
    if (endIndex >= cards.length) {
      return [
        ...cards.slice(activeCard),
        ...cards.slice(0, endIndex % cards.length),
      ];
    }
    return cards.slice(activeCard, endIndex);
  };

  const visibleCards = getVisibleCards();

  return (
    <div className="h-fit md:w-full w-full mb-4">
      <style>
        {`
          /* Light blue and white shaded container */
          .light-blue-white-container {
            background: linear-gradient(135deg, #ffffff, #add8e6);
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 15px;
            transition: transform 0.3s, box-shadow 0.3s; /* Added transition */
          }

          /* Company name */
          .company-name {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            margin-top: 10px;
            color: #333;
          }

          /* Reduced hover effect */
          .light-blue-white-container:hover {
            transform: translate(-6px); /* Reduced translateY */
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Reduced box-shadow */
          }
        `}
      </style>
      <div className="flex flex-col items-center px-4 py-2">
  <div className="text-center">
    <h2 className="text-xl md:text-4xl font-bold text-gray-800 mb-6">Companies That Collaborate With Us</h2>
    <p className="text-base md:text-xl font-bold text-gray-400 ">We are proud to work with these companies</p>
  </div>
</div>

      <div className="flex flex-col gap-y-7">
        {/* row sliding */}
        <div className="flex flex-row justify-center">
          {visibleCards.map((card, index) => {
            return (
              <div
                key={index}
                className="flex flex-col px-8 py-8 lg:w-[35%] xl:w-[30%] rounded-lg transform scale-[0.7] translate-y-3 light-blue-white-container"
              >
                {/* Updated: Made image container full width */}
                <div className="w-full flex items-center justify-center">
                  <img
                    src={card.image}
                    alt=""
                    className="w-full h-auto max-h-[150px] max-w-[200px] rounded-lg"
                  />
                </div>
                {/* Company name */}
                
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
