import React from "react";
import Contactcntxt from "../chunkpages/Contactcntxt";
import Footer from "../UIcomp/Footer";
import WhatsAppStickyButton from "../chunkpages/WhatsappStickyButton";
export default function Contactus() {
  return (
    <div className=" font-times">
      <Contactcntxt />
      <WhatsAppStickyButton />

      <Footer />
    </div>
  );
}
