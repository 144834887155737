import React, { useState, useEffect } from "react";

export default function Cards2() {
  {
    /*const cards = [
    {
      img: `images/Symposium_Slide2.jpg`,
      title: ` Online & Offline Live Project Internship`,
      desc: `  Gain practical exposure to real-world projects through our online and offline live project internship. Collaborate with industry professionals, work on live assignments, and refine your skills in a dynamic learning environment.`,
    },
    {
      img: `images/Symposium_Slide3.jpg`,
      title: `Real-Time Project Internship`,
      desc: `Experience the pace and demands of real-time projects with our immersive internship program. Get involved in ongoing projects within our company, tackle challenges, and contribute to impactful solutions while honing your abilities.`,
    },
    {
      img: `images/Project3.jpg`,
      title: `Student Project Development Internship`,
      desc: `  Fuel your creativity and innovation with our student project development internship. Transform your ideas into tangible projects under expert guidance, leveraging resources and support to bring your vision to life.`,
    },
  ]; */
  }

  return (
    <div className=" relative  h-[850px] lg:h-[350px] w-full md:w-full md:h-[470px] mb-3 lg:mb-10 lg:pt-5 bg-bgimage rounded-lg bg-fixed bg-no-repeat bg-cover bg-center">
      <div className="absolute mt-[10px] w-full h-full text-white py-8 lg:py-5 px-2 md:px-8 w-[100%] rounded-lg md:block">
        <div className="flex flex-row lg:justify-end lg:space-x-10 max-md:flex-col rounded-lg">
          <div className="flex flex-col lg:w-[650px] lg:pb-[180px] lg:justify-center max-md:ml-8 mt-[85px] space-y-5 w-[250px] h-[250px] rounded-lg" data-aos="fade-down">
            <img src="images/Symposium_Slide2.jpg" alt="" className="lg:hidden border border-Darkblue rounded-lg"/>
            <img src="images/Symposium_Slide3.jpg" alt="" className="border border-Darkblue rounded-lg"/>
            <img src="images/Project3.jpg" alt="" className="lg:hidden border border-Darkblue rounded-lg"/>
          </div>
          <div className="flex flex-row justify-start items-center max-md:mt-[100px]">
            <div className="flex flex-col bg-Darkblue lg:mb-[80px] w-[600px] lg:h-[260px] max-md:mt-[80px] max-md:h-[250px] space-evenly rounded-lg" data-aos="fade-out">
              <h2 className="text-xl font-TimesNewRoman text-justify text-White px-2 py-2">Experience the pace and demands of real-time projects with our immersive internship program. Get involved in ongoing projects within our company, tackle challenges, and contribute to impactful solutions while honing your abilities.</h2>
              <div className="max-md:hidden">
                <h2 className="text-xl font-TimesNewRoman text-justify text-Yellow px-2 py-2"> Gain practical exposure to real-world projects through our online and offline live project internship. Collaborate with industry professionals, work on live assignments, and refine your skills in a dynamic learning environment.</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
