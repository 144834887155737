import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Slider6 from "../chunkpages/Cards6";
import Slider7 from "../chunkpages/Cards7";
import WhatsAppStickyButton from "../chunkpages/WhatsappStickyButton";
import Cards5 from "../chunkpages/Cards5";
import FinalYear from "../SubPagesCompos/FinalYear";
import Footer from "../UIcomp/Footer";
import Internbutton from "../SubPagesCompos/Internbutton";
import Aos from "aos";
import "aos/dist/aos.css";

function Internshipprogram() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  });

  return (
    <section className="bg-White grid w-full  max-md:space-y-5 place-items-center">
      <div
        className="relative flex justify-center items-center h-[250px] w-full md:w-full md:h-[600px] rounded-lg mb-8 lg:mb-10 bg-cover bg-center"
        style={{ backgroundImage: "url('/images/bgintern.jpg')" }}
      >
        <div
          className="absolute mt-[40px] w-full h-full text-white md:mt-[250px] max-md:pt-[70px] py-8 lg:py-16 px-6 md:px-12  w-[50%] rounded-bl-[20px] rounded-br-[80px] md:block"
          data-aos="fade-in"
        >
          <h2 className="flex justify-center items-center mt-[40px] text-4xl md:text-6xl font-extrabold text-White font-TimesNewRoman">
            Internship Portal
          </h2>

          <p className="lg:text-lg max-md:text-[10px] lg:text-justify w-[1050px] ml-[100px] max-md:text-justify font-semibold text-Yellow font-TimesNewRoman mt-4 hidden md:block">
            Our Internship Training at Altruisty bridges the gap between
            academic knowledge and professional experience through hands-on
            projects and expert mentorship. Develop skills and confidence to
            excel in your career with our comprehensive training.
          </p>

          {/*<div className="relative flex justify-center items-center px-2 py-2 font-medium group mt-[20px]">
            <Link to="/form">
              <button className="flex justify-center items-center h-50 w-50">
                <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-Darkblue group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
                <span className="absolute inset-0 w-full h-full bg-Yellow border-2 border-Darkblue group-hover:bg-Darkblue"></span>
                <span className="relative text-Darkblue group-hover:text-White">
                  Apply Now
                </span>
              </button>
            </Link>
          </div>*/}
        </div>
      </div>

      {/* Apply Now Button */}
      <Internbutton />

      {/* Intern Domains */}
      <div className=" relative h-[950px] w-full md:w-full md:h-[400px] lg:mt-8 mb-8 lg:mb-16 bg-bgimage rounded-lg bg-fixed bg-no-repeat bg-cover bg-center shadow-xl">
        <div
          className="absolute w-full h-full text-white py-5 lg:py-5 px-6 md:px-6 w-[100%] rounded-lg md:block"
          data-aos="fade-in"
        >
          <h2 className="flex justify-center items-center text-4xl md:text-6xl font-extrabold text-Yellow font-mono">
            Domains
          </h2>
          <div className="text-justify lg:w-[840px] lg:ml-[200px]">
            <span className="flex justify-center items-center text-justify text-white lg:py-5 space-y-5 font-bold font-TimesNewRoman">
              We provide various Domains in our Internship. By this you can
              participate in sector which you wish to learn.The Technology
              and multiple domain sector are created to improve your skills in
              several competative domains.
            </span>
          </div>
          <br></br>
          <span className="flex justify-center text-Yellow items-center font-extrabold">
            Here are the Domains Provided by Us.
          </span>
          <div
            className="flex flex-row mt-5 space-x-5 space-y-3 max-md:flex-col justify-evenly items-center"
            data-aos="fade-down"
          >
            <div className="flex justify-center items-center bg-Darkblue ml-4 mt-3 w-[300px] h-[100px] rounded-lg">
              <span className="text-White items-center">
                Full stack Development
              </span>
            </div>
            <div className="flex justify-center items-center bg-Darkblue w-[300px] h-[100px] rounded-lg">
              <span className="text-White items-center">
                Mern stack Development
              </span>
            </div>
            <div className="flex justify-center items-center bg-Darkblue w-[300px] h-[100px] rounded-lg">
              <span className="text-White justify-center items-center">
                AI & ML
              </span>
            </div>
            <div className="flex justify-center items-center bg-Darkblue w-[300px] h-[100px] rounded-lg">
              <span className="text-White items-center">UI & UX Design</span>
            </div>
            <div className="flex justify-center items-center bg-Darkblue w-[300px] h-[100px] rounded-lg">
              <span className="text-White items-center">
                Internet of Things
              </span>
            </div>
            <div className="flex justify-center items-center bg-Darkblue w-[300px] h-[100px] rounded-lg">
              <span className="text-White items-center">Robotics</span>
            </div>
          </div>
        </div>
      </div>

      {/* Training and Project Internship Sections */}
      <div className="w-full mx-auto space-y-5">
        {/* Training Internship Section */}
        <div className="w-full max-md:h-[500px] h-[350px] mx-auto p-5 mt-1 bg-gray-200 rounded-lg shadow-xl">
          <div className="flex flex-col items-center gap-8">
            <div className="text-center sm:text-base" data-aos="fade-in">
              <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-Darkblue">
                Training Internship
              </h2>
              <p className="mb-8 font-semibold text-md text-Darkblue text-justify">
                Great service experiences fast - without the complexity of
                traditional ITSM solutions. Accelerate critical development
                work, eliminate toil, and deploy changes with ease.
              </p>
              <Slider6 />
            </div>
          </div>
        </div>

        {/* Project Internship Section */}
        <div className="w-full mx-auto p-8 bg-gray-200 lg:h-[550px] rounded-lg space-y-10 shadow-xl">
          <div className="flex flex-col justify-center items-center gap-8">
            <div
              className="text-center text-Darkblue sm:text-base"
              data-aos="fade-in"
            >
              <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-Darkblue">
                Project Internship
              </h2>
              <p className="mb-8 font-semibold text-md text-Darkblue text-justify">
                Deliver great service experiences fast - without the complexity
                of traditional ITSM solutions. Accelerate critical development
                work, eliminate toil, and deploy changes with ease.
              </p>
              <Slider7 />
            </div>
          </div>
        </div>
      </div>

      {/* Final Year Project Training Internship Section */}
      <div className="w-full mx-auto lg:mt-5 p-8 bg-gray-200 mt-[5px] rounded-lg shadow-xl">
        <div className="flex flex-col justify-center items-center">
          <div className="text-center text-gray-500 sm:text-base">
            <h2
              className="mb-4 lg:text-4xl max-md:text-4xl font-extrabold tracking-tight text-Darkblue"
              data-aos="fade-in"
            >
              Final Year Project Training Internship
            </h2>
            <div
              className="grid w-full justify-start items-center mt-10"
              data-aos="fade-in"
            >
              <FinalYear />
            </div>
          </div>
        </div>
      </div>

      {/* Footer and WhatsApp

 Button */}
      <Footer />
      <WhatsAppStickyButton />
    </section>
  );
}

export default Internshipprogram;
