
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Testimonials() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);

  const images = [
    { img: 'images/Testi1.jpg' },
    { img: 'images/Testi2.jpg' },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // Disable arrows
    adaptiveHeight: true,
  };

  return (
    <div className="w-full pb-16 bg-gray-100 ">
      <div className="w-full py-4 lg:pt-20 flex flex-col items-center">
        <h1 className="text-l py-2 px-4 md:text-5xl text-[#F5cF6B] text-center border-2 px-20 bg-[#17212E] font-times mb-10">
          VOICES OF SATISFACTION
        </h1>
        <div className="w-full max-w-5xl px-4 md:px-8 lg:px-12">
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index} className="flex flex-col lg:flex-row items-center justify-center lg:space-x-8" data-aos="fade-up">
                <div className="flex-shrink-0 mb-4 lg:mb-0 flex justify-center">
                  <img
                    src={process.env.PUBLIC_URL + '/' + image.img}
                    alt={`Testimonial ${index + 1}`}
                    className="object-cover w-32 h-32 md:w-48 md:h-48 rounded-full"
                  />
                </div>
                <div className="flex flex-col items-center lg:items-center text-center">
                  <h2 className="text-2xl font-bold text-gray-900 mb-4 lg:mt-4">
                    {index === 0 ? "Excellence in Action" : "Dream Team Unity"}
                  </h2>
                  <p className="text-gray-700 text-base md:text-lg">
                    {index === 0
                      ? "Guiding principles pave our path. They're not just words; they're our compass, directing us through each day. With our customers leading the way, gratitude echoes in every action."
                      : "Together, we achieve more. Collaboration is the cornerstone of our success. With trust and respect at the core of our interactions, we accomplish our goals and exceed expectations."}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
