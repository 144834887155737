
import React from 'react';
import { Link } from 'react-router-dom';
import './others.css';

const AppDevelopment = () => {
  return (
    <>
      {/* <section className="homeap pt-20 text-center bg-cover bg-center text-white font-times" id="homeap">
        <div className="container mx-2 px-4 md:px-8 py-16 md:py-24">
        <h1 className="text-2xl md:text-4xl leading-tight mb-4">App Development</h1>
          <p className=" md:text-lg mb-6 max-w-3xl mx-auto">A software solution is typically custom-built or configured to solve a specific customer problem. It could include: Custom software development. Customized Assembly of multiple software products.</p>
          <Link to="/form">
            <button className="butt">Contact</button>
          </Link>
        </div>
      </section> */}
      <section className="homeap pt-20 text-center bg-cover bg-center text-white font-times" id="homeap">
  <div className="container mx-auto px-4 md:px-8 py-16 md:py-24">
    <h1 className="text-xl sm:text-2xl md:text-4xl leading-tight mb-4">App Development</h1>
    <p className="text-sm sm:text-base md:text-lg mb-6 max-w-3xl mx-auto">
      A software solution is typically custom-built or configured to solve a specific customer problem. It could include: Custom software development. Customized Assembly of multiple software products.
    </p>
    <Link to="/form"></Link>
     
</div>
</section>
      <section className="homema bg-gray-100 text-center py-16 md:py-10 font-times" id="homema">
        <div className="container mx-auto px-4 md:px-8">
          <h1 className="text-2xl md:text-4xl  mb-4">Mobile App Development</h1>
          <p className="text-base md:text-lg mb-6 max-w-3xl mx-auto">We specialize in crafting cutting-edge mobile applications tailored to meet your unique business needs. From concept to deployment, our team ensures seamless user experiences across iOS and Android platforms, leveraging the latest technologies to bring your ideas to life.</p>
        </div>
      </section>

      <section className="homeci bg-white text-center py-16 md:py-10 font-times" id="homeci">
        <div className="container mx-auto px-4 md:px-8">
          <h1 className="text-2xl md:text-4xl font-bold mb-4">Chatbot Integration</h1>
          <p className="text-base md:text-lg mb-6 max-w-3xl mx-auto">Enhance user interactions and streamline customer support with our chatbot integration services. We develop custom chatbots that intelligently engage with users, providing instant assistance and driving conversions.</p>
        </div>
      </section>

      <section className="homedm bg-gray-100 text-center py-16 md:py-10 font-times" id="homedm">
        <div className="container mx-auto px-4 md:px-8">
          <h1 className="text-2xl md:text-4xl font-bold mb-4">App Database Management</h1>
          <p className="text-base md:text-lg mb-6 max-w-3xl mx-auto">Efficient database management is essential for app performance and scalability. Our experts handle all aspects of app database management, from design and implementation to optimization and maintenance, ensuring optimal performance and reliability.</p>
        </div>
      </section>

      <section className="homeaa bg-white text-center py-16 md:py-10 font-times" id="homeaa">
        <div className="container mx-auto px-4 md:px-8">
          <h1 className="text-2xl md:text-4xl font-bold mb-4">App Deployment & API Integration</h1>
          <p className="text-base md:text-lg mb-6 max-w-3xl mx-auto">We handle the entire app deployment process, ensuring smooth integration with third-party APIs and services. From app store submissions to backend system connections, our experts ensure seamless deployment and functionality.</p>
        </div>
      </section>

      <section className="homeas bg-gray-100 text-center py-16 md:py-10 font-times" id="homeas">
        <div className="container mx-auto px-4 md:px-8">
          <h1 className="text-2xl md:text-4xl font-bold mb-4">App Security Maintenance & Support</h1>
          <p className="text-base md:text-lg mb-6 max-w-3xl mx-auto">Protecting your app and its users is paramount. Our dedicated team provides ongoing security maintenance and support services, continuously monitoring for potential threats and vulnerabilities to keep your app secure and your data safe.</p>
        </div>
      </section>

      <section className="homeui bg-white text-center py-16 md:py-10 font-times" id="homeui">
        <div className="container mx-auto px-4 md:px-8">
          <h1 className="text-2xl md:text-4xl font-bold mb-4">App UI/UX Design</h1>
          <p className="text-base md:text-lg mb-6 max-w-3xl mx-auto">Our talented designers focus on creating intuitive and visually appealing interfaces that enhance user engagement and satisfaction. With a keen eye for detail, we design every aspect of your app's interface to ensure a seamless and delightful user experience.</p>
        </div>
      </section>
    </>
  );
};

export default AppDevelopment;
