import React from "react";

import { Link } from "react-router-dom";
const steps = [
  {
    title: "About",
    description: "We start by understanding your needs and requirements.",
    icon: "🔍", // Placeholder for an icon
    link: "/musicalAbout",
  },
  {
    title: "Choices",
    description: "Our team designs a solution tailored to your specifications.",
    icon: "🎨",
    link: "/musicalChoices",
  },
  {
    title: "Certifications",
    description: "We build your solution using the latest technologies.",
    icon: "💻",
    link: "/musicalCertifications",
  },
  {
    title: "Competitions",
    description: "Your project goes live for the world to see.",
    icon: "🚀",
    link: "/musicalCompetitions",
  },
  // Add more steps as needed
];

const KeyFeatures = () => {
  return (
    <section className="py-12 ">
      <div className="py-12 ">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center items-center">
            <h2 className="text-4xl text-orange-600 font-semibold tracking-wide uppercase">
              Key Features
            </h2>
          </div>

          <div className="mt-10">
            <ul className="mx-auto w-96 md:w-full md:grid md:grid-cols-2 lg:grid-cols-4 gap-10">
              {steps.map((step, index) => (
                <li key={index} className="mt-10 md:mt-0">
                  {/* <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <span className="text-xl">{step.icon}</span>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">{step.title}</h4>
                    <p className="mt-2 text-base text-gray-500">{step.description}</p>
                  </div>
                </div> */}

                  <div className="  items-start p-4 border-2 border-inherit  transition-all duration-300 transform backdrop-blur shadow-lg place-items-center rounded-xl hover:-translate-y-2 hover:shadow-2xl">
                    <span className="text-xl">{step.icon}</span>

                    <h3 className="mb-2 text-green-600 text-xl font-bold py-4">
                      {step.title}
                    </h3>
                    <p className="text-white">{step.description}</p>
                    <Link to={step.link}>
                      <button className=" mx-auto mt-8">
                        <a
                          href="/"
                          className="relative inline-block text-sm group"
                        >
                          <span className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-black transition-colors duration-300 ease-out border-2 border-black rounded-lg group-hover:text-white">
                            <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                            <span className="absolute left-0 w-40 h-40 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-black group-hover:-rotate-180 ease"></span>
                            <span className="relative">Learn more</span>
                          </span>
                          <span
                            className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-black rounded-lg group-hover:mb-0 group-hover:mr-0"
                            data-rounded="rounded-lg"
                          ></span>
                        </a>
                      </button>
                    </Link>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KeyFeatures;
