import React from "react";
import logo from "../pageimg/logoo.jpg";
import yt from "../pageimg/yt.png";
import { Link } from "react-router-dom";
import maps from "../pageimg/maps.png";

export default function Footer() {
  return (
    <footer
      id="rework"
      className="w-full bg-white"
      aria-labelledby="footer-heading"
    >
      <div className="bg-black h-0.5"></div>
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      
      <div className="px-6 mx-auto max-w-7xl sm:pt-24 lg:px-8 relative">
        <div className="xl:flex xl:justify-between xl:gap-8">
          <div className="space-y-5">
            <img
              id="footer-logo"
              className="md:h-17 md:w-60 h-27 w-80 py-2"
              src={logo}
              alt="Company name"
            />
            <p className="font-semibold leading-6 text-black font-Inter"></p>
            <div className="flex flex-col">
              <div className="flex space-x-6">
                <a
                  href="https://www.linkedin.com/company/altruisty/"
                  className="text-yellow-500 hover:text-gray-500"
                >
                  <span className="sr-only">Linked in</span>
                  <svg
                    className="w-8 h-8"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#0077B5"
                      fillRule="evenodd"
                      d="M20.45175,20.45025 L16.89225,20.45025 L16.89225,14.88075 C16.89225,13.5525 16.86975,11.844 15.04275,11.844 C13.191,11.844 12.90825,13.2915 12.90825,14.7855 L12.90825,20.45025 L9.3525,20.45025 L9.3525,8.997 L12.765,8.997 L12.765,10.563 L12.81375,10.563 C13.2885,9.66225 14.4495,8.71275 16.18125,8.71275 C19.78575,8.71275 20.45175,11.08425 20.45175,14.169 L20.45175,20.45025 Z M5.33925,7.4325 C4.1955,7.4325 3.27375,6.50775 3.27375,5.36775 C3.27375,4.2285 4.1955,3.30375 5.33925,3.30375 C6.47775,3.30375 7.4025,4.2285 7.4025,5.36775 C7.4025,6.50775 6.47775,7.4325 5.33925,7.4325 L5.33925,7.4325 Z M7.11975,20.45025 L3.5565,20.45025 L3.5565,8.997 L7.11975,8.997 L7.11975,20.45025 Z M23.00025,0 L1.0005,0 C0.44775,0 0,0.44775 0,0.99975 L0,22.9995 C0,23.55225 0.44775,24 1.0005,24 L23.00025,24 C23.55225,24 24,23.55225 24,22.9995 L24,0.99975 C24,0.44775 23.55225,0 23.00025,0 L23.00025,0 Z"
                    />
                  </svg>
                </a>
                <a href="/">
                  <img className="h-9 w-9" src={yt} alt="YouTube" />
                </a>
                <a href="/MapPage">
                  <img className="h-9 w-9" src={maps} alt="Maps" />
                </a>
                <a
                  href="https://twitter.com"
                  className="text-yellow-500 hover:text-gray-500"
                >
                  <span className="sr-only ">instagram</span>
                  <svg
                    className="w-8 h-8 mt-1"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="2"
                      y="2"
                      width="28"
                      height="28"
                      rx="6"
                      fill="url(#paint0_radial_87_7153)"
                    />
                    <rect
                      x="2"
                      y="2"
                      width="28"
                      height="28"
                      rx="6"
                      fill="url(#paint1_radial_87_7153)"
                    />
                    <rect
                      x="2"
                      y="2"
                      width="28"
                      height="28"
                      rx="6"
                      fill="url(#paint2_radial_87_7153)"
                    />
                    <path
                      d="M23 10.5C23 11.3284 22.3284 12 21.5 12C20.6716 12 20 11.3284 20 10.5C20 9.67157 20.6716 9 21.5 9C22.3284 9 23 9.67157 23 10.5Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21ZM16 19C17.6569 19 19 17.6569 19 16C19 14.3431 17.6569 13 16 13C14.3431 13 13 14.3431 13 16C13 17.6569 14.3431 19 16 19Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6 15.6C6 12.2397 6 10.5595 6.65396 9.27606C7.2292 8.14708 8.14708 7.2292 9.27606 6.65396C10.5595 6 12.2397 6 15.6 6H16.4C19.7603 6 21.4405 6 22.7239 6.65396C23.8529 7.2292 24.7708 8.14708 25.346 9.27606C26 10.5595 26 12.2397 26 15.6V16.4C26 19.7603 26 21.4405 25.346 22.7239C24.7708 23.8529 23.8529 24.7708 22.7239 25.346C21.4405 26 19.7603 26 16.4 26H15.6C12.2397 26 10.5595 26 9.27606 25.346C8.14708 24.7708 7.2292 23.8529 6.65396 22.7239C6 21.4405 6 19.7603 6 16.4V15.6ZM15.6 8H16.4C18.1132 8 19.2777 8.00156 20.1779 8.0751C21.0548 8.14674 21.5032 8.27659 21.816 8.43597C22.5686 8.81947 23.1805 9.43139 23.564 10.184C23.7234 10.4968 23.8533 10.9452 23.9249 11.8221C23.9984 12.7223 24 13.8868 24 15.6V16.4C24 18.1132 23.9984 19.2777 23.9249 20.1779C23.8533 21.0548 23.7234 21.5032 23.564 21.816C23.1805 22.5686 22.5686 23.1805 21.816 23.564C21.5032 23.7234 21.0548 23.8533 20.1779 23.9249C19.2777 23.9984 18.1132 24 16.4 24H15.6C13.8868 24 12.7223 23.9984 11.8221 23.9249C10.9452 23.8533 10.4968 23.7234 10.184 23.564C9.43139 23.1805 8.81947 22.5686 8.43597 21.816C8.27659 21.5032 8.14674 21.0548 8.0751 20.1779C8.00156 19.2777 8 18.1132 8 16.4V15.6C8 13.8868 8.00156 12.7223 8.0751 11.8221C8.14674 10.9452 8.27659 10.4968 8.43597 10.184C8.81947 9.43139 9.43139 8.81947 10.184 8.43597C10.4968 8.27659 10.9452 8.14674 11.8221 8.0751C12.7223 8.00156 13.8868 8 15.6 8Z"
                      fill="white"
                    />
                    <defs>
                      <radialGradient
                        id="paint0_radial_87_7153"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(12 23) rotate(-55.3758) scale(25.5196)"
                      >
                        <stop stopColor="#B13589" />
                        <stop offset="0.79309" stopColor="#C62F94" />
                        <stop offset="1" stopColor="#8A3AC8" />
                      </radialGradient>
                      <radialGradient
                        id="paint1_radial_87_7153"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(11 31) rotate(-65.1363) scale(22.5942)"
                      >
                        <stop stopColor="#E0E8B7" />
                        <stop offset="0.444662" stopColor="#FB8A2E" />
                        <stop offset="0.71474" stopColor="#E2425C" />
                        <stop offset="1" stopColor="#E2425C" stopOpacity="0" />
                      </radialGradient>
                      <radialGradient
                        id="paint2_radial_87_7153"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(0.500002 3) rotate(-8.1301) scale(38.8909 8.31836)"
                      >
                        <stop offset="0.156701" stopColor="#406ADC" />
                        <stop offset="0.467799" stopColor="#6A45BE" />
                        <stop offset="1" stopColor="#6A45BE" stopOpacity="0" />
                      </radialGradient>
                    </defs>
                  </svg>
                </a>
              </div>
              <div className="mt-4">
                <h1 className="font-bold">Contact</h1>
                <p>Mobile: +918667839838</p>
                <p>Email: altruistybusiness@gmail.com</p>
              </div>
            </div>
          </div>

          <div className="mt-10 xl:mt-0 md:block">
            
            <ul className="mt-4 space-y-4">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/Services">Services</a>
              </li>
              <li>
                <a href="/Eventsprogram">Events and Competition</a>
              </li>
              <li>
                <a href="/StartupLaunchpad">Startup Services</a>
               
              </li>
              <li>
                <a href="/Package">Package</a>
              </li>
              <li>
                <a href="/Aboutus">About us</a>
              </li>
              <li>
                <a href="/Contactus">Contact us</a>
              </li>
            </ul>
          </div>

          <div className="mt-10 xl:mt-0 hidden md:block">
            <h1 className="font-bold"><a href="/Services">Services</a></h1>
            <ul className="mt-4 space-y-4">
              <li>
                <a href="/appdevelopment">App development</a>
              </li>
              <li>
                <a href="/webdevelopment">Web development</a>
              </li>
              <li>
                <a href="/graphicdesigning">Graphic designing</a>
              </li>
              <li>
                <a href="/digitalmarketing">Digital marketing</a>
              </li>
              <li>
                <a href="/training">Training program</a>
              </li>
              <li>
                <a href="/internshipprogram">Internship program</a>
              </li>
            </ul>
          </div>

          <div className="mt-10 xl:mt-0 hidden md:block">
            <h1 className="font-bold">Package</h1>
            <ul className="mt-4 space-y-4">
              <li>
                <a href="">Student</a>
              </li>
              <li>
                <a href="">Startup</a>
              </li>
              <li>
                <a href="">Student Startup</a>
              </li>
              <li>
                <a href="">MSME Company</a>
              </li>
              <li>
                <a href="">Corporate</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex justify-center border-t py-7 border-gray-900/10">
          <p className="text-sm font-semibold leading-5 text-black font-Inter">
            &copy; 2024 Altruisty, Inc. All rights reserved.
          </p>
        </div>
      </div>
     
    </footer>
  );
}
