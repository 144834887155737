
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import backgroundImage4 from './homebanner2.png';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS

const Internship = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false
    });
  }, []);

  const data = [
    {
      title: 'Training Internship',
      desc: 'Crafting user-friendly mobile applications tailored to your needs, ensuring seamless functionality and engaging user experiences.',
      link: "/internshipprogram"
    },
    {
      title: 'Project Internship',
      desc: 'Deliver great service experiences fast - without the complexity of traditional ITSM solutions. Accelerate critical development work.',
      link: "/internshipprogram"
    },
  ];

  return (
    <div>
      {/* Training Internship Section */}
      <section className="relative bg-fixed bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${backgroundImage4})` }}>
        {/* <div className="absolute inset-0 bg-black bg-opacity-0"></div> Removed opacity */}
        {/* <div className="absolute w-full h-full bg-black opacity-40 blur-md"></div> */}
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative z-10 text-center pt-6 md:pt-6 lg:pt-8"> 
          <h1 className="text-2xl md:text-4xl lg:text-5xl   text-[#FFFFFF] font-times ">INTERNSHIP</h1>
        </div>
        <div className="relative z-10 max-w-screen-lg mx-auto px-4 md:px-8 lg:px-12 py-6 md:py-12 lg:pb-2">
          <div
            className="p-4 md:p-6 mb-6 md:mb-8"
            data-aos="fade-up"
            style={{ boxShadow: 'none' }} // Remove shadow if causing issues
          >
            <h2 className="text-xl md:text-3xl lg:text-4xl font-semibold text-[#F5CF6B] text-center  lg:pb-4 mb-2">{data[0].title}</h2>
            <p className=" md:text-lg lg:text-2xl text-justify text-white mb-4">{data[0].desc}</p>
            <div className="flex justify-center mt-6">
              <Link to={data[0].link}>
                <button className="px-4 py-2 md:px-6 md:py-3 bg-[#17212E] text-[#F5CF6B] font-semibold rounded-md hover:bg-[#F5CF6B] hover:text-black transition duration-300">
                  Learn More
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      
      {/* Internship Student Verification Section */}
      <section className="relative bg-[#FFFFFF]">
        <div className="absolute inset-0 bg-[#FFFFFF]"></div>
        <div className="relative z-10 py-6 md:py-12 lg:py-16 text-center">
          <div
            className="max-w-4xl mx-auto transition-transform duration-300 px-4 md:px-6 lg:px-8 rounded-lg overflow-hidden transform transition-transform duration-500 ease-in-out hover:scale-105"
            data-aos="fade-up"
            style={{ boxShadow: 'none' }} // Remove shadow if causing issues
          >
            <h1 className="text-2xl md:text-4xl lg:text-5xl font-extrabold text-[#17212E] mb-14">
              Internship Student Verification
            </h1>
            {/* <p className="md:text-lg lg:text-xl text-[#17212E] mb-6 md:mb-8 px-4 md:px-6 lg:px-8">
              If you're an internship student, verify your identity to access your project details.
            </p> */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8 items-center">
              {/* Left Column: Image */}
              <div className="px-2 flex justify-center" data-aos="fade-right">
                <img
                  src="/images/internship.jpg"
                  alt="Internship Program"
                  className="w-full h-auto rounded-lg"
                  style={{ boxShadow: 'none' }} // Remove shadow if causing issues
                />
              </div>
              {/* Right Column: Description */}
              <div className="text-left md:text-left flex flex-col items-center md:items-start md:ml-8" data-aos="fade-left">
                <p className=" md:text-lg lg:text-xl text-[#17212E] mb-4 text-center md:text-left">
                  Join our internship program and gain hands-on experience with real-world projects. Develop skills under expert mentorship and accelerate your career growth.
                </p>
                <p className=" md:text-lg lg:text-xl text-[#17212E] mb-4 text-center md:text-left">
                  Whether you're interested in training or project-based internships, we have opportunities to fit your goals.
                </p>
                <Link to="/verify" data-aos="fade-up">
                  <button className="px-4 py-2 md:px-6 md:py-3 bg-[#17212E] text-[#F5CF6B] font-semibold rounded-md hover:bg-[#F5CF6B] hover:text-black transition duration-300 mt-4">
                    Verify Now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Project Internship Section */}
      <section className="relative bg-fixed bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${backgroundImage4})` }}>
      {/* <div className="absolute w-full h-full bg-black opacity-40 blur-md"></div> */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative z-10 max-w-screen-lg mx-auto px-4 md:px-8 lg:px-12 py-6 md:py-12 lg:py-16">
          <div
            className="p-4 md:p-6 mb-6 md:mb-8 "
            data-aos="fade-up"
            style={{ boxShadow: 'none' }} // Remove shadow if causing issues
          >
            <h2 className="text-xl md:text-3xl lg:text-4xl lg:pb-4 font-semibold text-[#F5CF6B] text-center mb-2">{data[1].title}</h2>
            <p className="lg:pl-4   lg:text-2xl text-justify text-white mb-4 lg:flex justify-center">{data[1].desc}</p>
            <div className="flex justify-center mt-6">
              <Link to={data[1].link}>
                <button className="px-4 py-2 md:px-6 md:py-3 bg-[#17212E] text-[#F5CF6B] font-semibold rounded-md hover:bg-[#F5CF6B] hover:text-black transition duration-300">
                  Learn More
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Internship;
