

import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import backgroundImg from './image3.png'; // Update the path to your image

const services = [
  { name: "SCHOOL TRAINING", path: "/training" },

  { name: "COLLEGE TRAINING", path: "/training" },
  { name: "FINAL YEAR PROJECT TRAINING", path: "/training" },
  
 
];



const TrainingPrograms = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const scrollInterval = 5000; // 5 seconds interval

    const scrollContainer = () => {
      if (window.innerWidth < 1024) { // Only scroll on small screens
        if (containerRef.current) {
          const container = containerRef.current;
          const newScrollPosition = container.scrollLeft + container.clientWidth;

          // Scroll the container
          container.scrollTo({ left: newScrollPosition, behavior: 'smooth' });

          // Reset scroll position to the beginning if reached the end
          if (newScrollPosition >= container.scrollWidth) {
            container.scrollTo({ left: 0, behavior: 'smooth' });
          }
        }
      }
    };

    const intervalId = setInterval(scrollContainer, scrollInterval);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div 
      className="relative w-full lg:py-12 sm:py-8 bg-cover bg-center bg-fixed lg:mt-20"
      style={{ backgroundImage: `url(${backgroundImg})` }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      
      {/* Content Wrapper */}
      <div className="relative z-10 container mx-auto px-4 py-2 sm:py-12 text-center font-times">
        {/* Header Section */}
        <h1 className="text-l pt-4 lg:text-4xl lg:pb-8 text-[#FFFFFF] pb-2">PATHWAYS TO SUCCESS </h1>
        <h2 className="text-l lg:text-4xl font-bold text-[#F5CF6B] mt-2">SPEACIALIZED TRAINING FOR EVERY STAGE</h2>

        {/* Services List */}
         <div className="flex flex-col items-center mb-8 lg:mb-0  px-2 ">
            <h3 className="text-xl lg:text-3xl  text-[#FFFFFF] pt-8 mb-8">Our Training Program</h3>
            <ul className="list-none text-white ">
              {services.map((service, index) => (
                <li key={index} className="mb-2">
                  <Link to={service.path} className="text-[#F5CF6B] text-l   hover:no-underline hover:border-b-2 hover:border-[#F5CF6B]">
                    {service.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          
          {/* Vertical Line */}
       

        {/* Button Section */}
        <div className="flex justify-center mt-8">
          <Link to="/training">
            <button className="mb-4 bg-[#17212E] text-[#F5CF6B] py-2 px-4 w-38 sm:py-3 sm:px-6 rounded-lg hover:bg-[#F5CF6B] hover:text-black transition duration-300 text-sm sm:text-base">
              View Program
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TrainingPrograms;
