
import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

export default function Inersonevent() {
  // Banner Slide
  const slide = [
    {
      title: "Birthday Events",
      description:
        "Celebrate life's milestones with our expertly curated birthday events. Whether it's a sweet sixteen, a milestone birthday, or anything in between, we specialize in creating unforgettable experiences tailored to your unique preferences and style. From venue selection and decor to entertainment and catering, our team handles every detail with precision and care, ensuring a seamless and stress-free celebration for you and your loved ones.   ",
      icon: "/images/Inperson_Slide1.jpg",
    },
    {
      title: "Wedding Events",
      description:
        "Say 'I do' to a picture-perfect wedding day with our comprehensive wedding event services. From intimate ceremonies to grand affairs, we offer end-to-end support to bring your dream wedding to life. Our experienced team works closely with you to design a personalized wedding experience that reflects your love story and exceeds your expectations. With meticulous planning, attention to detail, and a commitment to excellence, we'll help you create cherished memories that last a lifetime. ",
      icon: "/images/Inperson_Slide6.jpg",
    },
    {
      title: "Engagement Party",
      description:
        "Celebrate the beginning of your journey together with a spectacular engagement party designed to dazzle and delight. Whether you envision an intimate gathering or a lavish soirée, our team is here to turn your vision into reality. From venue selection and thematic decor to entertainment and catering, we handle every aspect of your engagement party with creativity and finesse, ensuring an unforgettable celebration that sets the stage for your future together.",
      icon: "/images/Inperson_Slide4.jpg",
    },
    {
      title: "Anniversary Celebration",
      description:
        "Commemorate your love and commitment with a memorable anniversary celebration that honors your journey together. Whether it's a milestone anniversary or an annual tradition, we specialize in crafting bespoke anniversary events that capture the essence of your relationship and celebrate your enduring bond. From romantic dinners to elaborate parties, we'll work closely with you to create an unforgettable experience that honors your love story and creates new memories to cherish for years to come.",
      icon: "/images/Inperson_Slide2.jpg",
    },
  ];
  //Individual events card

  const steps = [
    {
      title: "Birthday Events",
      description:
        "Let us craft your unforgettable birthday celebration with precision and style, tailored to your unique preferences and milestones.",
      icon: "/images/Birthday_Events_Image.png",
      link: "/inperson",
    },
    {
      title: "Wedding Events",
      description:
      "Say 'I do' to a personalized and picture-perfect wedding day with our comprehensive event services.",
      icon: "/images/Inperson_Slide5.jpg",
      link: "/inperson",
    },
    {
      title: "Engagement Party",
      description:
      "Begin your journey together with a dazzling engagement party designed to delight and create unforgettable memories for your future.",
      icon: "/images/Inperson_Slide4.jpg",
      link: "/inperson",
    },
    {
      title: "Anniversary Celebration",
      description:
      "Commemorate your love with a bespoke anniversary celebration that captures your journey and creates cherished memories for years to come.",
      icon: "/images/Inperson_Slide7.jpg",
      link: "/inperson",
    },
  ];

  // Data for the slider
  const data = [
    {
      src: "images/Birthday_Icon.jpg",
    },
    {
      src: "images/Birthday2.jpg",
    },
    {
      src: "images/Wedding1.jpg",
    },
    {
      src: "images/Wedding2.jpg",
    },
  ];

  // Slider settings
  const settings = {
    className: "center",
    centerMode: true,
    dots: true,
    infinite: true,
    speed: 1000,
    centerPadding: "60px",
    cssEase: "linear",
    autoplaySpeed: 2500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 410,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <section className="bgcolor">
      <div className=" container px-2 py-20 mx-auto max-xl:flex flex-col">
        {/* Banner  */}

        <div className="w-full mx-8 my-8 max-sm flex flex-col justify-center">
          <section className="grid gap-4 w-full flex justify-center">
            <div
              id="servicesSlide"
              className="h-96 sm:w-[600px] md:w-[800px] lg:w-[1000px] w-96 lg:pt-10 mx-auto"
            >
              <Slider {...settings}>
                {slide.map((item, index) => (
                  <div
                    key={index}
                    className="text-gray-500 sm:text-lg :text-gray-400"
                  >
                    <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-center text-orange-600 ">
                      {item.title}
                    </h2>
                    <div className="flex flex-col sm:flex-row items-center justify-center">
                      <img
                        src={item.icon}
                        className="w-1/1 sm:w-96  mb-4 sm:mb-0 sm:mr-4"
                        alt={item.title}
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </section>

          

          <div className="flex justify-center sm:pt-43 pt-10 md:pt-0">
            <button
              className="w-full md:w-1/2 lg:w-1/4 p-3 justify-center flex  bg-indigo-600 rounded-lg text-slate-300 group-hover:mb-0 group-hover:mr-0 hover:bg-violet-500 hover:text-slate-950 active:bg-violet-700 transition-colors duration-1000 ease-out border-2 mt-0"
              onClick={() =>
                (window.location.href = "https://docs.google.com/forms/u/0/")
              }
            >
              Book Now!
            </button>
          </div>
        </div>
      </div>

      {/* Events  */}

      <section className="py-12 ">
        <div className="py-12 ">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center items-center">
              <h2 className="text-4xl text-orange-600 font-semibold tracking-wide uppercase">
                Events Organized
              </h2>
            </div>

            <div className="mt-10 backdrop-blur">
              <ul className="mx-auto w-full md:w-full md:grid md:grid-cols-2 lg:grid-cols-4 gap-10">
                {steps.map((step, index) => (
                  <li key={index} className="mt-10 md:mt-0">
                    <div className="  w-full h-full items-start p-4 border-2 border-inherit hover:border-black transition-all duration-300 transform bg-slate shadow-lg place-items-center rounded-xl hover:-translate-y-2 hover:shadow-2xl  ">
                      <img src={step.icon}  width={350}  alt="Card Images"
                        className="m-auto rounded-lg"/>

                      <h3 className="mb-2 text-green-600 text-xl font-bold py-4">
                        {step.title}
                      </h3>
                      <p className="text-white">{step.description}</p>
                      <Link to={step.link}></Link>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Carousel Section */}

      <section className="grid gap-4 w-full">
        <div className="h-96 sm:w-[600px] md:w-[800px] lg:w-[600px] w-96  gap-20 mx-auto">
          <Slider {...settings}>
            {data.map((item, index) => (
              <div key={index} className="w-full w-1/1 flex  ">
                <img
                  className="object-contain object-center w-full max-w-[450px]  md-h-30 rounded-xl"
                  src={item.src}
                  alt="blog"
                />
              </div>
            ))}
          </Slider>
        </div>
      </section>

      {/* Other Details  */}

      <section className="py-12 ">
        <div className="py-12 ">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center items-center">
              <h2 className="text-4xl text-orange-600 font-semibold tracking-wide uppercase">
                Other Details
              </h2>
            </div>

            <div className="mt-10 backdrop-blur">
              <ul className="mx-auto w-96 md:w-full md:grid md:grid-cols-2 lg:grid-cols-2 gap-10 ">
              {slide.map((step, index) => (
                  <li key={index} className="mt-10 md:mt-0">
                    <div className="  items-start p-4 border-2 border-inherit hover:border-black transition-all duration-300 transform bg-slate shadow-lg place-items-center rounded-xl hover:-translate-y-2 hover:shadow-2xl h-full">
                      <h3 className="mb-2 text-green-600 text-xl font-bold py-4">
                        {step.title}
                      </h3>
                      <p className="text-white">{step.description}</p>
                      <Link to={step.link}></Link>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Booking Button */}
      <div className="flex justify-center">
        <button
          className="w-full md:w-1/2 lg:w-1/4  p-3 justify-center flex ease-linear bg-indigo-600 rounded-lg text-slate-300 group-hover:mb-0 group-hover:mr-0 hover:bg-violet-500 hover:text-slate-950 active:bg-violet-700 transition-colors duration-1000 ease-out border-2"
          onClick={() =>
            (window.location.href = "https://docs.google.com/forms/u/0/")
          }
        >
          Book Now!
        </button>
      </div>
    </section>
  );
}
