import React from 'react';

const AltruistyStartupLaunchpad = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-[#17212E] text-white">
      <div className="text-center">
        <h1 className="text-5xl font-bold">Altruisty Startup Launchpad</h1>
        <p className="text-2xl mt-4 text-[#F5CF6B]">Coming Soon</p>
      </div>
    </div>
  );
}

export default AltruistyStartupLaunchpad;