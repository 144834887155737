import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

export const HostAnEvent = () => {
	const [messageSent, setMessageSent] = useState(false);

	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs
			.sendForm("service_j2nil66", "template_7vpf2yu", form.current, {
				publicKey: "-eMbkSbBRJJPs-LcW",
			})
			.then(
				() => {
					console.log("SUCCESS!");
					setMessageSent(true);
				},
				(error) => {
					console.log("FAILED...", error.text);
					setMessageSent(false);
				}
			);
	};

	return (
		<div className="container mx-auto gap-4">
			<hr className="m-2" />
			<p className="text-center text-2xl font-semibold mt-20 mb-8">
				Host an <span className="text-[#001a57]">Event</span> ? <br />
				<span className="font-normal text-lg text-[#001a57]">
					{" "}
					Fill the form , we'll get back to you soon !
				</span>
			</p>
			<div className="flex flex-col lg:flex-row lg:gap-8">
				<img
					src="/EventImages/contact.jpg"
					className="hidden lg:block lg:w-1/2 h-[450px] object-cover object-center rounded-lg"
					alt="contact us"
				></img>
				<form
					ref={form}
					onSubmit={sendEmail}
					className="flex flex-col lg:w-1/2"
				>
					<div className="flex">
						<div className="w-1/2">
							<label className="m-2 font-medium text-stone-700 flex flex-col gap-2">
								Name
								<input
									type="text"
									name="user_name"
									className="p-2 rounded-lg shadow-md"
								/>
							</label>
						</div>
						<div className="w-1/2">
							<label className="m-2 font-medium text-stone-700 flex flex-col gap-2">
								Mobile
								<input
									type="text"
									name="user_mobile"
									className="p-2 rounded-lg shadow-md"
								/>
							</label>
						</div>
					</div>

					<label className="m-2 font-medium text-stone-700 flex flex-col gap-2">
						Email
						<input
							type="email"
							name="user_email"
							className="p-2 rounded-lg shadow-md"
						/>
					</label>
					<label className="m-2 font-medium text-stone-700 flex flex-col gap-2">
						Event Type
						<input
							type="text"
							name="event_type"
							className="p-2 rounded-lg shadow-md"
						/>
					</label>
					<label className="m-2 font-medium text-stone-700 flex flex-col gap-2">
						Message
						<textarea name="message" className="p-2 rounded-lg shadow-md" />
					</label>
					<button
						type="submit"
						className={`m-2 h-10 rounded-lg shadow-md my-4 ${
							messageSent ? "bg-green-500" : "bg-[#001a57]"
						} text-white font-medium hover:opacity-80`}
					>
						{messageSent ? "Sent !" : "Send"}
					</button>
				</form>
			</div>
		</div>
	);
};
