import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const data = [
  {
    name: 'Mr. Rahul',
    role: 'Hardware technology lead',
    imageUrl: 'images/kar.png',
  },
  {
    name: 'Mr. Harsha Praveen',
    role: 'Career counsellor & Industry Analyst',
    imageUrl: 'images/IMG_4140.PNG',
  },
 
];

const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 2,
  slidesToScroll: 1,
  rtl: true, // Set Right To Left
  autoplay: true, // Enable autoplay
  autoplaySpeed: 5000, // Set autoplay speed to 3 seconds
  responsive: [
    {
        breakpoint: 1024, // screens larger than 1024px
        settings: {
            slidesToShow: 3,
        }
    },
    {
        breakpoint: 768, // screens larger than 768px
        settings: {
            slidesToShow: 1,
        }
    }
  ],
  appendDots: dots => (
    <div style={{ textAlign: "center" }}>
      <ul style={{ margin: "2px",color:"red" }}> {dots} </ul>
    </div>
  )
};

export default function Ourteam() {
  return (
    <section className="grid gap-4 justify-items-center w-full py-4 bgcolor align-center" >
      <div className="bg-[#17212E] rounded-lg shadow-xl p-8 mb-8 w-full sticky z-5">
        <h1 className="text-3xl text-[#F5CF6B] text-center font-Poppins mb-0 font-bold">TEAM MEMBERS</h1>
      </div>
      <div className="team-slider-container max-w-xs	md:max-w-lg " style={{ width: '100%', maxHeight: '90vh' }}>
        <div className="slick-slider" style={{ margin: "0 -30px"}}>
          <Slider {...settings}>
            {data.map((member, index) => (
              <div key={index} className="team-slide slick-list sm:ml-" style={{ height: '350px', margin: '0 30px', width: '100%' }}>
                <div className="flex flex-col items-center bg-[#17212E] hover:bg-[#F5CF6B] text-[#F5CF6B] hover:text-[#17212E] duration-300 rounded-xl p-8 d-flex justify-content-center align-items-center" style={{ width: '100%', height: '100%' }}>
                  <img
                    className="object-contain object-center w-1 h-20 mx-auto lg:h-48 md:h-20 rounded-full"
                    src={member.imageUrl}
                    alt="blog"
                    style={{
                      width:"100%",
                      height: 'auto',
                      backgroundColor: '#A69080',
                      boxShadow: '6px 6px 6px rgba(0, 0, 0, 0)', // Add box shadow
                    }}
                  />

                  <h2 className="mb-5 text-2xl md:text-4xl font-bold tracking-tight text-center font-sans" style={{ height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
                    {member.name}
                  </h2>

                  <p className="font-semibold text-lg  w-full text-center font-sans" style={{ height: '80px', overflow: 'hidden', textAlign: 'center' }}>
                    {member.role}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <br />
      <br />
    </section>
  );
}


// hover:shadow-[0_35px_60px_-15px_rgba(23,33,46)