import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import { Autoplay } from "swiper/modules";
import {
	RxCrop,
	RxDesktop,
	RxPencil2,
	RxReader,
	RxRocket,
	RxAccessibility,
} from "react-icons/rx";
import { createClient } from "contentful";

const CardSlider = () => {
	const [events, setEvents] = useState([]);
	const [slidesPerView, setSlidesPerView] = useState(1);
	const client = createClient({
		space: process.env.REACT_APP_SPACE_ID,
		accessToken: process.env.REACT_APP_ACCESS_TOKEN,
	});

	useEffect(() => {
		const getAllEntries = async () => {
			try {
				const entries = await client.getEntries();
				setEvents(entries.items);
			} catch (error) {
				console.log(`Error fetching authors ${error}`);
			}
		};
		getAllEntries();
	}, []);

	// This block of code ensures that the slider maintains its structure and the correct number of slides
	// when the slides per view exceeds the total number of slides in the page.
	// It prevents the slider from messing up and ensures that the appropriate number of slides render
	// based on the desired screen size.
	useEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth;
			let newSlidesPerView = 1;

			if (width < 490) {
				newSlidesPerView = 1;
			} else if (width < 860) {
				newSlidesPerView = Math.min(2, events.length);
			} else if (width < 990) {
				newSlidesPerView = Math.min(3, events.length);
			} else {
				newSlidesPerView = Math.min(4, events.length);
			}

			setSlidesPerView(newSlidesPerView);
		};

		handleResize(); // Call on initial render
		window.addEventListener("resize", handleResize); // Add event listener

		return () => {
			window.removeEventListener("resize", handleResize); // Clean up on component unmount
		};
	}, [events]); // Added events as dependency to recalculate slidesPerView when it changes

	const icons = [
		RxCrop,
		RxDesktop,
		RxPencil2,
		RxReader,
		RxRocket,
		RxAccessibility,
	];

	return (
		<div className="flex items-center justify-center flex-col pt-32 ml-20">
			<Swiper
				slidesPerView={slidesPerView}
				spaceBetween={15}
				freeMode={false}
				pagination={{
					clickable: true,
				}}
				modules={[Autoplay]}
				autoplay={{ delay: 1500 }}
				// autoplay={false}
				className="max-w-[80%] lg:max-w-[70%]"
			>
				{events.map((event) => {
					// To get the random icons in the slides
					const RandomIconComponent =
						icons[Math.floor(Math.random() * icons.length)];
					return (
						<SwiperSlide key={event.sys.id}>
							<div className="flex flex-col gap-6 mb-20 group relative shadow-lg text-[#001a57] rounded-xl px-6 py-8 h-[200px] w-[185px] lg:h-[350px] lg:w-[300px] overflow-hidden cursor-pointer">
								<div
									className="absolute inset-0 bg-cover bg-center w-full"
									style={{
										backgroundImage: `url(${event.fields.eventImage?.fields.file.url})`,
									}}
								>
									<div className="absolute inset-0 bg-black opacity-10 group-hover:opacity-50" />
									<div className="absolute flex flex-col gap-3 w-full h-full justify-center ml-2 mt-8">
										<RandomIconComponent className="text-[#001a57] group-hover:text-blue-400 w-[32px] h-[32px]" />
										<p className="text-lg lg:text-xl line-clamp-3 w-full">
											{event.fields.title}
										</p>
										{/* <p className="lg:text-[18px]">{event.fields.description}</p> */}
									</div>
								</div>
							</div>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</div>
	);
};

export default CardSlider;
