

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import appdevelopment from '../Images/appdevelopment-logo.png.webp';
import webdevelopment from '../Images/webdevelopment-logo.jpg';
import digidalmarketing from '../Images/digitalmarketing-logo.png.webp';
import graphic from '../Images/graphicdesiging-logo.png.jpeg';
import mentorship from '../Images/mendorship-logo.jpg';
import incupation from '../Images/incubation-logo.jpg';
import Images from '../Images/services2-logo.jpg';
import Footer from '../../../../UIcomp/Footer';
const Altruistyservices = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [designation, setDesignation] = useState('');
  const navigate = useNavigate();

  const handleServiceClick = (path) => {
    navigate(path);
  };

  return (
    <>
      <style jsx>{`
        .card1 {
          border: 1px solid #ddd;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          padding: 20px;
          transition: transform 0.2s;
          text-align: center;
          background-color: #fff;
        }

        .card1:hover {
          transform: scale(1.05);
        }

        .card1 img {
          max-width: 100%;
          height: 200px;
          border-radius: 10px;
        }

        .card1 h3 {
          font-size: 1.5rem;
          margin-top: 15px;
          color: #444;
        }

        .card1 p {
          font-size: 1rem;
          margin: 15px 0;
          color: #acacac;
        }

        .btn1 {
          background-color: #007bff;
          color: white;
          width: 100%;
          padding: 10px 20px;
          text-decoration: none;
          border-radius: 5px;
          text-transform: uppercase;
          transition: background-color 0.2s;
          letter-spacing: 3px;
        }

        .btn1:hover {
          background-color: #0056b3;
        }
      `}</style>

      <section
        className="home bg-cover bg-center text-white text-center py-28 md:py-60 font-times"
        style={{
          backgroundImage: `url(${Images})`,
          backgroundBlendMode: 'overlay',
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }}
      >
        <div className="container mx-auto px-6">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">ALTRUISTY SERVICES</h1>
          <p className="text-lg md:text-xl mb-8 max-w-2xl mx-auto">
            We provide high-quality services & innovative solutions for reliable growth.
          </p>
          <Link to="/form">
          <button className="px-6 py-3 bg-[#17212E] text-[#F5CF6B] font-semibold rounded-md transition transform duration-300 hover:scale-105">
  Contact
</button>

          </Link>
        </div>
      </section>

      <section className="py-10 font-times">
  <div className="container mx-auto px-6">
    <h2 className="text-center text-[#17212E] text-3xl font-bold mb-8">Our Services</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
      {[
        
        { img: digidalmarketing, title: 'Digital Marketing', desc: 'Digital marketing, also called online marketing, is the promotion of brands to connect with potential customers using the internet and other forms of digital communication.', path: '/DigitalMarketing' },
        { img: graphic, title: 'Graphic Designing', desc: 'A software solution is typically custom-built or configured to solve a specific customer problem. It could include: Custom software development. Customized Assembly of multiple software products.', path: '/GraphicDesigning' },
        { img: webdevelopment, title: 'Web Development', desc: 'Education is both the act of teaching knowledge to others and the act of receiving knowledge from someone else. Education also refers to the knowledge received through schooling or instruction.', path: '/WebDevelopment' },
        { img: appdevelopment, title: 'App Development', desc: 'A software solution is typically custom-built or configured to solve a specific customer problem. It could include: Custom software development. Customized Assembly of multiple software products.', path: '/AppDevelopment' },
        { img: mentorship, title: 'Mentorship', desc: 'A mentoring program is a structured initiative designed to pair experienced individuals with less experienced ones to provide guidance, support and knowledge transfer.', path: '/Mentorship' },
        { img: incupation, title: 'Loans & Investment', desc: 'The incubation process allows entrepreneurs to preserve capital and gain external support to accelerate their businesses growth. Through business incubation, the Enterprise Center captures.', path: '/Incubation' }
      ].map((service, index) => (
        <div
          key={index}
          className="group card1 p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition duration-300 cursor-pointer"
          onClick={() => handleServiceClick(service.path)}
        >
          <img className="w-full h-60 object-cover rounded-t-lg" src={service.img} alt={service.title} />
          <h3 className="text-2xl text-[#17212E] text-center font-bold mt-4 group-hover:text-[#F5CF6B]">{service.title}</h3>
          <p className="mt-2 text-[#17212E]">{service.desc}</p>
        </div>
      ))}
    </div>
  </div>
</section>



      <section className="bg-gray-100 md:py-10 font-times">
        <div className="container mx-auto px-6 text-center">
          <h3 className="md:text-2xl text-xl text-[#F5CF6B] px-2 py-1  md:py-2 md:mx-80 md:font-bold border-2 bg-[#17212E]">Read Our Customer Testimonials</h3>
          <p className="mt-4 md:mt-10 text-gray-800">Our Clients Are Our Partners And We Can't Imagine a Better Future For Our Company Without Helping Them Reach Their Objectives</p>
          <div className="mt-10 overflow-x-auto">
            <div className="flex space-x-4 lg:space-x-20 py-4">
              {[
                { img: "https://xsgames.co/randomusers/assets/avatars/male/55.jpg", text: "Altruisty provided excellent service and innovative solutions for our business growth. Highly recommended!", name: "John Doe, CEO of ExampleCorp" },
                { img: "https://xsgames.co/randomusers/assets/avatars/male/26.jpg", text: "Altruisty's CEO personally attends client meetings and gives his feedback on business growth strategies.", name: "Roy Smith, Developer" },
                { img: "https://xsgames.co/randomusers/assets/avatars/male/34.jpg", text: "The team at Altruisty is knowledgeable and professional. They helped us achieve our goals effectively.", name: "Jane Smith, Marketing Director at SampleCompany" },
                { img: "https://xsgames.co/randomusers/assets/avatars/female/49.jpg", text: "Fantastic experience working with Altruisty. Their solutions are top-notch and customer service is outstanding.", name: "Lindesay Rune, CTO of DemoEnterprises" },
                { img: "https://xsgames.co/randomusers/assets/avatars/female/30.jpg", text: "At the beginning I thought that the price was a little high for what they offer, but they over-deliver each and every time.", name: "Wanda, Manager" },
                { img: "https://xsgames.co/randomusers/assets/avatars/female/1.jpg", text: "I recommend Altruisty to every business owner or growth leader that wants to take their company to the next level.", name: "Emma, Business Owner" }
              ].map((testimonial, index) => (
                <div key={index} className="p-6 text-left flex-none w-64">
                  <img className="w-16 h-16 rounded-full mx-auto" src={testimonial.img} alt={testimonial.name} />
                  <p className="mt-4 italic text-center">"{testimonial.text}"</p>
                  <p className="mt-2 text-gray-800 font-semibold text-center">- {testimonial.name}</p>
                </div>
              ))}
            </div>
           
          </div>
        </div>
      </section>
      
<Footer/>
    </>
  );
};

export default Altruistyservices;

