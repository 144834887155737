
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import backgroundImage1 from './homebanner.png'; // Adjust the path based on your project structure
import backgroundImage2 from './whiteeee.jpg';
import backgroundImage3 from './homebanner2.png';
import backgroundImage4 from './homebanner3.png';

function Banner() {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [backgroundImage4];

  const [firstWordLetters, setFirstWordLetters] = useState([]);
  const [secondWordLetters, setSecondWordLetters] = useState([]);
  const [thirdWordLetters, setThirdWordLetters] = useState([]);

  useEffect(() => {
    const firstWord = "Empowering";
    const secondWord = "Future";
    const thirdWord = "Leaders";

    const firstWordArray = firstWord.split('').map((char, index) => ({
      char,
      style: { animationDelay: `${index * 0.1}s` },
    }));

    const secondWordArray = secondWord.split('').map((char, index) => ({
      char,
      style: { animationDelay: `${(firstWord.length + index) * 0.1}s` },
    }));

    const thirdWordArray = thirdWord.split('').map((char, index) => ({
      char,
      style: { animationDelay: `${(firstWord.length + secondWord.length + index) * 0.1}s` },
    }));

    setFirstWordLetters(firstWordArray);
    setSecondWordLetters(secondWordArray);
    setThirdWordLetters(thirdWordArray);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="relative w-full h-[300px] md:h-screen flex items-center justify-center overflow-hidden">
      <div className="absolute w-full h-full bg-cover bg-center animate-zoomIn" style={{ backgroundImage: `url(${images[currentImage]})` }}></div>
      <div className="absolute w-full h-full bg-black opacity-40 blur -md"></div>
      <div className="absolute w-full text-center z-10">
        <h1 className="md:mt-0 mt-7 text-3xl md:text-7xl md:mb-3 font-extrabold text-white flex justify-center">
          {firstWordLetters.map((letter, index) => (
            <span key={index} className="inline-block opacity-0 animate-fade-in" style={letter.style}>
              {letter.char}
            </span>
          ))}
          <span className="mx-2 md:mx-3"></span>
          {secondWordLetters.map((letter, index) => (
            <span key={index} className="inline-block opacity-0 animate-fade-in" style={letter.style}>
              {letter.char}
            </span>
          ))}
          <span className="mx-2 md:mx-3"></span>
          {thirdWordLetters.map((letter, index) => (
            <span key={index} className="inline-block opacity-0 animate-fade-in" style={letter.style}>
              {letter.char}
            </span>
          ))}
        </h1>
        <p className="md:text-2xl text-xl font-bold text-white mb-6 mt-3">Let's make your dreams a reality!</p>
        <div>
          <Link to="/services">
          <button className="mt-4 bg-[#17212E] pt-2 pb-2   text-[#F5CF6B]  font-bold py-2 px-4 rounded-l transform transition-transform hover:scale-105 active:scale-105">

              Explore Services
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Banner;
