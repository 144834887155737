import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import { AiOutlineInfoCircle, AiOutlineUser, AiOutlineEye,AiOutlineBulb } from 'react-icons/ai';
import slide_image_1 from '../assets/compet1.jpg';
import slide_image_2 from '../assets/compet2.jpg';
import slide_image_3 from '../assets/compet3.jpg';
import slide_image_4 from '../assets/compet4.jpg';

import WhatsAppStickyButton from "../chunkpages/WhatsappStickyButton";
function MusicalCompetitions() {
  return (
    <>
    <section className='grid place-content-center justify-center mx-auto grid-cols-1 gap-3'>
    <div className=" text-4xl md:text-4xl lg:text-6xl font-bold mx-auto my-[5rem] mt-[8rem]">Competitions</div>
    <div className='w-[25rem] md:w-[50rem] lg:w-[100rem] lg:h-full lg:mb-[10rem] mx-auto '>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={'2'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
      >
     
        <SwiperSlide className='backdrop-blur-3xl bg-white/30 rounded-xl mx-auto border-2'>
        <div className='mx-auto'>
        <h1 className='text-xl mx-10 md:mx-[8rem] md:text-4xl lg:text-4xl font-bold  lg:mx-[20rem] my-10'> Introduction</h1>
          <img className='md:lg:h-[250px] lg:w-[500px]  rounded-xl mx-auto my-8'   src={slide_image_1} alt="slide_image" />
          <p className=" text-white font-semibold mx-auto md:mx-6 text-sm md:text-xl lg:text-xl lg:mx-[6rem]"> Crafting user-friendly mobile applications tailored to your needs,</p>
          <button className=" mx-10 md:mx-[8rem] md:mt-8 lg:mx-[20rem] mt-4">
      <a href="/" className="relative inline-block text-sm group">
       <span className="relative h-[50px] z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-black transition-colors duration-300 ease-out border-2 border-black rounded-lg group-hover:text-white">
        <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
       <span className="absolute left-0 w-40 h-40 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-black group-hover:-rotate-180 ease"></span>
      <span className="relative">Learn more</span>
      </span>
      <span className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-black rounded-lg group-hover:mb-0 group-hover:mr-0" data-rounded="rounded-lg"></span>
           </a>
         </button>
          </div>
        </SwiperSlide>
        <SwiperSlide className='backdrop-blur-xl bg-white/30 rounded-xl border-2'>
        <div className='mx-auto'>
        <h1 className='text-xl mx-10 md:mx-[8rem] md:text-4xl lg:text-4xl font-bold  lg:mx-[20rem] my-10'> Introduction</h1>
          <img className='md:lg:h-[250px] lg:w-[500px]  rounded-xl mx-auto my-8'   src={slide_image_2} alt="slide_image" />
          <p className=" text-white font-semibold mx-auto md:mx-6 text-sm md:text-xl lg:text-xl lg:mx-[6rem]"> Crafting user-friendly mobile applications tailored to your needs,</p>
          <button className=" mx-10 md:mx-[8rem] md:mt-8 lg:mx-[20rem] mt-4">
      <a href="/" className="relative inline-block text-sm group">
       <span className="relative h-[50px] z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-black transition-colors duration-300 ease-out border-2 border-black rounded-lg group-hover:text-white">
        <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
       <span className="absolute left-0 w-40 h-40 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-black group-hover:-rotate-180 ease"></span>
      <span className="relative">Learn more</span>
      </span>
      <span className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-black rounded-lg group-hover:mb-0 group-hover:mr-0" data-rounded="rounded-lg"></span>
           </a>
         </button>
          </div>
        </SwiperSlide>
        <SwiperSlide className='backdrop-blur-xl bg-white/30 rounded-xl border-2 '>
        <div className='mx-auto'>
        <h1 className='text-xl mx-10 md:mx-[8rem] md:text-4xl lg:text-4xl font-bold  lg:mx-[20rem] my-10'> Introduction</h1>
          <img className='md:lg:h-[250px] lg:w-[500px]  rounded-xl mx-auto my-8'   src={slide_image_3} alt="slide_image" />
          <p className=" text-white font-semibold mx-auto md:mx-6 text-sm md:text-xl lg:text-xl lg:mx-[6rem]"> Crafting user-friendly mobile applications tailored to your needs,</p>
          <button className=" mx-10 md:mx-[8rem] md:mt-8 lg:mx-[20rem] mt-4">
      <a href="/" className="relative inline-block text-sm group">
       <span className="relative h-[50px] z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-black transition-colors duration-300 ease-out border-2 border-black rounded-lg group-hover:text-white">
        <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
       <span className="absolute left-0 w-40 h-40 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-black group-hover:-rotate-180 ease"></span>
      <span className="relative">Learn more</span>
      </span>
      <span className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-black rounded-lg group-hover:mb-0 group-hover:mr-0" data-rounded="rounded-lg"></span>
           </a>
         </button>
          </div>
        </SwiperSlide>
        <SwiperSlide className='backdrop-blur-xl bg-white/30 rounded-xl border-2 '>
        <div className='mx-auto'>
        <h1 className='text-xl mx-10 md:mx-[8rem] md:text-4xl lg:text-4xl font-bold  lg:mx-[20rem] my-10'> Introduction</h1>
          <img className='md:lg:h-[250px] lg:w-[500px]  rounded-xl mx-auto my-8'   src={slide_image_4} alt="slide_image" />
          <p className=" text-white font-semibold mx-auto md:mx-6 text-sm md:text-xl lg:text-xl lg:mx-[6rem]"> Crafting user-friendly mobile applications tailored to your needs,</p>
          <button className=" mx-10 md:mx-[8rem] md:mt-8 lg:mx-[20rem] mt-4">
      <a href="/" className="relative inline-block text-sm group">
       <span className="relative h-[50px] z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-black transition-colors duration-300 ease-out border-2 border-black rounded-lg group-hover:text-white">
        <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
       <span className="absolute left-0 w-40 h-40 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-black group-hover:-rotate-180 ease"></span>
      <span className="relative">Learn more</span>
      </span>
      <span className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-black rounded-lg group-hover:mb-0 group-hover:mr-0" data-rounded="rounded-lg"></span>
           </a>
         </button>
          </div>
        </SwiperSlide>
    

        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </div>
          <div className="swiper-pagination -mb-6"></div>
        </div>
      </Swiper>
      </div>
    
    </section>
    <section className="">
  <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div className="max-w-screen-lg  sm:text-lg ">
          <h2 className="mb-4 text-4xl tracking-tight font-bold text-orange-600 ">Powering innovation at <span className="font-extrabold">200,000+</span> companies worldwide</h2>
          <p className=" text-white mb-4 font-semibold">Track work across the enterprise through an open, collaborative platform. Link issues across Jira and ingest data from other software development tools, so your IT support and operations teams have richer contextual information to rapidly respond to requests, incidents, and changes.</p>
          <p className=" text-white mb-4 font-medium">Deliver great service experiences fast - without the complexity of traditional ITSM solutions.Accelerate critical development work, eliminate toil, and deploy changes with ease.</p>
          
      </div>
  </div>
</section>
<section className=" ">
    <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
        <div className="font-semibold  sm:text-lg ">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-orange-600 ">We didn't reinvent the wheel</h2>
            <p className=" text-white mb-4">We are strategists, designers and developers. Innovators and problem solvers. Small enough to be simple and quick, but big enough to deliver the scope you want at the pace you need. Small enough to be simple and quick, but big enough to deliver the scope you want at the pace you need.</p>
            
        </div>
        <div className="grid grid-cols-2 gap-4 mt-8">
            <img className="w-full rounded-lg transition-all duration-300 transform bg-slate shadow-lg hover:-translate-y-2 hover:shadow-2xl" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-2.png" alt="office content 1"/>
            <img className="mt-4 w-full lg:mt-10 rounded-lg transition-all duration-300 transform bg-slate shadow-lg hover:-translate-y-2 hover:shadow-2xl" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-1.png" alt="office content 2"/>
        </div>
    </div>
</section>
<div className='text-white w-full backdrop-blur p-5  flex flex-row flex-wrap justify-evenly gap-10 '>

<div className='w-80 ' >
    <h1 className='text-2xl font-bold flex flex-row items-center gap-3 text-green-600'><AiOutlineInfoCircle />About us </h1>
    <p className='mt-5 text-md font-semibold text-white'>Welcome to N_chat, the revolutionary chat application crafted by Naveen. Harnessing the power of cutting-edge technologies including React JS, Tailwind CSS, Node JS, Express JS, Mongo DB, and Firebase, N_chat promises a seamless and secure chatting experience like never before.</p>
</div>

<div className='w-80 ' >
    <h1 className='text-2xl font-bold flex flex-row items-center gap-3 text-green-600'><AiOutlineUser/>Meet Siva Sathiya:</h1>
    <p className='mt-5 text-md font-semibold text-white'>N_chat is the brainchild of Naveen, a visionary developer passionate about creating innovative solutions for modern communication needs. With N_chat, Naveen aims to revolutionize the way people connect and interact online.</p>
</div>

<div className='w-80 text-md' >
    <h1 className='text-2xl  font-bold flex flex-row items-center gap-3 text-green-600'><AiOutlineBulb/>Our Mission</h1>
    <p className='mt-5 text-md font-semibold text-white'>Our mission is to provide a secure and seamless chatting experience to users across the globe. We are committed to ensuring that our users can chat with their friends and family without any interruptions or security concerns.</p>
</div>


<div className='w-80' >
    <h1 className='text-2xl font-bold flex flex-row items-center gap-3 text-green-600'><AiOutlineEye/>Our Vision</h1>
    <p className='mt-5 text-md font-semibold text-white'>Our vision is to become the go-to chat application for users worldwide. We aim to provide a platform that is not only secure and reliable but also user-friendly and feature-rich.</p>
</div>



</div>
</>
  );
}

export default MusicalCompetitions;
