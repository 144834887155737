
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'aos/dist/aos.css'; // Import AOS CSS
import AOS from 'aos'; // Import AOS library

import Landingpage from '../chunkpages/Landingpage';
import Stats from '../chunkpages/Stats';
import Testimonials from '../chunkpages/Testimonials';
import Endofhome from '../chunkpages/Endofhome';
import Internship from '../chunkpages/Internship';
import Tpprogram from '../chunkpages/Tpprogram';
import Footer from '../UIcomp/Footer';
import Trainingsintens from '../chunkpages/Trainings&intens';
import Domains from '../chunkpages/Domains';
import WhatsAppStickyButton from '../chunkpages/WhatsappStickyButton';
import Whatsappgroup from '../chunkpages/Whatsappgroup';
import HeadlineSection from '../chunkpages/HeadlineSection';
import Homepackage from '../chunkpages/Homepackage';
import Homeservice from '../chunkpages/Homeservice';
import Verify from '../SubPagesCompos/Verify';
import HeadlinesSection from '../chunkpages/HeadlinesSection';
import BrandStorySection from '../chunkpages/BrandStorySection';
const Home = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true }); // Initialize AOS with desired settings
  }, []);

  return (
    <div className="landing-page-contanier font-times">
      <div className="landing-stats-container">
        <Landingpage />
      </div>
      <br />
      <br />
      <div>
  <HeadlinesSection/>
</div>
      {/* <div className="landing-stats-container pb-10 pt-20 ">
        <HeadlineSection />
      </div> */}


      <br />
      {/* <div className="landing-stats-container">
        <Homeservice />
      </div>
      <div className="landing-stats-container">
        <Homepackage />
      </div>

      <div className="landing-stats-container">
        <Trainingsintens />
      </div> */}

      <div className="landing-stats-container">
        <Internship />
      </div>
      <div className="landing-stats-container">
        <Whatsappgroup />
      </div>
      <div>
        <BrandStorySection/>
      </div>
      <div className="landing-stats-container">
        <Tpprogram />
      </div>

     

      <Testimonials />
      {/* <div className="landing-stats-container">
        <Homeservice />
      </div>
      <div className="landing-stats-container">
        <Homepackage />
      </div>

      <div className="landing-stats-container">
        <Trainingsintens />
      </div> */}
       
     
      <Endofhome />

      <WhatsAppStickyButton />
      <div className="endofhome-container">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
 






