import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

export default function Otherevents() {
  // Banner Slide
  const slide = [
    {
      title: "Seminars and Webinars",
      description:
        "We specialize in organizing interactive seminars and webinars online, providing a dynamic platform for knowledge sharing and professional growth across various industries. Our events feature expert speakers, engaging presentations, and interactive discussions to foster learning and networking opportunities. Participants can join from anywhere, making our online platform accessible and convenient. We offer a diverse range of topics, from emerging trends to skill development, tailored to meet the needs of modern professionals. With user-friendly interfaces and flexible scheduling options, we ensure a seamless and engaging experience for all attendees. Join us to expand your knowledge, connect with industry experts, and stay ahead in your field with our innovative seminars and webinars.  ",
      icon: "/images/Other_Events_Slide1.jpg",
    },
    {
      title: "Workshops and Training Sessions",
      description:
        "Engage in enriching learning experiences through our meticulously crafted workshops and training sessions conducted online, featuring expert facilitators, hands-on activities, and practical skill development tailored to your industry needs. Our interactive platform ensures seamless participation and collaboration, fostering a dynamic learning environment for all attendees. From honing specific skills to exploring new techniques and best practices, our workshops are designed to empower individuals and teams for success in today's competitive landscape. Join us to elevate your expertise, connect with industry peers, and stay ahead in your professional journey with our innovative online workshops and training sessions. ",
      icon: "/images/Other_Events_Slide4.jpg",
    },
    {
      title: "Chief Guest Organising ",
      description:
        "Assembling a prestigious lineup of chief guests for your event involves strategic planning and coordination. Begin by identifying influential individuals who align with your event's theme and goals. Craft compelling invitations highlighting the benefits of participation and the value they bring to the audience. Coordinate logistics such as travel arrangements, accommodations, and scheduling to ensure a seamless experience. Provide detailed briefings on the event agenda, audience demographics, and their role during the program. Offer personalized attention and support throughout their participation to enhance their experience. Express gratitude and recognition through thoughtful gestures and acknowledgments during the event. Follow up post-event to maintain relationships and seek feedback for future improvements.",
      icon: "/images/Other_Events_Slide7.jpg",
    },
    {
      title: "Digital Media and Marketing ",
      description:
        "Leverage digital media and marketing to connect with your target audience effectively, utilizing data insights for personalized campaigns and impactful content creation. Enhance brand visibility and engagement through strategic use of social media, SEO, and innovative technologies like AI and AR. Measure campaign success with key metrics, making data-driven optimizations for continuous improvement and staying ahead of evolving trends. Collaborate with influencers and partners to amplify your brand's reach and credibility in the digital landscape. Stay agile and adaptable, embracing new tools and strategies to meet consumer preferences and achieve marketing goals.",
      icon: "/images/Other_Events_Slide10.jpg",
    },
  ];
  //Individual events card

  const steps = [
    {
      title: "Seminars and Webinars",
      description:
        "Join our interactive online seminars and webinars featuring expert speakers, engaging presentations, and diverse topics tailored for modern professionals, fostering knowledge sharing, networking, and professional growth across industries.",
      icon: "/images/Other_Events_Slide2.jpg",
      link: "/inperson",
    },
    {
      title: "Workshops and Training Sessions",
      description:
        "Join our dynamic online workshops and training sessions for hands-on skill development and expert guidance across industries, ensuring accessibility and convenience for professional growth.",
      icon: "/images/Other_Events_Slide5.jpg",
      link: "/inperson",
    },
    {
      title: "Chief Guest Organising ",
      description:
        "Strategically assemble a prestigious lineup of chief guests for your event, ensuring seamless coordination, personalized attention, and post-event relationship maintenance for a memorable and impactful experience.",
      icon: "/images/Other_Events_Slide8.jpg",
      link: "/inperson",
    },
    {
      title: "Digital Media and Marketing ",
      description:
        "Craft data-driven digital media strategies leveraging innovative technologies and strategic collaborations to engage audiences and drive continuous growth in today's competitive landscape.",
      icon: "/images/Other_Events_Slide11.jpg",
      link: "/inperson",
    },
  ];

  // Data for the slider
  const data = [
    {
      src: "images/Other_Events_Slide3.jpg",
    },
    {
      src: "images/Other_Events_Slide6.jpg",
    },
    {
      src: "images/Other_Events_Slide9.jpg",
    },
    {
      src: "images/Other_Events_Slide12.jpg",
    },
  ];

  // Slider settings
  const settings = {
    className: "center",
    centerMode: true,
    dots: true,
    infinite: true,
    speed: 1000,
    centerPadding: "60px",
    cssEase: "linear",
    autoplaySpeed: 2500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 410,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <section className="bgcolor">
      <div className=" container px-2 py-20 mx-auto max-xl:flex flex-col">
        {/* Banner  */}

        <div className="w-full mx-8 my-8 max-sm flex flex-col justify-center">
          <section className="grid gap-4 w-full flex justify-center">
            <div
              id="servicesSlide"
              className="h-96 sm:w-[600px] md:w-[800px] lg:w-[1000px] w-96 lg:pt-10 mx-auto"
            >
              <Slider {...settings}>
                {slide.map((item, index) => (
                  <div
                    key={index}
                    className="text-gray-500 sm:text-lg :text-gray-400"
                  >
                    <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-center text-orange-600">
                      {item.title}
                    </h2>
                    <div className="flex flex-col sm:flex-row items-center justify-center">
                      <img
                        src={item.icon}
                        className="w-1/1 sm:w-96  mb-4 sm:mb-0 sm:mr-4"
                        alt={item.title}
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </section>

          <div className="flex justify-center sm:pt-43 pt-10 md:pt-0">
            <button
              className="w-full md:w-1/2 lg:w-1/4 p-3 justify-center flex  bg-indigo-600 rounded-lg text-slate-300 group-hover:mb-0 group-hover:mr-0 hover:bg-violet-500 hover:text-slate-950 active:bg-violet-700 transition-colors duration-1000 ease-out border-2 mt-0"
              onClick={() =>
                (window.location.href = "https://docs.google.com/forms/u/0/")
              }
            >
              Book Now!
            </button>
          </div>
        </div>
      </div>

      {/* Events  */}

      <section className="py-12 ">
        <div className="py-12 ">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center items-center">
              <h2 className="text-4xl text-orange-600 font-semibold tracking-wide uppercase">
                Events Organized
              </h2>
            </div>

            <div className="mt-10 backdrop-blur">
              <ul className="mx-auto w-full md:w-full md:grid md:grid-cols-2 lg:grid-cols-4 gap-10">
                {steps.map((step, index) => (
                  <li key={index} className="mt-10 md:mt-0">
                    <div className="  w-full h-full items-start p-4 border-2 border-inherit hover:border-black transition-all duration-300 transform bg-slate shadow-lg place-items-center rounded-xl hover:-translate-y-2 hover:shadow-2xl  ">
                      <img
                        src={step.icon}
                        width={350}
                        alt="Card Images"
                        className="m-auto rounded-lg"
                      />

                      <h3 className="mb-2 text-green-600 text-xl font-bold py-4">
                        {step.title}
                      </h3>
                      <p className="text-white">{step.description}</p>
                      <Link to={step.link}></Link>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Carousel Section */}

      <section className="grid gap-4 w-full">
        <div className="h-96 sm:w-[600px] md:w-[800px] lg:w-[600px] w-96  gap-20 mx-auto">
          <Slider {...settings}>
            {data.map((item, index) => (
              <div key={index} className="w-full w-1/1 flex  ">
                <img
                  className="object-contain object-center w-full max-w-[450px]  md-h-30 rounded-xl"
                  src={item.src}
                  alt="blog"
                />
              </div>
            ))}
          </Slider>
        </div>
      </section>

      {/* Other Details  */}

      <section className="py-12 ">
        <div className="py-12 ">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center items-center">
              <h2 className="text-4xl text-orange-600 font-semibold tracking-wide uppercase">
                Other Details
              </h2>
            </div>

            <div className="mt-10 backdrop-blur">
              <ul className="mx-auto w-96 md:w-full md:grid md:grid-cols-2 lg:grid-cols-2 gap-10 ">
                {slide.map((step, index) => (
                  <li key={index} className="mt-10 md:mt-0">
                    <div className="  items-start p-4 border-2 border-inherit hover:border-black transition-all duration-300 transform bg-slate shadow-lg place-items-center rounded-xl hover:-translate-y-2 hover:shadow-2xl h-full">
                      <h3 className="mb-2 text-green-600 text-xl font-bold py-4">
                        {step.title}
                      </h3>
                      <p className="text-white">{step.description}</p>
                      <Link to={step.link}></Link>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Booking Button */}
      <div className="flex justify-center">
        <button
          className="w-full md:w-1/2 lg:w-1/4  p-3 justify-center flex ease-linear bg-indigo-600 rounded-lg text-slate-300 group-hover:mb-0 group-hover:mr-0 hover:bg-violet-500 hover:text-slate-950 active:bg-violet-700 transition-colors duration-1000 ease-out border-2"
          onClick={() =>
            (window.location.href = "https://docs.google.com/forms/u/0/")
          }
        >
          Book Now!
        </button>
      </div>
    </section>
  );
}
