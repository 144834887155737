import React from "react";
import Eventsandcompetition from "../SubPagesCompos/Eventsandcompetition";

import WhatsAppStickyButton from "../chunkpages/WhatsappStickyButton";
function Eventsprogram() {
  return (
    <div>
      <Eventsandcompetition></Eventsandcompetition>
      
      <WhatsAppStickyButton />
    </div>
  );
}

export default Eventsprogram;
