import Masonry from "react-layout-masonry";

const EventPage = () => {
	return (
		<div className="pt-40 container mx-auto">
			<div className="w-full">
				<p className="p-2 w-1/2 text-lg font-semibold text-white bg-blue-950 mx-auto rounded-lg m-4 text-center">
					Lorem ipsum dolor sit amet consectetur adipisicing elit
				</p>
			</div>
			<div className="flex justify-around my-20">
				<img
					className="h-[400px] object-cover object-center rounded-lg sticky top-40"
					src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image.jpg"
					alt=""
				/>
				<div className="w-1/2">
					<div>
						<p className="text-xl font-semibold">Description</p>
						<p className="indent-10 my-4 font-light leading-7">
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis
							repudiandae non unde aliquid harum! Velit amet ipsam dicta id
							fugiat! Deserunt reprehenderit quod architecto dicta sequi
							assumenda obcaecati voluptatem laboriosam eaque reiciendis libero
							delectus dolore beatae, esse quidem porro at aspernatur vel
							doloremque consectetur. Necessitatibus quam ducimus eos
							dignissimos laborum!
						</p>
					</div>
					<Masonry
						columns={{ 640: 1, 1024: 2, 1280: 3 }}
						gap={16}
						className="mt-10"
					>
						<img
							class="rounded-lg"
							src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image.jpg"
							alt=""
						/>
						<img
							class="rounded-lg"
							src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-1.jpg"
							alt=""
						/>
						<img
							class="rounded-lg"
							src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-2.jpg"
							alt=""
						/>
						<img
							class="rounded-lg"
							src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-3.jpg"
							alt=""
						/>
						<img
							class="rounded-lg"
							src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-4.jpg"
							alt=""
						/>
						<img
							class="rounded-lg"
							src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-5.jpg"
							alt=""
						/>
						<img
							class="rounded-lg"
							src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-6.jpg"
							alt=""
						/>
						<img
							class="rounded-lg"
							src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-7.jpg"
							alt=""
						/>
						<img
							class="rounded-lg"
							src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-8.jpg"
							alt=""
						/>
						<img
							class="rounded-lg"
							src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-9.jpg"
							alt=""
						/>
						<img
							class="rounded-lg"
							src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-10.jpg"
							alt=""
						/>
						<img
							class="rounded-lg"
							src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-11.jpg"
							alt=""
						/>
					</Masonry>
				</div>
			</div>
		</div>
	);
};

export default EventPage;
