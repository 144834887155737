import React, { useState, useEffect } from "react";

export default function Cards2() {
  const cards = [
    {
      img: `images/Projects1.jpg`,
      title: `Online & Offline Project Training`,
      // desc: `Collaborate with mentors and peers to refine your project idea, develop prototypes, have a conversation with our team and deliver a compelling presentation.`,
    },
    {
      img: `images/Projects2.jpg`,
      title: `Arrange Any Domain Project`,
      // desc: `Explore diverse domains and select a project aligned with your interests and career goals.Embark on a challenging yet rewarding journey to showcase your skills and innovation.`,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const nextSlide = () => {
    const slidesToShow = windowWidth >= 768 ? 2 : 1; // 2 cards for desktop, 1 card for mobile
    setCurrentIndex((prevIndex) => (prevIndex + slidesToShow) % cards.length);
  };

  const prevSlide = () => {
    const slidesToShow = windowWidth >= 768 ? 2 : 1; // 2 cards for desktop, 1 card for mobile
    setCurrentIndex(
      (prevIndex) => (prevIndex - slidesToShow + cards.length) % cards.length
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000); // Change slide every 5 seconds
    return () => clearInterval(interval);
  }, [windowWidth]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getVisibleCards = () => {
    const visibleCards = [];
    const slidesToShow = windowWidth >= 768 ? 2 : 1; // 3 cards for desktop, 1 card for mobile
    for (let i = 0; i < slidesToShow; i++) {
      visibleCards.push(cards[(currentIndex + i) % cards.length]);
    }
    return visibleCards;
  };

  return (
    <div className="flex flex-row w-full h-full mx-auto justify-center items-center">
      <div className="flex flex-row w-full justify-start max-md:space-y-5 max-md:flex-col">
        <div className="flex flex-col space-y-3 bg-Darkblue w-full h-[550px] max-md:h-[350px] pt-5 pl-5 pr-5 pb-5 lg:mr-[50px] justify-evenly items-center max-md:items-center rounded-lg">
          <h1 className="text-2xl font-bold text-Yellow text-center">Our Trainings</h1>
          <h2 className="text-lg text-White text-center md:text-justify">1. Online and Offline Training</h2>
          <h2 className="text-lg text-White text-center md:text-justify">2. Domain project Arrangement</h2>
          <h2 className="text-lg text-White text-center md:text-justify">3. Team Collaborative Assessments</h2>
          <h2 className="text-lg text-White text-center md:text-justify">4. Structural Presentation </h2>
          <span className="text-Yellow">Collaborate with mentors and peers to refine your project idea, develop prototypes, have a conversation with our team and deliver a compelling presentation.</span>
          <span className="text-Yellow max-md:hidden">Explore diverse domains and select a project aligned with your interests and career goals.Embark on a challenging yet rewarding journey to showcase your skills and innovation.</span>       
        </div>
        <div
          className="flex flex-col max-md:flex-col justify-between bg-gray-200 items-center"
          style={{ width: "100%" }}
        >
          {getVisibleCards().map((card, index) => (
            <div
              key={index}
              className="flex flex-col lg:w-[650px] justify-center bg-Darkblue items-center max-md:pr-5 max-md:pl-5 border-none rounded-xl shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl"
              data-aos="flip-in"           
            >
              <img
                src={card.img}
                alt="img"
                className="w-[200px] h-[200px] lg:h-[250px] lg:w-[600px] max-md:w-[400px] object-cover pt-5 pb-5 rounded-lg"             
              />
              {/* <div className="p-4 flex flex-col justify-between flex-1">
              <div>
                <h1 className="text-xl font-bold mb-2 text-Darkblue">
                  {card.title}
                </h1>
                <p className="text-Darkblue text-sm text-justify">{card.desc}</p>
              </div>
              
            </div> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
