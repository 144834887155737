import React, { useState, useEffect } from "react";

export default function Cards2() {
  const cards = [
    {
      img: `images/Symposium_Slide5.jpg`,
      title: `Core Domain Training Internship`,
      // desc: `Dive deep into your field of interest with our specialized core domain training internship. Our expert instructors provide in-depth knowledge and practical skills tailored to your chosen domain, ensuring you're equipped with industry-relevant expertise.`,
    },
    {
      img: `images/online_t.jpg`,
      title: `Technology Domain Training Internship`,
      // desc: `Dive deep into your field of interest with our specialized Technology Domain training internship. Our expert instructors provide in-depth knowledge and practical skills tailored to your chosen domain, ensuring you're equipped with industry-relevant expertise.`,
    },
    {
      img: `images/Seminar.jpg`,
      title: `Student Choice Training Internship`,
      // desc: `Dive deep into your field of interest with our specialized student choice training internship. Our expert instructors provide in-depth knowledge and practical skills tailored to your chosen domain, ensuring you're equipped with industry-relevant expertise.`,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const nextSlide = () => {
    const slidesToShow = windowWidth >= 768 ? 3 : 1; // 3 cards for desktop, 1 card for mobile
    setCurrentIndex((prevIndex) => (prevIndex + slidesToShow) % cards.length);
  };

  const prevSlide = () => {
    const slidesToShow = windowWidth >= 768 ? 3 : 1; // 3 cards for desktop, 1 card for mobile
    setCurrentIndex(
      (prevIndex) => (prevIndex - slidesToShow + cards.length) % cards.length
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000); // Change slide every 3 seconds
    return () => clearInterval(interval);
  }, [windowWidth]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getVisibleCards = () => {
    const visibleCards = [];
    const slidesToShow = windowWidth >= 768 ? 3 : 1; // 3 cards for desktop, 1 card for mobile
    for (let i = 0; i < slidesToShow; i++) {
      visibleCards.push(cards[(currentIndex + i) % cards.length]);
    }
    return visibleCards;
  };

  return (
    <div className="relative w-full max-w-6xl mx-auto">
      <div className="overflow-hidden">
        <div
          className="flex flex-row justify-between items-center max-w-6xl transition-transform duration-500"
          style={{ width: "100%" }}
        >
          {getVisibleCards().map((card, index) => (
            <div key={index} className="flex w-full md:w-1/3 p-2" data-aos="fade-right">
              <div className="flex flex-row max-md:flex-col bg-Darkblue lg:pt-5 lg:pl-5 lg:pb-5 md:pl-2 md:pr-2 pt-3 max-md:items-center transform transition duration-500 hover:scale-105 hover:shadow-2xl rounded-lg">
                <img
                  src={card.img}
                  alt="img"
                  className="justify-between items-center w-[150px] h-[150px] object-cover rounded-full"
                />
                <div className="p-4 flex flex-col flex-grow">
                  <h1 className="text-xl font-bold mb-2 text-Yellow">
                    {card.title}
                  </h1>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
