import React, { useEffect, useRef, useState } from 'react';
import Aboutushero from '../chunkpages/Aboutushero';
import Verttimeline from '../chunkpages/Verttimeline';
import Mediagal from '../chunkpages/Mediagal';
import Footer from '../UIcomp/Footer';
import Ourteam from '../chunkpages/Ourteam';
import WhatsAppStickyButton from '../chunkpages/WhatsappStickyButton';
import Achievements from '../chunkpages/Achiments';
import AOS from "aos"
import "aos/dist/aos.css"

export default function Aboutus() {
  const videoRef = useRef(null);
  const [videoInView, setVideoInView] = useState(false);
  const [achievementsLoaded, setAchievementsLoaded] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (videoRef.current) {
        const top = videoRef.current.getBoundingClientRect().top;
        const bottom = videoRef.current.getBoundingClientRect().bottom;
        const windowHeight = window.innerHeight;

        // Check if the video is in the viewport
        if (top < windowHeight && bottom > 0) {
          setVideoInView(true);
        } else {
          setVideoInView(false);
        }
      }
    };

  AOS.init( {once: false})
    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = (event) => {
    // Prevent scrolling if the Achievements component is in view
    if (videoInView) {
      event.preventDefault();
    }
  };

  const handleAchievementsLoaded = () => {
    setAchievementsLoaded(true);
  };

  return (
    <div onWheel={handleScroll} className=' font-times mr-2'>
     <br/>
     {/* <div data-aos-duration="800" data-aos="fade-right"> */}
      <Verttimeline />
     {/* </div> */}
      <Mediagal />
      <div ref={videoRef}>
        {videoInView && !achievementsLoaded && <Achievements onLoad={handleAchievementsLoaded} />}
      </div>
      <br/>
      <div data-aos="fade-right">
      <Ourteam />
      </div>
      <WhatsAppStickyButton />
      <Footer />
    </div>
  );
}