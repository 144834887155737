import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Imageslider from "../chunkpages/imageslider";
import Cards2 from "../chunkpages/Cards2";
import Cards3 from "../chunkpages/Cards3";
import Cards4 from "../chunkpages/Cards4";
import Cards5 from "../chunkpages/Cards5";
import WhatsAppStickyButton from "../chunkpages/WhatsappStickyButton";
import Footer from "../UIcomp/Footer";
import Trainingbanner from "../SubPagesCompos/Trainingbanner";
import SchoolData from "../Json/SchoolData.json";
import SchoolCarousel from "../Carousel/SchoolCarousel";
import CollegeData from "../Json/CollegeData.json";
import CollegeCarousel from "../Carousel/CollegeCarousel";
import FinalData from "../Json/FinalProject.json";
import FinalCarousel from "../Carousel/FinalProjectCarousel";

const imageSchool = {
  image1: require("../assets/kids1.jpg"),
  image2: require("../assets/kids2.jpg"),
  image3: require("../assets/kids3.jpg"),
};
const imageCollege = {
  image1: require("../assets/CollegeStudents3.jpg"),
  image2: require("../assets/CollegeStudents2.jpg"),
  image3: require("../assets/CollegeStudents1.jpg"),
};
const imageFinal = {
  image1: require("../assets/Projects1.jpg"),
  image2: require("../assets/Projects2.jpg"),
};

export default function Training() {
  const [schoolslides, setSchoolSlides] = useState([]);

  useEffect(() => {
    const mappedSchoolSlides = SchoolData.map((slide) => ({
      ...slide,
      image: imageSchool[slide.imageKey],
    }));
    setSchoolSlides(mappedSchoolSlides);
  }, []);

  const [collegeslides, setCollegeSlides] = useState([]);

  useEffect(() => {
    const mappedCollegeSlides = CollegeData.map((slide) => ({
      ...slide,
      image: imageCollege[slide.imageKey],
    }));
    setCollegeSlides(mappedCollegeSlides);
  }, []);

  const [finalslides, setFinalSlides] = useState([]);

  useEffect(() => {
    const mappedFinalSlides = FinalData.map((slide) => ({
      ...slide,
      image: imageFinal[slide.imageKey],
    }));
    setFinalSlides(mappedFinalSlides);
  }, []);

  return (
    <section className="pt-14 md:pt-24">
      <Trainingbanner />
      <div className="mt-4 lg:mt-20 ">
        <SchoolCarousel slides={schoolslides} styles="object-cover" />
      </div>
      <div className="mt-2 lg:mt-20 ">
        
          <CollegeCarousel slides={collegeslides} styles="object-cover" />
        </div>

        {/* online training*/}

        <div className="mt-10 lg:mt-20 mb-10">
          <FinalCarousel slides={finalslides} styles="object-cover" />
        </div>
    
      <Footer />
      <WhatsAppStickyButton />
    </section>
  );
}
