
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'aos/dist/aos.css'; // Import AOS CSS
import AOS from 'aos'; // Import AOS library

const data = [
  {
    title: 'Company Community Group',
    description: 'This group is for chatting in topic-based groups and getting important announcements.',
    icon: 'images/logo_design.png',
    link: 'https://chat.whatsapp.com/K0nY1iTIi7J7qOsbvBqVNl'
  },
  {
    title: 'Student Community Group',
    description: 'Workshop information, inter-college event info are shared in this group.',
    icon: 'images/logo_design.png',
    link: 'https://chat.whatsapp.com/LIFgMjj8GxIIgZk312vItl'
  },
  {
    title: 'Community Channel Group',
    description: 'Startup competitions, workshops, college event info are shared in this channel.',
    icon: 'images/logo_design.png',
    link: 'https://whatsapp.com/channel/0029VaDP8mR05MUeztfw842K'
  },
];

const Whatsappgroup = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
  }, []);

  return (
    <section className="pt-2 bg-gray-100 lg:mt-10 px-4 mt-4 ">
      <div className="text-center mb-12 lg:mt-4">
        <h1 className="text-2xl md:text-4xl text-[#17212E] font-times mb-4">WHATSAPP EXCLUSIVE ACCESS</h1>
        <p className="text-gray-600 max-w-2xl mx-auto">Join our exclusive WhatsApp groups and stay updated with the latest announcements, events, and more.</p>
      </div>
      <div className="space-y-8 max-w-screen-lg mx-auto">
        {data.map((item, index) => (
          <div
            key={index}
            className="flex flex-col md:flex-row items-center md:justify-between bg-white rounded-lg shadow-md p-6 transition-transform duration-500 hover:scale-105"
            data-aos="fade-up"
          >
            <div className="flex flex-col md:flex-row items-center space-x-4 mb-4 md:mb-0">
              <img className="w-16 h-16 mb-4 md:mb-0" src={item.icon} alt={item.title} />
              <div className="text-center md:text-left">
                <h2 className="text-lg sm:text-xl font-bold text-[#17212E]">{item.title}</h2>
                <p className="text-gray-700">{item.description}</p>
              </div>
            </div>
            <Link to={item.link}>
              <button className="px-4 py-2  bg-[#17212E] text-[#F5CF6B] rounded-lg hover:bg-[#F5CF6B] hover:text-black transition-colors duration-300">
                Join Chat
              </button>
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Whatsappgroup;
