
  
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../UIcomp/Footer";
import Launchpad from "../pageimg/launchpad.png"; // Ensure the correct path to the image

const Landingpage = () => {
  const [activeCard, setActiveCard] = useState(0);

  const cards = [{ title: 'Transforming Businesses through Innovation', description: 'Let\'s make your dreams a reality!' }];

  const numberOfVisibleCards = 1;
  const autoplayInterval = 2800;

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveCard((prevCard) => (prevCard === cards.length - 1 ? 0 : prevCard + 1));
    }, autoplayInterval);

    return () => clearInterval(timer);
  }, [cards.length]);

  const getVisibleCards = () => {
    const endIndex = activeCard + numberOfVisibleCards;
    if (endIndex >= cards.length) {
      return [...cards.slice(activeCard), ...cards.slice(0, endIndex % cards.length)];
    }
    return cards.slice(activeCard, endIndex);
  };

  const visibleCards = getVisibleCards();

  return (
    <section
      className="home bg-cover bg-center text-white text-center pb-10 pt-28 md:py-60 font-times"
      style={{
        backgroundImage: `url(${Launchpad})`,
        backgroundBlendMode: 'overlay',
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      }}
    >
      <div className="container px-6 bg-black md:bg-opacity-40   bg-opacity-0 rounded-lg p-6 md:p-12 max-w-2xl mx-auto">
        <h1 className="text-4xl md:text-5xl font-bold mb-4">  Transforming Businesses through Innovation</h1>
       
     
          <p className="text-2xl md:text-lg mb-4">{visibleCards[0].description}</p>
         
        </div>
     
    </section>
  );
};

export default Landingpage;
