import React, { useState } from "react";
import Footer from "../UIcomp/Footer";
import WhatsAppStickyButton from "../chunkpages/WhatsappStickyButton";

export default function IdeatoProduct() {
  const Modal = ({ handleClose, show, reason }) => {
    const showHideClassName = show
      ? "fixed inset-0 overflow-y-auto flex items-center justify-center"
      : "hidden";

    return (
      <div className={showHideClassName}>
        <div className="modal-overlay fixed inset-0 bg-black opacity-50"></div>

        <div className="modal-content bg-white p-6 md:p-8 rounded-lg shadow-lg z-50 w-full max-w-md relative">
          <button
            className="absolute top-0 right-0 m-4 text-gray-700 hover:text-gray-900"
            onClick={handleClose}
          >
            Close
          </button>
          <div className="text-center">
            <h1 className="text-3xl font-bold mb-4">{reason.title}</h1>
            <img
              className="w-full h-auto rounded-lg mb-4"
              src="/images/web3-bg.jpg"
              alt=""
            />
            <p className="text-gray-700">{reason.description}</p>
          </div>
        </div>
      </div>
    );
  };

  const reasons = [
    {
      title: "Idea Validation Analyst",
      description:
        "Are you bursting with innovative ideas but unsure of their potential success? Our Idea Validation Analyst service provides thorough market research and analysis to determine the viability of your concept. We'll assess market demand, competition, and potential challenges, giving you the insights needed to make informed decisions about pursuing your idea.",
    },
    {
      title: "Concept to Prototype Development",
      description:
        "Ready to bring your idea to life? Our Concept to Prototype Development service turns your vision into a tangible reality. Our expert team collaborates closely with you to refine your concept, create detailed plans, and build a prototype that showcases the core features and functionality of your product.",
    },
    {
      title: "MVP Development",
      description:
        "Launching a Minimum Viable Product (MVP) is crucial for testing your idea in the real world and gathering valuable feedback from users. Our MVP Development service helps you swiftly develop and deploy a scaled-down version of your product, allowing you to validate assumptions, iterate quickly, and adapt to market needs.",
    },
    {
      title: "Product Development",
      description:
        "Transforming your MVP into a fully-fledged product requires meticulous planning and execution. Our Product Development service guides you through every stage of the process, from design and development to testing and deployment. We leverage cutting-edge technologies and best practices to ensure your product meets the highest standards of quality and functionality.",
    },
    {
      title: " Idea Implementation Consultant",
      description:
        "Ready to take the leap from idea to execution? Our Idea Implementation Consultant service provides hands-on support and strategic guidance to help you navigate the complexities of turning your vision into reality. From establishing workflows to optimizing resources, we'll help you overcome obstacles and achieve your goals efficiently.",
    },
    {
      title: "Product Roadmap Consultant",
      description:
        "Building a successful product requires a clear roadmap that outlines key milestones, timelines, and strategies for growth. Our Product Roadmap Consultant service helps you develop a comprehensive plan that aligns with your business objectives and market dynamics. We'll work with you to prioritize features, allocate resources effectively, and adapt to evolving market trends.",
    },
    // Add more reasons as needed
  ];
  const [showModal, setShowModal] = useState(false);
  const [selectedReason, setSelectedReason] = useState({});

  // Function to toggle modal visibility
  const toggleModal = (reason) => {
    setSelectedReason(reason);
    setShowModal(!showModal);
  };

  return (
    <div>
      <div className="relative px-8 py-20 xl:px-20 2xl:mx-auto 2xl:container">
        <h1 className="hidden pb-10 text-6xl text-center text-orange-600 font-Poppins xl:block">
          Idea To Product Generation
        </h1>
        <h1 className="block text-5xl text-center font-bold leading-tight text-orange-800 xl:hidden lg:leading-10">
          Idea To Product Generation
        </h1>
      </div>
      <div className="relative mx-auto max-w-[90%] md:max-w-full mb-8 lg:mb-16">
        <div
          className="grid place-content-end rounded-[20px] bg-cover bg-center w-full h-[250px] md:h-[600px] relative"
          style={{ backgroundImage: "url('images/ideatoprod.jpg')" }}
        >
          <div className="absolute bottom-0 left-0 right-0 px-4 md:px-6 bg-slate-200/50 rounded-b-[20px]">
            <h2 className="text-4xl md:text-6xl font-extrabold text-blue-600 text-center font-Poppins">
              Transform Your Ideas into Products..!
            </h2>
            <p className="text-sm md:text-xl text-orange-600 md:line-clamp-2 hover:line-clamp-4 font-semibold text-center mt-2">
              At Altruisty, we specialize in guiding individuals like you
              through the process of transforming your innovative ideas into
              successful startups.
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="py-12 ">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
              <h2 className="text-base text-orange-600 font-semibold tracking-wide uppercase">
                We Deliver Better Products
              </h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-green-600 sm:text-4xl">
                A better way to deliver
              </p>
              <p className="mt-4 max-w-2xl text-xl text-black lg:mx-auto">
                What We do With Your Idea
              </p>
            </div>

            <div className="mt-10">
              <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
                {reasons.map((reason) => (
                  <div className="flex flex-col items-center p-4 border-2 border-slate-300 hover:border-black transition-all duration-300 transform backdrop-blur shadow-lg rounded-xl hover:-translate-y-2 hover:shadow-2xl">
                    <span className="text-xl">{reason.icon}</span>

                    <h3 className="mb-2 text-green-600 text-xl font-bold py-4">
                      {reason.title}
                    </h3>
                    <p className="text-black">{reason.description}</p>

                    <button
                      className="mt-4"
                      onClick={() => toggleModal(reason)}
                    >
                      <div className="relative inline-block text-sm group">
                        <span className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-blue-900 transition-colors duration-300 ease-out border-2 border-blue-900 rounded-lg group-hover:text-white">
                          <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                          <span className="absolute left-0 w-40 h-40 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-blue-900 group-hover:-rotate-180 ease"></span>
                          <span className="relative">Explore more</span>
                        </span>
                        <span
                          className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-blue-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                          data-rounded="rounded-lg"
                        ></span>
                      </div>
                    </button>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        handleClose={() => toggleModal(selectedReason)}
        reason={selectedReason}
      />
      <WhatsAppStickyButton />
      <Footer />
    </div>
  );
}
