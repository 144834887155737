import React, { useState } from "react";
import axios from "axios";

function Verify() {
  const [registernumber, setRegisterNumber] = useState("");
  const [internDetails, setInternDetails] = useState(null);
  const [formError, setFormError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleVerify = async () => {
    setIsLoading(true);
    setFormError("");
    setInternDetails(null);
    try {
      const origin = encodeURIComponent(window.location.origin);
      const response = await axios.get(`https://script.google.com/macros/s/AKfycbyWFs10gd9v3LnAoDYcktlfTY1iA5iGtMrP9DMMeHPKnTv_VXDX5zmnI7sFiSw-NRNH/exec?regNo=${registernumber}&origin=${origin}`);
      const { data } = response;
      console.log(data);

      if (data.status === "success" && data.data.length > 0) {
        setInternDetails(data.data[0]);
        setFormError("");
      } else {
        setInternDetails(null);
        setFormError("Intern not found.");
      }
    } catch (error) {
      setFormError("Failed to verify intern.");
    }

    setIsLoading(false);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-Darkblue p-6 rounded-lg shadow-lg">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-Yellow">
            Verify Your Internship
          </h2>
          <p className="mt-2 text-center text-sm text-White">
            Enter your Internship ID to view your projects.
          </p>
        </div>
        <form className="space-y-4">
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="internship-id" className="sr-only">
                Internship ID
              </label>
              <input
                id="internship-id"
                name="internship-id"
                type="text"
                autoComplete="internship-id"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Internship ID"
                value={registernumber}
                onChange={(e) => setRegisterNumber(e.target.value)}
              />
            </div>
          </div>

          {formError && (
            <p className="text-red-500 text-sm text-center">{formError}</p>
          )}

          <div>
            <button
              type="button"
              onClick={handleVerify}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-Darkblue bg-Yellow hover:bg-White focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-White"
            >
              {isLoading ? (
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
              ) : (
                "Verify"
              )}
            </button>
          </div>
        </form>

        {isLoading && (
          <div className="text-center text-gray-700 mt-4">
            Fetching details...
          </div>
        )}

        {internDetails && (
          <div>
            <h3 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
            Intern Details
            </h3>
            <ul className="mt-6 space-y-4">
            {Object.keys(internDetails).map((key, index) => (
                <li key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
                  <div className="p-4">
                    <h4 className="text-lg font-semibold text-gray-900 mb-2">
                      {key}: {internDetails[key]}
                    </h4>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Verify;
