
import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

export default function Corporateevent() {
  // Banner Slide
  const slide = [
    {
      title: "Conferences",
      description:
        "Transform your corporate events with our expert conference planning services, meticulously handling venue selection, agenda planning, speaker coordination, and attendee registration to create a seamless and productive experience. Our team brings a wealth of experience and attention to detail, ensuring every aspect of your event exceeds expectations and leaves a lasting impression on participants. Whether it's a large-scale conference or an intimate gathering, we tailor our services to suit your specific needs and objectives, delivering a successful event that enhances your brand's reputation and fosters valuable connections among attendees. Let us elevate your corporate events to new heights of professionalism and excellence. ",
      icon: "/images/Corporate_Slide1.jpg",
    },
    {
      title: "Meetings",
      description:
        "Enhance your official meetings with our professional planning services, covering venue selection, agenda creation, participant coordination, and logistics to ensure a smooth and effective gathering. Our team brings expertise in optimizing meeting formats, facilitating discussions, and managing timelines, guaranteeing a productive and focused environment. Whether it's a board meeting, strategic planning session, or executive summit, we customize our services to align with your objectives and corporate culture, fostering collaboration and decision-making. Trust us to elevate your official meetings, leaving a lasting impact and driving tangible results for your organization.",
      icon: "/images/Corporate_Slide6.jpg",
    },
    {
      title: "Product Launchs",
      description:
        "Elevate your product launch events with our comprehensive planning services, covering venue selection, event design, promotional strategies, and attendee engagement to create a memorable and impactful experience. Our team specializes in showcasing your product's unique features, coordinating media coverage, and maximizing audience interaction, ensuring a successful launch that generates excitement and buzz. Whether it's a new product unveiling, promotional campaign kickoff, or brand repositioning event, we tailor our services to showcase your product's value proposition and drive customer engagement. Let us take your product launch to the next level, amplifying your brand presence and driving business growth.",
      icon: "/images/Corporate_Slide7.jpg",
    },
    {
      title: "Team Events",
      description:
        "Whether it's a day of thrilling outdoor activities or a weekend retreat, our team building events are meticulously crafted to foster collaboration, communication, and teamwork among your employees. We curate engaging experiences that challenge participants to work together, build trust, and develop problem-solving skills in a fun and supportive environment. From adventure challenges to creative workshops and strategic games, our events are designed to inspire creativity and camaraderie. Our expert facilitators ensure that every activity contributes to building stronger bonds and enhancing workplace dynamics, leading to improved productivity and morale. Invest in your team's success by participating in our transformative team building experiences that leave a lasting impact.",
      icon: "/images/Corporate_Slide8.jpg",
    },
  ];
  //Individual events card

  const steps = [
    {
      title: "Conferences",
      description:
        "Elevate your corporate events with our expert conference planning services, ensuring a seamless and impressive experience for participants.",
      icon: "/images/Corporate_Slide2.jpg",
      link: "/inperson",
    },
    {
      title: "Meetings",
      description:
      "Elevate your official meetings with our professional planning services, ensuring a seamless and effective gathering for all participants.",
      icon: "/images/Corporate_Slide4.jpg",
      link: "/inperson",
    },
    {
      title:  "Product Launchs",
      description:
      "Transform your product launch events with our comprehensive planning services, ensuring a memorable and impactful experience that boosts brand presence and drives business growth.",
      icon: "/images/Corporate_Slide3.jpg",
      link: "/inperson",
    },
    {
      title: "Team Events",
      description:
      "Enhance team collaboration, communication, and productivity through our revitalized team-building events that foster an engaging and cohesive workplace environment.",
      icon: "/images/Corporate_Slide9.jpg",
      link: "/inperson",
    },
  ];

  // Data for the slider
  const data = [
    {
      src: "/images/Corporate_Slide3.jpg",
    },
    {
      src: "/images/Corporate_Slide1.jpg",
    },
    {
      src: "/images/Corporate_Slide5.jpg",
    },
    {
      src: "/images/Corporate_Slide7.jpg",
    },
  ];

  // Slider settings
  const settings = {
    className: "center",
    centerMode: true,
    dots: true,
    infinite: true,
    speed: 1000,
    centerPadding: "60px",
    cssEase: "linear",
    autoplaySpeed: 2500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 410,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <section className="bgcolor">
      <div className=" container px-2 py-20 mx-auto max-xl:flex flex-col">
        {/* Banner  */}

        <div className="w-full mx-8 my-8 max-sm flex flex-col justify-center">
          <section className="grid gap-4 w-full flex justify-center">
            <div
              id="servicesSlide"
              className="h-96 sm:w-[600px] md:w-[800px] lg:w-[1000px] w-96 lg:pt-10 mx-auto"
            >
              <Slider {...settings}>
                {slide.map((item, index) => (
                  <div
                    key={index}
                    className="text-gray-500 sm:text-lg :text-gray-400"
                  >
                    <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-center text-orange-600 ">
                      {item.title}
                    </h2>
                    <div className="flex flex-col sm:flex-row items-center justify-center">
                      <img
                        src={item.icon}
                        className="w-1/1 sm:w-96  mb-4 sm:mb-0 sm:mr-4"
                        alt={item.title}
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </section>

          

          <div className="flex justify-center sm:pt-43 pt-10 md:pt-0">
            <button
              className="w-full md:w-1/2 lg:w-1/4 p-3 justify-center flex  bg-indigo-600 rounded-lg text-slate-300 group-hover:mb-0 group-hover:mr-0 hover:bg-violet-500 hover:text-slate-950 active:bg-violet-700 transition-colors duration-1000 ease-out border-2 mt-0"
              onClick={() =>
                (window.location.href = "https://docs.google.com/forms/u/0/")
              }
            >
              Book Now!
            </button>
          </div>
        </div>
      </div>

      {/* Events  */}

      <section className="py-12 ">
        <div className="py-12 ">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center items-center">
              <h2 className="text-4xl text-orange-600 font-semibold tracking-wide uppercase">
                Events Organized
              </h2>
            </div>

            <div className="mt-10 backdrop-blur">
              <ul className="mx-auto w-full md:w-full md:grid md:grid-cols-2 lg:grid-cols-4 gap-10">
                {steps.map((step, index) => (
                  <li key={index} className="mt-10 md:mt-0">
                    <div className="  w-full h-full items-start p-4 border-2 border-inherit hover:border-black transition-all duration-300 transform bg-slate shadow-lg place-items-center rounded-xl hover:-translate-y-2 hover:shadow-2xl  ">
                      <img src={step.icon}  width={350}  alt="Card Images"
                        className="m-auto rounded-lg"/>

                      <h3 className="mb-2 text-green-600 text-xl font-bold py-4">
                        {step.title}
                      </h3>
                      <p className="text-white">{step.description}</p>
                      <Link to={step.link}></Link>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Carousel Section */}

      <section className="grid gap-4 w-full">
        <div className="h-96 sm:w-[600px] md:w-[800px] lg:w-[600px] w-96  gap-20 mx-auto">
          <Slider {...settings}>
            {data.map((item, index) => (
              <div key={index} className="w-full w-1/1 flex  ">
                <img
                  className="object-contain object-center w-full max-w-[450px]  md-h-30 rounded-xl"
                  src={item.src}
                  alt="blog"
                />
              </div>
            ))}
          </Slider>
        </div>
      </section>

      {/* Other Details  */}

      <section className="py-12 ">
        <div className="py-12 ">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center items-center">
              <h2 className="text-4xl text-orange-600 font-semibold tracking-wide uppercase">
                Other Details
              </h2>
            </div>

            <div className="mt-10 backdrop-blur">
              <ul className="mx-auto w-96 md:w-full md:grid md:grid-cols-2 lg:grid-cols-2 gap-10 ">
              {slide.map((step, index) => (
                  <li key={index} className="mt-10 md:mt-0">
                    <div className="  items-start p-4 border-2 border-inherit hover:border-black transition-all duration-300 transform bg-slate shadow-lg place-items-center rounded-xl hover:-translate-y-2 hover:shadow-2xl h-full">
                      <h3 className="mb-2 text-green-600 text-xl font-bold py-4">
                        {step.title}
                      </h3>
                      <p className="text-white">{step.description}</p>
                      <Link to={step.link}></Link>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Booking Button */}
      <div className="flex justify-center">
        <button
          className="w-full md:w-1/2 lg:w-1/4  p-3 justify-center flex ease-linear bg-indigo-600 rounded-lg text-slate-300 group-hover:mb-0 group-hover:mr-0 hover:bg-violet-500 hover:text-slate-950 active:bg-violet-700 transition-colors duration-1000 ease-out border-2"
          onClick={() =>
            (window.location.href = "https://docs.google.com/forms/u/0/")
          }
        >
          Book Now!
        </button>
      </div>
    </section>
  );
}
