import React from 'react';
import { Link } from 'react-router-dom';

const AppDevelopment = () => {
  return (
    <>
      <section className='homeweb bg-black bg-opacity-60 pt-28 p-8 font-times' id='homeweb'>
        <h1 className='text-2xl sm:text-2xl leading-tight  md:text-4xl lg:text-5xl font-bold mb-4'>Web Development</h1>
        <p className='text-sm sm:text-base md:text-lg lg:text-xl mb-4'>
          A software solution is typically custom-built or configured to solve a specific customer problem. It could include: Custom software development. Customized Assembly of multiple software products.
        </p>
        <Link to='/form'>
          <button className='butt'>
            Contact
          </button>
        </Link>
      </section>

      <section className='homecom bg-black bg-opacity-60 p-5 font-times' id='homecom'>
        <h1 className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4'>E-commerce Websites</h1>
        <p className='text-sm sm:text-base md:text-lg lg:text-xl mb-4'>
          We specialize in creating captivating e-commerce websites that drive sales and conversions. From user-friendly navigation to secure payment gateways, we design and develop e-commerce solutions that elevate your online business.
        </p>
      </section>

      <section className='homeport bg-black bg-opacity-60 p-5 font-times ' id='homeport'>
        <h1 className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4'>Portfolio Websites</h1>
        <p className='text-sm sm:text-base md:text-lg lg:text-xl mb-4'>
          Showcase your work and talent with a stunning portfolio website designed to impress. Our creative team crafts visually appealing and easy-to-navigate websites that effectively highlight your achievements and expertise.
        </p>
      </section>

      <section className='homecor bg-black bg-opacity-60 p-5 font-times ' id='homecor'>
        <h1 className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4'>Corporate Websites</h1>
        <p className='text-sm sm:text-base md:text-lg lg:text-xl mb-4'>
          Establish a strong online presence with a professional corporate website tailored to your brand identity. Our team creates sleek and informative websites that reflect your company's values and engage visitors effectively.
        </p>
      </section>

      <section className='homewebui bg-black bg-opacity-60 p-5 font-times' id='homewebui'>
        <h1 className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4'>Web Design (UI / UX)</h1>
        <p className='text-sm sm:text-base md:text-lg lg:text-xl mb-4'>
          We combine creativity with functionality to deliver exceptional web design solutions. Our UI/UX experts focus on creating visually stunning and intuitive interfaces that enhance user engagement and drive results.
        </p>
      </section>

      <section className='homemen bg-black bg-opacity-60 p-5 font-times' id='homemen'>
        <h1 className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4'>Website Maintenance and Support</h1>
        <p className='text-sm sm:text-base md:text-lg lg:text-xl mb-4'>
          Keep your website running smoothly with our comprehensive maintenance and support services. From regular updates and backups to troubleshooting and performance optimization, we ensure your website stays secure and up-to-date.
        </p>
      </section>

      <section className='homestack bg-black bg-opacity-60 p-5 font-times' id='homestack'>
        <h1 className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4'>Full-stack Websites</h1>
        <p className='text-sm sm:text-base md:text-lg lg:text-xl mb-4'>
          We offer end-to-end development solutions for full-stack websites, handling everything from frontend design to backend development and database integration. Our team ensures seamless functionality and exceptional user experiences across all aspects of your website.
        </p>
      </section>
    </>
  );
};

export default AppDevelopment;
