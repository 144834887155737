
import React from 'react';
import { Link } from 'react-router-dom';
import './others.css';
import img1 from '../Images/animation-logo.jpg'
const Mentorship = () => {
  return (
    <>
      <section className="home1 pt-20 text-center bg-cover bg-center text-white" style={{ backgroundImage: `url(${img1})` }} id="home1">
        <div className="container mx-auto px-4 md:px-8 p-8 pt-28">
          <h1 className="text-2xl md:text-4xl font-bold leading-tight mb-4">Empower Your Journey with Expert Mentorship</h1>
          <p className="text-base md:text-lg mb-6 max-w-3xl mx-auto">Mentorship services can provide valuable support for individuals and startups aiming to grow their skills, knowledge, and businesses.</p>
          <Link to="/form">
            <button className="butt">Contact Us</button>
          </Link>
        </div>
      </section>

      <section className="home3 bg-gray-100 text-center py-16 md:py-10" id="home3">
        <div className="container mx-auto px-4 md:px-8">
          <h1 className="text-2xl md:text-4xl font-bold mb-4">Career Mentorship</h1>
          <p className="text-base md:text-lg mb-6 max-w-3xl mx-auto">The mentor shares insights, knowledge, and advice based on their own experiences, while the mentee absorbs this wisdom to make informed decisions about their career path. Open communication, trust, and a commitment to the mentee's growth characterize the relationship.</p>
        </div>
      </section>

      <section className="home4 bg-white text-center py-16 md:py-10" id="home4">
        <div className="container mx-auto px-4 md:px-8">
          <h1 className="text-2xl md:text-4xl font-bold mb-4">Skill Development Mentorship</h1>
          <p className="text-base md:text-lg mb-6 max-w-3xl mx-auto">Mentorship helps students enhance their academic skills, such as critical thinking, problem-solving, and research abilities. Mentors can guide students in developing effective study techniques and improving their academic performance.</p>
        </div>
      </section>

      <section className="home5 bg-gray-100 text-center py-16 md:py-10" id="home5">
        <div className="container mx-auto px-4 md:px-8">
          <h1 className="text-2xl md:text-4xl font-bold mb-4">Entrepreneurship Mentorship</h1>
          <p className="text-base md:text-lg mb-6 max-w-3xl mx-auto">Starting a business is a daunting task, one that requires determination, creativity, and a willingness to take risks. But no entrepreneur has to do it alone.</p>
        </div>
      </section>

      <section className="home6 bg-white text-center py-16 md:py-10" id="home6">
        <div className="container mx-auto px-4 md:px-8">
          <h1 className="text-2xl md:text-4xl font-bold mb-4">Academic Mentorship</h1>
          <p className="text-base md:text-lg mb-6 max-w-3xl mx-auto">An academic mentor is a positive role model of a successful student who supports their mentee by giving academic advice, sharing resources, and caring about their students' success.</p>
        </div>
      </section>

      <section className="home7 bg-gray-100 text-center py-16 md:py-10" id="home7">
        <div className="container mx-auto px-4 md:px-8">
          <h1 className="text-2xl md:text-4xl font-bold mb-4">Leadership Mentorship</h1>
          <p className="text-base md:text-lg mb-6 max-w-3xl mx-auto">Mentoring someone to become a leader involves identifying their potential, providing them with challenging opportunities, offering feedback, and supporting their journey towards becoming an effective leader.</p>
        </div>
      </section>
    </>
  );
};

export default Mentorship;
