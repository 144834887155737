import React from 'react';
import { Link } from 'react-router-dom';
import './others.css';

const Incubation = () => {
  return (
    <>
      <section className='home2 font-times bg-black bg-opacity-60 pt-28 p-5 text-center' id='home2'>
        <h1 className='text-2xl sm:text-3xl md:text-4xl leading-tight lg:text-5xl font-bold mb-4'>Loans & Investments</h1>
        <p className='text-sm sm:text-base md:text-lg lg:text-xl mb-4'>
          Loans and investments are two distinct financial concepts that are essential for personal finance and business management. Here's an overview of each:
        </p>
        <Link to='/form'>
          <button className='butt'>
            Contact Us
          </button>
        </Link>
      </section>

      <section className='home9 font-times bg-black bg-opacity-60 p-5 text-center' id='home9'>
        <h1 className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4'>Funding and Financial Support</h1>
        <p className='text-sm sm:text-base md:text-lg lg:text-xl mb-4'>
          Financial support is important for employees because it helps alleviate financial stress and improves overall wellbeing. By providing assistance and resources to manage personal finances, employees can focus on their work, leading to increased productivity and job satisfaction.
        </p>
      </section>

      <section className='home8 font-times bg-black bg-opacity-60 p-5  text-center' id='home8'>
        <h1 className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4'>Mutual Funds</h1>
        <p className='text-sm sm:text-base md:text-lg lg:text-xl mb-4'>
          A mutual fund is a pool of money managed by a professional Fund Manager. It is a trust that collects money from a number of investors who share a common investment objective and invests the same in equities, bonds, money market instruments and/or other securities.
        </p>
      </section>

      <section className='home10 font-times bg-black bg-opacity-60 p-5  text-center' id='home10'>
        <h1 className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4'>Cryptocurrencies</h1>
        <p className='text-sm sm:text-base md:text-lg lg:text-xl mb-4'>
          A cryptocurrency is a digital currency, which is an alternative form of payment created using encryption algorithms. The use of encryption technologies means that cryptocurrencies function both as a currency and as a virtual accounting system.
        </p>
      </section>

      <section className='home11 font-times bg-black bg-opacity-60 p-5  text-center' id='home11'>
        <h1 className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4'>Stocks</h1>
        <p className='text-sm sm:text-base md:text-lg lg:text-xl mb-4'>
          A stock is a security that represents a fractional ownership in a company. When you buy a company's stock, you're purchasing a small piece of that company, called a share.
        </p>
      </section>
    </>
  );
};

export default Incubation;
