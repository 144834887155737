import React from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "aos/dist/aos.css"
import AOS from "aos"
import useEffect from "react"

// Import local images
import database1 from './aboutus-images/database1.jpg';
import db2 from './aboutus-images/db2.jpg'
import db3 from './aboutus-images/db3.jpg'
import field1 from './aboutus-images/field1.jpg'
import field4 from "./aboutus-images/field4.webp"
import domain1 from "./aboutus-images/domain1.jpg"
import domain2 from "./aboutus-images/domain2.png"
import domain3 from "./aboutus-images/domain3.jpg"
import blog1 from "./aboutus-images/blog1.jpg"
import blog2 from "./aboutus-images/blog2.jpeg"
import blog3 from "./aboutus-images/blog3.jpeg"
import team1 from "./aboutus-images/team1.jpg"
import team2 from "./aboutus-images/team2.png"
import team3 from "./aboutus-images/team3.jpg"
import custom1 from "./aboutus-images/custom1.png"
import custom2 from "./aboutus-images/custom2.jpg"


function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}


export default function Verttimeline() {

  const color="#F5CF6B";
  const data = [
    {
      title: 'Add your databases',
      desc: 'Use your own Notion databases or duplicate ours effortlessly, enabling you to manage and organize content efficiently while maintaining seamless integration with your blog platform.',
      images: [
        database1,
        db2,
        db3
      ]
    },
    {
      title: 'Map your fields',
      desc: 'Map your Notion fields with Feather fields effortlessly, ensuring smooth data transition and accurate representation of your content across platforms.',
      images: [
        field1,
        field4
      ]
    },
    {
      title: 'Choose your domain',
      desc: 'Choose a domain or a subdomain for your blog that reflects your brand or personal identity, ensuring easy access and recognition for your audience.',
      images: [
        domain1,
        domain2,
        domain3
      ]
    },
    {
      title: 'Get your blog',
      desc: "That's it. You will get back a professional blog with sleek designs, customizable templates, and seamless navigation.",
      images: [
        blog1,
        blog2,
        blog3
      ]
    },
    {
      title: 'Collaborate with team members', // New timeline item 1
      desc: 'Collaborate with your team members seamlessly using shared workspaces and collaborative tools, ensuring everyone stays aligned and productive.',
      images: [
        team1,
        team2,
        team3
      ]
    },
    {
      title: 'Customize your blog design', // New timeline item 2
      desc: 'Customize your blog\'s design with a variety of themes, fonts, and colors to reflect your unique style and branding, ensuring a visually appealing and professional look.',
      images: [
        custom1,
        custom2
      ]
    },
  ];

  // Settings for the Slick carousel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Slide every 3 seconds evenly
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SamplePrevArrow />,
    appendDots: dots => (
      <div 
        className="text-blue"
        style={{
          color:"red"
          // backgroundColor: "#ffffff",
          // borderRadius: "10px",
          // padding: "10px"
        }}
      >
        <ul style={{ margin: "0px",color:"blue" }}> {dots} </ul>
      </div>
    )
  };

  return (
    <div className="font-sans md:mt-10">
      <section className="grid gap-4 w-full py-10 bgcolor">
        <div className="bg-[#17212E] rounded-lg shadow-xl p-8 mb-8 w-full sticky z-5 font-bold">
          <h1 className="text-2xl md:text-3xl text-[#F5CF6B] text-center font-Poppins mb-0 mr-3">WHAT WE DISCOVERED</h1>
        </div>
        <br />
      <div data-aos-duration="800" data-aos="fade-right">
        <div className="flex flex-wrap justify-center ml-0 mr-0">
          {data.map((item, index) => (
            <div key={index} className="m-6 p-4 rounded-lg shadow-inner hover:scale-105 bg-[#17212E] text-white transition duration-300 ease-in-out transform hover:scale-103 w-full max-w-xs md:max-w-sm" style={{ height: 'auto' }}>
              <Slider {...settings}>
                {item.images.map((image, i) => (
                  <div key={i}>
                    <img src={image} alt={`Slide ${i}`} className="mx-auto w-full bg-clip-border h-48 object-contain " />
                  </div>
                ))}
              </Slider>
              <h2 className={`my-2 text-lg md:text-xl font-bold text-center py-2 text-[${color}] `}>{item.title}</h2>
              
                <p className="mb-4 text-base text-justify md:text-base ">{item.desc}</p>
              
            </div>
          ))}
        </div>
      </div>
      </section>
    </div>
  );
}
