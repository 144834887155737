import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import logo from "../pageimg/logo_design.png";

const Sidebar = ({ isMobileMenuOpen, handleMobileMenuToggle, mobileMenuRef }) => {
  const [isServiceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [isEventDropdownOpen, setEventDropdownOpen] = useState(false);

  return (
    <div
      className={`fixed top-0 right-0 h-full bg-white shadow-lg transform transition-transform ${
        isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'
      } w-64 p-4 z-50`}
      ref={mobileMenuRef}
    >
      <button
        className="text-2xl p-2 absolute top-2 right-2"
        onClick={handleMobileMenuToggle}
      >
        &times;
      </button>
      <div className="flex flex-col items-start space-y-4">
        <div className="flex items-center space-x-2">
          <img src={logo} alt="Altruisty Logo" className="h-8" />
          <h1 className="text-2xl font-bold">Altruisty</h1>
        </div>
        <div className="w-full">
          <input
            type="text"
            placeholder="Search..."
            className="w-full p-2 border rounded"
          />
        </div>
        <nav className="flex flex-col space-y-2">
          <Link to="/" className="text-lg" onClick={handleMobileMenuToggle}>
            Home
          </Link>
          <div>
            <div 
              className="flex justify-between items-center cursor-pointer" 
              onClick={() => setServiceDropdownOpen(!isServiceDropdownOpen)}
            >
              <span className="text-lg">Services</span>
              {isServiceDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {isServiceDropdownOpen && (
              <div className="pl-4 flex flex-col space-y-2 mt-2">
                <Link to="/Altruistyservices" onClick={handleMobileMenuToggle}>Altruisty Services</Link>
                <Link to="/training" onClick={handleMobileMenuToggle}>Training Program</Link>
                <Link to="/StartupLaunchpad" onClick={handleMobileMenuToggle}>Startup Services</Link>
              </div>
            )}
          </div>
          <div>
            <div 
              className="flex justify-between items-center cursor-pointer" 
              onClick={() => setEventDropdownOpen(!isEventDropdownOpen)}
            >
              <span className="text-lg">Events & Competition</span>
              {isEventDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {isEventDropdownOpen && (
              <div className="pl-4 flex flex-col space-y-2 mt-2">
                <Link to="/eventsandcompetition" onClick={handleMobileMenuToggle}>Events & Competition</Link>
                <Link to="/pastprogramlog" onClick={handleMobileMenuToggle}>Past Program Log</Link>
              </div>
            )}
          </div>
          <Link to="/internshipprogram" className="text-lg" onClick={handleMobileMenuToggle}>
            Internship
          </Link>
          <Link to="/StartupLaunchpad" className="text-lg" onClick={handleMobileMenuToggle}>
            Startup Launchpad
          </Link>
          <Link to="/Package" className="text-lg" onClick={handleMobileMenuToggle}>
            Package
          </Link>
          <Link to="/Aboutus" className="text-lg" onClick={handleMobileMenuToggle}>
            About Us
          </Link>
          <Link to="/Contactus" className="text-lg" onClick={handleMobileMenuToggle}>
            Contact Us
          </Link>
        </nav>
        <div className="mt-4">
          <div className="flex items-center space-x-2">
            <FaPhoneAlt />
            <span>+91 86678 58430</span>
          </div>
          <div className="flex items-center space-x-2 mt-2">
            <FaEnvelope />
            <span>balaji@bigbucksinnovation.com</span>
          </div>
          <div className="flex items-center space-x-2 mt-2">
            <FaMapMarkerAlt />
            <span>Neelankarai, Neelankarai</span>
          </div>
        </div>
        <button className="mt-4 bg-green-500 text-white py-2 px-4 rounded">
          Get a quote
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
