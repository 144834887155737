import React from "react";
import { Link } from "react-router-dom";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const data=[
    {
    index:0,
    img:"images/tapes.jpg",
    title:"Adaptive learning on digital platform",
    desc:"",
    link:""
   },
   {
    index:1,
    img:"images/instruments.jpg",
    title:"Graded Music Certificates",
    desc:"",
    link:""
   },
   {
    index:2,
    img:"images/teaching.jpg",
    title:"Live 1:1 music for Kids and Adults",
    desc:"",
    link:""
   }
]
const settings = {
    className: 'center',
    centerMode: true,
    dots: true,
    infinite: true,
    speed: 1000,
    centerPadding: '60px',
    cssEase: 'linear',
    autoplaySpeed: 2500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
        }
      },
    ],
  };

export default function MusicalSlider(){
    return (
        <div className=" md:w-full w-full ">

        <div>
           
            <div className="relative px-8 py-20 xl:px-20 2xl:mx-auto 2xl:container">
            
                    <h1 className="hidden pb-10 text-6xl text-center text-orange-600 font-Poppins xl:block">
                        Musical Hub
                    </h1>
                    <h1 className="block text-5xl text-center font-bold leading-tight text-orange-600 xl:hidden lg:leading-10">Musical Hub</h1>
                    <Slider {...settings}>
                    {data.map(d=>(
                   
                            <div className="flex">
                                <div className="mx-14 mt-14 md:flex">
                                    <div className="relative lg:w-1/2 sm:w-96 xl:h-96 ">
                                        <div className="rounded-xl">
                                        <img src={d.img} alt="" className="  w-[500px] mx-auto shadow-lg rounded-[20px] " />
                                        </div>
                                  
                                    </div>
                                    <div className="flex flex-col justify-between  ">
                                        <div>
                                            <h1 className="text-xl md:text-4xl text-center p-10 font-semibold text-green-600 xl:leading-loose">{d.title}</h1>
                                         </div>
                                        
                                        <Link  to={d.link}>                       
                  <button className=" mx-10 md:mx-20  mb-2">
      <a href="/" className="relative inline-block text-sm group">
       <span className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-blue-900 transition-colors duration-300 ease-out border-2 border-blue-900 rounded-lg group-hover:text-white">
        <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
       <span className="absolute left-0 w-40 h-40 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-blue-900 group-hover:-rotate-180 ease"></span>
      <span className="relative">Explore more</span>
      </span>
      <span className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-blue-900 rounded-lg group-hover:mb-0 group-hover:mr-0" data-rounded="rounded-lg"></span>
           </a>
           </button>
                     </Link>  
                                    </div>
                                </div>
                            </div>  
                        ))}
                        
                    </Slider>

            </div>
        </div>    
    </div>
    );
}