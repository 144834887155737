
import React from "react";
// import Service1 from "../chunkpages/Service1page";
// import WhyChooseUs from "../unwanteds/Service2page";
// import OurProcess from "../chunkpages/OurProcess";
// import WhatsAppStickyButton from "../chunkpages/WhatsappStickyButton";
import ServiceBanner from "./Services/ServiceBanner";
import ParallaxEffect from "./Services/ParallaxEffect";
import ServiceAbout from "./Services/ServiceAbout";
import OurServices from "./Services/OurServices";
import Headlineservice from "./Services/Headlineservice";
import Headlinestartups from "./Services/Headlinestartups";
import Footer from "../UIcomp/Footer";
import { Link } from 'react-router-dom';
import StepsNavigation from "../SubPagesCompos/StepsNavigation";

import Getservice from "./Services/Getservice";
import TrainingPrograms from "./Services/TrainingPrograms";

export default function Services() {
  return (
    <>
      <ServiceBanner />
      
      <Headlineservice />
      <ParallaxEffect />
      <OurServices />
      
<Getservice/>

    
   <Headlinestartups/>
      <StepsNavigation />
      <TrainingPrograms/>
      <ServiceAbout />
      <Footer />
    </>
  );
}
