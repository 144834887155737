
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { FaLaptopCode, FaMobileAlt, FaPenFancy, FaChartLine, FaGraduationCap } from 'react-icons/fa';

const HeadlinesSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
  }, []);

  return (
    <div className="container mx-auto p-4">
      {/* Header Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 pt-8">
        <div className="text-center pt-2 lg:pt-20 mb-8">
          <h1 className="text-2xl sm:text-4xl font-bold text-[#17212E] font-times mb-2">
            WHAT WE DO <span className="text-gray-600">as</span> EXPERTS
          </h1>
          <p className="text-gray-600">We build digital solutions.</p>
        </div>


        {/* Icon and Title Section */}
        <Link to="/webdevelopment" className="no-underline">
          {/* Card 1 */}
          <div
            data-aos="fade-up"
            className="bg-gray-100 rounded-lg overflow-hidden shadow-lg transform transition-transform duration-500 ease-in-out hover:scale-105 hover:bg-white hover:text-blue-500 group"
          >
            <div className="flex flex-col items-center p-6 sm:p-10 md:p-16">
              <FaLaptopCode className="text-3xl sm:text-4xl text-blue-500 group-hover:text-blue-500 mb-4 transition-colors duration-300" />
              <h2 className="text-l sm:text-xl text-gray-500 group-hover:text-[#F5CF6B] font-bold mb-2 transition-colors duration-300">
                WEB DEVELOPMENT
              </h2>
              <p className="text-sm sm:text-base text-gray-500 text-center group-hover:text-[#17212E] transition-colors duration-300">
                Creating responsive and engaging websites that meet your business needs.
              </p>
            </div>
          </div>
        </Link>

        <Link to="/appdevelopment" className="no-underline">
          {/* Card 2 */}
          <div
            data-aos="fade-up"
            data-aos-delay="200"
            className="bg-gray-100 rounded-lg overflow-hidden shadow-lg transform transition-transform duration-500 ease-in-out hover:scale-105 hover:bg-white hover:text-blue-500 group"
          >
            <div className="flex flex-col items-center p-6 sm:p-10 md:p-16">
              <FaMobileAlt className="text-3xl sm:text-4xl text-blue-500 group-hover:text-blue-500 mb-4 transition-colors duration-300" />
              <h2 className="text-l sm:text-xl text-gray-500 group-hover:text-[#F5CF6B] font-bold mb-2 transition-colors duration-300">
                APP DEVELOPMENT
              </h2>
              <p className="text-sm sm:text-base text-gray-500 text-center group-hover:text-[#17212E] transition-colors duration-300">
                Building user-friendly mobile applications for both Android and iOS platforms.
              </p>
            </div>
          </div>
        </Link>

        <Link to="/graphicdesigning" className="no-underline">
          {/* Card 3 */}
          <div
            data-aos="fade-up"
            data-aos-delay="400"
            className="bg-gray-100 rounded-lg overflow-hidden shadow-lg transform transition-transform duration-500 ease-in-out hover:scale-105 hover:bg-white hover:text-blue-500 group"
          >
            <div className="flex flex-col items-center p-6 sm:p-10 md:p-16">
              <FaPenFancy className="text-3xl sm:text-4xl text-blue-500 group-hover:text-blue-500 mb-4 transition-colors duration-300" />
              <h2 className="text-l sm:text-xl text-gray-500 group-hover:text-[#F5CF6B] font-bold mb-2 transition-colors duration-300">
                GRAPHIC DESIGN
              </h2>
              <p className="text-sm sm:text-base text-gray-500 text-center group-hover:text-[#17212E] transition-colors duration-300">
                Designing visually appealing graphics and branding materials to make your brand stand out.
              </p>
            </div>
          </div>
        </Link>

        <Link to="/digitalmarketing" className="no-underline">
          {/* Card 4 */}
          <div
            data-aos="fade-up"
            data-aos-delay="600"
            className="bg-gray-100 rounded-lg overflow-hidden shadow-lg transform transition-transform duration-500 ease-in-out hover:scale-105 hover:bg-white hover:text-blue-500 group"
          >
            <div className="flex flex-col items-center p-6 sm:p-10 md:p-16">
              <FaChartLine className="text-3xl sm:text-4xl text-blue-500 group-hover:text-blue-500 mb-4 transition-colors duration-300" />
              <h2 className="text-l sm:text-xl text-gray-500 group-hover:text-[#F5CF6B] font-bold mb-2 transition-colors duration-300">
                DIGITAL MARKETING
              </h2>
              <p className="text-sm sm:text-base text-gray-500 text-center group-hover:text-[#17212E] transition-colors duration-300">
                Implementing effective strategies to enhance your online presence and engagement.
              </p>
            </div>
          </div>
        </Link>

        <Link to="/training" className="no-underline">
          {/* Card 5 */}
          <div
            data-aos="fade-up"
            data-aos-delay="800"
            className="bg-gray-100 rounded-lg overflow-hidden shadow-lg transform transition-transform duration-500 ease-in-out hover:scale-105 hover:bg-white hover:text-blue-500 group"
          >
            <div className="flex flex-col items-center p-6 sm:p-10 md:p-16">
              <FaGraduationCap className="text-3xl sm:text-4xl text-blue-500 group-hover:text-blue-500 mb-4 transition-colors duration-300" />
              <h2 className="text-l sm:text-xl text-gray-500 group-hover:text-[#F5CF6B] font-bold mb-2 transition-colors duration-300">
                TRAINING & INTERNSHIPS
              </h2>
              <p className="text-sm sm:text-base text-gray-500 text-center group-hover:text-[#17212E] transition-colors duration-300">
                Providing comprehensive training programs and internships to help individuals develop their skills.
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default HeadlinesSection;
