import React from 'react';

const MapPage = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="mapouter">
        <div className="gmap_canvas">
          <iframe
            title="Google Map"
            width="90%" // Adjust width as needed, e.g., 90% of the container width
            height="6000" // Adjust height as needed
            id="gmap_canvas"
            className="w-full h-full"
            src="https://maps.google.com/maps?q=16%2C+12%2C+2nd+St+Rd%2C+Urrur+Kuppam%2C+Besant+Nagar%2C+Chennai%2C+Tamil+Nadu+600090&t=&z=13&ie=UTF8&iwloc=&output=embed"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default MapPage;


