
const ServiceAbout = () => {
	return (
		<div className="bg-white m-5 rounded-xl flex gap-28 justify-center lg:p-24 font-times">
			<img
				style={{ marginTop: "90px" }}
				alt=""
				src="/ServiceImages/img3.jpg"
				className="h-[300px] rounded-lg object-cover hidden lg:block"
			/>
			<div className="flex flex-col items-center w-full p-6 lg:p-0 lg:px-6 md:w-[50%]">
				<p className="bg-white text-[#17212E] text-3xl font-bold text-center">
					Why Choose Us
				</p>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					fill="currentColor"
					class="w-10 h-10"
				>
					<path
						fill-rule="evenodd"
						d="M4.25 12a.75.75 0 0 1 .75-.75h14a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.75-.75Z"
						clip-rule="evenodd"
					/>
				</svg>
				<div className="text-left text-center text-gray-800 text-base lg:text-lg space-y-4">
          <p className="lg:block hidden">
            At Altruisty, we excel in turning your app ideas into reality with precision and creativity. Our team specializes in crafting user-friendly mobile applications that are tailored to your specific needs, ensuring seamless functionality and engaging user experiences. Leveraging the latest technologies and best practices, we deliver custom solutions with a focus on intuitive design, innovation, and performance. From initial concept through to development, deployment, and ongoing support, our end-to-end service is backed by a skilled team committed to timely delivery and exceptional results. Discover why Altruisty is your ideal partner for transforming your app vision into a successful reality.
          </p>
          <p className="block lg:hidden">
            At Altruisty, we excel in turning your app ideas into reality with precision and creativity. Our team specializes in crafting user-friendly mobile applications that are tailored to your specific needs.
          </p>
          <p className="lg:block hidden">
            Discover why we're the best choice for your next project.
          </p>
        </div>
			</div>
		</div>
	);
};

export default ServiceAbout;
