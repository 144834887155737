import React from "react";

export default function WhatsAppStickyButton() {

  const openWhatsApp = () => {
    window.open("https://wa.me/+918667839838/", "_blank");
  };

  return (
    <div className="fixed bottom-4 right-4 z-10 ">
      <button
        className="flex items-center justify-center w-12 h-12 bg-green-500 rounded-full text-white shadow-lg hover:bg-green-600 focus:outline-none"
        onClick={openWhatsApp}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            d="M10.946 3.134c-4.798 0-8.69 3.878-8.69 8.654a8.44 8.44 0 0 0 1.062 4.152l-1.304 4.64a.629.629 0 0 0 .769.776l4.712-1.26a8.467 8.467 0 0 0 4.14 1.078c4.8 0 8.69-3.878 8.69-8.654S15.746 3.134 10.946 3.134zm2.875 11.345c-.124.195-.719 1.082-1.133 1.39-.062.043-.135.096-.197.133-.187.124-.404.217-.62.286a.82.82 0 0 1-.368.012c-.093-.025-.217-.062-.31-.087a.619.619 0 0 1-.202-.099c-.426-.274-.895-.561-1.333-.843a7.396 7.396 0 0 1-2.176-1.642c-.268-.295-.527-.627-.72-1.002-.205-.427-.33-.925-.109-1.374a1.425 1.425 0 0 1 1.045-.777 9.964 9.964 0 0 1 1.853-.223c.38-.012.761-.012 1.145-.012.19 0 .404 0 .594.036.73.093 1.448.347 2.042.767.109.087.217.186.31.285.093.124.182.235.271.372.087.148.173.297.235.46.062.18.135.372.099.576-.036.202-.173.472-.298.674z"
          ></path>
        </svg>
      </button>
    </div>
  );
};

