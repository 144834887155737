import img from "./mv.jpg";
const Startupvision = () => {


	return (
		<div className="bg-white flex justify-center lg:p-24 font-times">
			<img
			style={{margin:"0px"}}
				alt=""
				src={img}
				className="h-[300px] rounded-lg object-cover hidden lg:block"
			/>
			<div className="flex flex-col items-center w-full p-6 lg:p-0 lg:px-6 md:w-[50%]">
				<p className="bg-white text-[#17212E] text-3xl font-bold text-center">
				Vision and Mission
				</p>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					fill="currentColor"
					class="w-10 h-10"
				>
					<path
						fill-rule="evenodd"
						d="M4.25 12a.75.75 0 0 1 .75-.75h14a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.75-.75Z"
						clip-rule="evenodd"
					/>
				</svg>
				<div className="text-center text-gray-500 space-y-4">
					<p>
					At Altruisty, we are dedicated to empowering businesses and entrepreneurs by transforming their app ideas into innovative realities. Our vision is to lead the mobile app development industry through cutting-edge solutions that anticipate and exceed client expectations, delivering seamless functionality and exceptional user experiences globally.
					</p>
					
				</div>
			</div>
		</div>
	);
};

export default Startupvision;
