import React, { useState } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


export default function Achievements() {
    const [videoPlaying, setVideoPlaying] = useState(true); // State to control video playback

    const settings = {
        className: 'center',
        centerMode: true,
        dots: true,
        infinite: true,
        speed: 1000,
        centerPadding: '60px',
        cssEase: 'linear',
        autoplaySpeed: 2500,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerMode: false,
                }
            },
        ],
    };

    return (
        <section className="mb-10">
            <div className="bg-[#17212E] rounded-lg shadow-xl p-8 mb-8 w-full sticky z-5">
          <h1 className="text-2xl md:text-3xl text-[#F5CF6B] text-center font-Poppins mb-0 mr-3 font-bold">ACHIEVEMENTS</h1>
        </div>
            <br />
            <div className="grid gap-4">
                <div>
                    {videoPlaying && (
                        <iframe
                            width="100%"
                            height="100%"
                            title="team"
                            src="images/aboutus.mp4"
                            className="w-full rounded-lg aspect-video"
                            style={{ backgroundColor: 'white' }}
                            allow="fullscreen; encrypted-media; picture-in-picture"
                            alt=""
                        />
                    )}
                </div>
                </div>
        </section>
    )
}