import React from 'react';
import { FaBusinessTime, FaBullseye } from 'react-icons/fa';

function Headlineservice() {
  return (
    <div className='px-4 py-8 md:px-24 font-times'>
      <div className="p-8 rounded-lg">
        <div className="grid grid-cols-1 gap-8 mt-8 mx-0 ">
          <div className="flex flex-col md:flex-row items-center bg-white rounded-lg shadow-lg p-6 md:p-6 lg:p-8 space-y-4 md:space-y-0 md:space-x-6 hover:bg-gray-100 transition duration-300 w-full md:w-auto">
            <div className="bg-[#17212E] rounded-full p-4 flex-shrink-0">
              <FaBusinessTime className="text-[#F5CF6B] w-8 h-8" />
            </div>
            <div className="text-center md:text-left w-full">
              <p className="text-xl md:text-2xl text-[#17212E] font-bold mb-2">Transformative Solutions for Your Business</p>
              <p className="text-gray-800 text-justify md:text-lg">
                Unlock your business's potential with our transformative solutions. We offer a comprehensive suite of services designed to revolutionize your operations, enhance efficiency, and drive growth. Our innovative approaches and cutting-edge technologies are tailored to meet your unique challenges, ensuring you stay competitive in a rapidly changing market.
              </p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center bg-white rounded-lg shadow-lg p-6 md:p-6 lg:p-8 space-y-4 md:space-y-0 md:space-x-6 hover:bg-gray-100 transition duration-300 w-full md:w-auto">
            <div className="bg-[#17212E] rounded-full p-4 flex-shrink-0">
              <FaBullseye className="text-[#F5CF6B] w-8 h-8" />
            </div>
            <div className="text-center md:text-left w-full">
              <h2 className="text-xl md:text-2xl text-[#17212E] font-bold mb-2">Solutions Designed for Your Needs</h2>
              <p className="text-gray-800 text-justify md:text-lg">
                At our core, we believe that one size does not fit all. Our solutions are meticulously crafted to address the specific needs and goals of your business. Whether you're looking to streamline processes, improve customer engagement, or boost productivity, our team of experts will work closely with you to develop customized strategies that deliver measurable results.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Headlineservice;
