

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const OurServices = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
  }, []);

  const data = [
    {
      img: 'images/apdevelopment.jpg',
      title: 'App Development',
      link: '/appdevelopment',
      hoverContent: [
        'Crafting user-friendly mobile applications tailored to your needs, ensuring seamless functionality and engaging user experiences.',
      ],
    },
    {
      img: 'images/wb.jpg',
      title: 'Web Development',
      link: '/webdevelopment',
      hoverContent: [
        'Building responsive, dynamic websites with cutting-edge technologies, ensuring optimal performance and user engagement.',
      ],
    },
    {
      img: 'images/dm.jpg',
      title: 'Digital Marketing',
      link: '/digitalmarketing',
      hoverContent: [
        'Driving brand visibility and customer engagement through strategic online campaigns and targeted marketing strategies.',
      ],
    },
    {
      img: 'images/gd.png',
      title: 'Graphic Designing',
      link: '/graphicdesigning',
      hoverContent: [
        'Creating visually stunning graphics and illustrations that convey your brand\'s message effectively and leave a lasting impression.',
      ],
    },
    {
      img: 'images/training.jpg',
      title: 'Training Program',
      link: '/training',
      hoverContent: [
        'Offering comprehensive training programs in IT and related services, equipping participants with practical skills and industry insights.',
      ],
    },
    {
      img: 'images/internship.jpg',
      title: 'Internship Program',
      link: '/internshipprogram',
      hoverContent: [
        'Providing hands-on internship experiences that help you apply theoretical knowledge in real-world scenarios and gain practical insights.',
      ],
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className="py-4 bg-gray-100 px-4 sm:px-8 lg:px-16">
      <div className="py-8 md:py-12 bg-gray-100 mx-6">
        <h1 className="text-2xl md:text-5xl text-[#17212E] font-bold text-center py-1 font-times mb-10 mt-10 border-b-2 shadow-lg">
          List of Services
        </h1>
      </div>
      <Slider {...settings}>
        {data.map((item, index) => (
          <div key={index} className="px-4">
            <div className="relative rounded-lg overflow-hidden transition duration-300 ease-in-out transform hover:scale-105" data-aos="fade-up">
              <img className="object-cover w-full h-64" src={item.img} alt={item.title} />
              <div className="absolute inset-0 bg-gray-900 bg-opacity-90 text-white opacity-0 hover:opacity-100 transition-opacity duration-300 ease-in-out flex flex-col items-center justify-center p-6">
                <ul className="text-center space-y-2">
                  {item.hoverContent.map((content, i) => (
                    <li key={i}>{content}</li>
                  ))}
                </ul>
                <div className="mt-4 text-center">
                  <Link to={item.link}>
                    <button className="px-4 py-2 bg-[#17212E] text-[#F5CF6B] rounded-lg hover:bg-[#F5CF6B] hover:text-black transition duration-300">
                      More →
                    </button>
                  </Link>
                </div>
              </div>
              <div className="p-6 bg-white">
                <h2 className="text-xl font-bold text-[#17212E] mb-2 text-center">{item.title}</h2>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={`${className} slick-arrow slick-next`} style={{ ...style, display: 'block' }} onClick={onClick}>

    </div>
  );
};

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={`${className} slick-arrow slick-prev`} style={{ ...style, display: 'block' }} onClick={onClick}>
     
    </div>
  );
};

export default OurServices;
