
import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

export default function Symposiumevents() {
  // Banner Slide
  const slide = [
    {
      title: "Technical Symposiums",
      description:
        "Our educational symposia offer a platform for knowledge sharing and discourse through panels, keynotes, and workshops. Esteemed experts provide deep insights and practical solutions to pressing issues. Keynote presentations inspire innovative thinking among attendees. Interactive workshops foster hands-on learning and skill development. We facilitate robust discussions on emerging trends and critical topics. Collaboration and networking are encouraged among diverse stakeholders. Join us to exchange ideas, gain insights, and drive innovation forward. Experience the power of meaningful dialogue at our symposia.",
      icon: "/images/Symposium_Slide2.jpg",
    },
    {
      title: "Non Technical Symposiums",
      description:
        "Our curated events offer a platform for meaningful engagement and discussion through panels, talks, and interactive sessions. Esteemed speakers share insights and perspectives on relevant topics. Keynote presentations inspire attendees to think creatively and explore new ideas. Engage in hands-on activities and networking opportunities to build connections. We facilitate dynamic conversations on current trends and pressing issues. Collaboration and interaction among participants are encouraged to foster a vibrant atmosphere. Join us for an enriching experience filled with learning, inspiration, and valuable connections. Experience the power of meaningful dialogue at our non-technical events.",
      icon: "/images/Symposium_Slide6.jpg",
    },
    {
      title: "Hackathons",
      description:
        "Our hackathons provide a dynamic platform for collaboration and innovation through coding challenges, brainstorming sessions, and mentorship opportunities. Participants work alongside experts to develop creative solutions to real-world problems. Keynote speakers inspire teams with insights and industry perspectives. Engage in hands-on coding and problem-solving activities to sharpen skills. We foster a culture of creativity, teamwork, and rapid prototyping. Collaborate with peers, receive feedback, and iterate on ideas to create impactful solutions. Join us for an immersive experience where ideas come to life and innovation thrives. Experience the excitement of coding and collaboration at our hackathons.",
      icon: "/images/Symposium_Slide8.jpg",
    },
    {
      title: "Culturls Events",
      description:
        "Embark on a journey of cultural exploration at our vibrant festivals, showcasing music, dance, art, and culinary delights. Experience the richness and diversity of cultures from around the globe. Enjoy captivating performances that celebrate the beauty of traditions and heritage. Immerse yourself in art exhibitions featuring diverse artistic expressions and styles. Indulge in culinary experiences that tantalize your taste buds with flavors from different regions. Our events create a tapestry of cultural harmony, fostering appreciation and understanding. Join us in celebrating the mosaic of humanity through immersive and enriching experiences. Experience the world's cultural richness at our festivals.",
      icon: "/images/Symposium_Events_Icon.png",
    },
  ];
  //Individual events card

  const steps = [
    {
      title:"Technical Symposiums",
      description:
        "Our educational symposia inspire knowledge sharing, innovation, and collaboration among thought leaders, industry experts, and participants, driving progress and sparking creativity.",
      icon: "/images/Symposium_Slide1.jpg",
      link: "/inperson",
    },
    {
      title: "Non Technical Symposiums",
      description:
      "Join us for an enriching experience of meaningful engagement, dynamic discussions, and valuable connections at our curated events.",
      icon: "images/Symposium_Slide4.jpg",
      link: "/inperson",
    },
    {
      title: "Hackathons",
      description:
      "Engage in collaborative innovation and rapid prototyping at our hackathons, fostering creativity and real-world problem-solving through coding challenges and mentorship opportunities.",
      icon: "/images/Symposium_Slide7.jpg",
      link: "/inperson",
    },
    {
      title: "Culturls Events",
      description:
      "Immerse yourself in a celebration of global cultures at our vibrant festivals, fostering appreciation and understanding through music, dance, art, and culinary delights.",
      icon: "/images/Symposium_Slide11.jpg",
      link: "/inperson",
    },
  ];

  // Data for the slider
  const data = [
    {
      src: "/images/Symposium_Slide2.jpg",
    },
    {
      src: "/images/Symposium_Slide5.jpg",
    },
    {
      src: "/images/Symposium_Slide9.jpg",
    },
    {
      src: "/images/Symposium_Slide12.jpg",
    },
  ];

  // Slider settings
  const settings = {
    className: "center",
    centerMode: true,
    dots: true,
    infinite: true,
    speed: 1000,
    centerPadding: "60px",
    cssEase: "linear",
    autoplaySpeed: 2500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 410,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <section className="bgcolor">
      <div className=" container px-2 py-20 mx-auto max-xl:flex flex-col">
        {/* Banner  */}

        <div className="w-full mx-8 my-8 max-sm flex flex-col justify-center">
          <section className="grid gap-4 w-full flex justify-center">
            <div
              id="servicesSlide"
              className="h-96 sm:w-[600px] md:w-[800px] lg:w-[1000px] w-96 lg:pt-10 mx-auto"
            >
              <Slider {...settings}>
                {slide.map((item, index) => (
                  <div
                    key={index}
                    className="text-gray-500 sm:text-lg :text-gray-400"
                  >
                    <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-center text-orange-600 ">
                      {item.title}
                    </h2>
                    <div className="flex flex-col sm:flex-row items-center justify-center">
                      <img
                        src={item.icon}
                        className="w-1/1 sm:w-96  mb-4 sm:mb-0 sm:mr-4"
                        alt={item.title}
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </section>

          

          <div className="flex justify-center sm:pt-43 pt-10 md:pt-0">
            <button
              className="w-full md:w-1/2 lg:w-1/4 p-3 justify-center flex  bg-indigo-600 rounded-lg text-slate-300 group-hover:mb-0 group-hover:mr-0 hover:bg-violet-500 hover:text-slate-950 active:bg-violet-700 transition-colors duration-1000 ease-out border-2 mt-0"
              onClick={() =>
                (window.location.href = "https://docs.google.com/forms/u/0/")
              }
            >
              Book Now!
            </button>
          </div>
        </div>
      </div>

      {/* Events  */}

      <section className="py-12 ">
        <div className="py-12 ">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center items-center">
              <h2 className="text-4xl text-orange-600 font-semibold tracking-wide uppercase">
                Events Organized
              </h2>
            </div>

            <div className="mt-10 backdrop-blur">
              <ul className="mx-auto w-full md:w-full md:grid md:grid-cols-2 lg:grid-cols-4 gap-10">
                {steps.map((step, index) => (
                  <li key={index} className="mt-10 md:mt-0">
                    <div className="  w-full h-full items-start p-4 border-2 border-inherit hover:border-black transition-all duration-300 transform bg-slate shadow-lg place-items-center rounded-xl hover:-translate-y-2 hover:shadow-2xl  ">
                      <img src={step.icon}  width={350}  alt="Card Images"
                        className="m-auto rounded-lg"/>

                      <h3 className="mb-2 text-green-600 text-xl font-bold py-4">
                        {step.title}
                      </h3>
                      <p className="text-white">{step.description}</p>
                      <Link to={step.link}></Link>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Carousel Section */}

      <section className="grid gap-4 w-full">
        <div className="h-96 sm:w-[600px] md:w-[800px] lg:w-[600px] w-96  gap-20 mx-auto">
          <Slider {...settings}>
            {data.map((item, index) => (
              <div key={index} className="w-full w-1/1 flex  ">
                <img
                  className="object-contain object-center w-full max-w-[450px]  md-h-30 rounded-xl"
                  src={item.src}
                  alt="blog"
                />
              </div>
            ))}
          </Slider>
        </div>
      </section>

      {/* Other Details  */}

      <section className="py-12 ">
        <div className="py-12 ">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center items-center">
              <h2 className="text-4xl text-orange-600 font-semibold tracking-wide uppercase">
                Other Details
              </h2>
            </div>

            <div className="mt-10 backdrop-blur">
              <ul className="mx-auto w-96 md:w-full md:grid md:grid-cols-2 lg:grid-cols-2 gap-10 ">
              {slide.map((step, index) => (
                  <li key={index} className="mt-10 md:mt-0">
                    <div className="  items-start p-4 border-2 border-inherit hover:border-black transition-all duration-300 transform bg-slate shadow-lg place-items-center rounded-xl hover:-translate-y-2 hover:shadow-2xl h-full">
                      <h3 className="mb-2 text-green-600 text-xl font-bold py-4">
                        {step.title}
                      </h3>
                      <p className="text-white">{step.description}</p>
                      <Link to={step.link}></Link>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Booking Button */}
      <div className="flex justify-center">
        <button
          className="w-full md:w-1/2 lg:w-1/4  p-3 justify-center flex ease-linear bg-indigo-600 rounded-lg text-slate-300 group-hover:mb-0 group-hover:mr-0 hover:bg-violet-500 hover:text-slate-950 active:bg-violet-700 transition-colors duration-1000 ease-out border-2"
          onClick={() =>
            (window.location.href = "https://docs.google.com/forms/u/0/")
          }
        >
          Book Now!
        </button>
      </div>
    </section>
  );
}
