import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import verify from '../SubPagesCompos/Verify';
import Signin from './Signin'
const InternshipSection = () => {
  const [showLoginOptions, setShowLoginOptions] = useState(false);

  return (
    <div className=" w-full mx-auto p-8 bg-gray-200 rounded-lg shadow-xl">
      <h2 className="text-4xl font-extrabold text-center text-Darkblue mb-5">Advance Your Career With Us</h2>
      <p className="text-center text-xl text-Darkblue mb-12">Discover the opportunities we offer to help you kickstart your career. Select an option below to proceed.</p>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-12" data-aos="fade-in">
        {/* Apply Section */}
        <div className="relative group bg-Darkblue p-8 rounded-lg shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl">
          <div className="absolute inset-0 opacity-0 group-hover:opacity-70 transition duration-500 rounded-lg"></div>
          <h3 className="relative text-3xl font-semibold text-center text-Yellow mb-4 z-10">Apply</h3>
          <p className="relative text-center text-White mb-6 z-10 text-justify">Submit your application for our internship programs and take the first step in your career.</p>
          <Link to="/form" className="relative bg-Yellow text-Darkblue px-6 py-3 rounded-full w-full text-center hover:bg-White hover:text-Darkblue focus:outline-none focus:ring-4 focus:ring-gray-400 z-10 block">
            Apply Now
          </Link>
        </div>

        {/* Login Section */}
        <div className="relative group bg-Darkblue p-8 rounded-lg shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl">
          <div className="absolute inset-0  opacity-0 group-hover:opacity-70 transition duration-500 rounded-lg"></div>
          <h3 className="relative text-3xl font-semibold text-center text-Yellow mb-4 z-10">Login</h3>
          <p className="relative text-center text-White mb-6 z-10 text-justify">Access your internship  to in order to manage your applications and track your progress.</p>
          <button
            className="relative bg-Yellow text-Darkblue px-6 py-3 rounded-full w-full hover:bg-White hover:text-Darkblue focus:outline-none focus:ring-4 focus:ring-gray-400 z-10 block"
            onClick={() => setShowLoginOptions(!showLoginOptions)}
          >
            Login
          </button>
          {showLoginOptions && (
            <div className="relative mt-6 space-y-4 z-10">
              <Link to="/Signin" className="bg-Yellow text-Darkblue px-6 py-3 rounded-full w-full text-center hover:bg-White hover:text-Darkblue focus:outline-none focus:ring-4 focus:ring-gray-400 block">
                Student Login
              </Link>
              <Link to="/login/startup" className="bg-Yellow text-Darkblue px-6 py-3 rounded-full w-full text-center hover:bg-White hover:text-Darkblue focus:outline-none focus:ring-4 focus:ring-gray-400 block">
                Employee Login
              </Link>
            </div>
          )}
        </div>

        {/* Verify Section */}
        <div className="relative group bg-Darkblue p-8 rounded-lg shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl">
          <div className="absolute inset-0 opacity-0 group-hover:opacity-70 transition duration-500 rounded-lg"></div>
          <h3 className="relative text-3xl font-semibold text-center text-Yellow mb-4 z-10">Verify</h3>
          <p className="relative text-center text-White mb-6 z-10 text-justify">Verify the status of your internship application using your unique ID and password.</p>
          <Link to="/verify" className="relative bg-Yellow text-Darkblue px-6 py-3 rounded-full w-full text-center hover:bg-White hover:text-Darkblue focus:outline-none focus:ring-4 focus:ring-gray-400 z-10 block">
            Verify
          </Link>
        </div>
      </div>
    </div>
  );
};

export default InternshipSection;
