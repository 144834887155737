import React from "react";
import WhatsAppStickyButton from "../chunkpages/WhatsappStickyButton";
function AILaunchpad() {
  return (
    <div>
      AILaunchpad
      <WhatsAppStickyButton />
    </div>
  );
}

export default AILaunchpad;
