import React, { useState } from "react";
import Footer from "../UIcomp/Footer";
import WhatsAppStickyButton from "../chunkpages/WhatsappStickyButton";

export default function Fundings() {
  const Modal = ({ handleClose, show, reason }) => {
    const showHideClassName = show
      ? "fixed inset-0 overflow-y-auto flex items-center justify-center"
      : "hidden";

    return (
      <div className={showHideClassName}>
        <div className="modal-overlay fixed inset-0 bg-black opacity-50"></div>

        <div className="modal-content bg-white p-6 md:p-8 rounded-lg shadow-lg z-50 w-full max-w-md relative">
          <button
            className="absolute top-0 right-0 m-4 text-gray-700 hover:text-gray-900"
            onClick={handleClose}
          >
            Close
          </button>
          <div className="text-center">
            <h1 className="text-3xl font-bold mb-4">{reason.title}</h1>
            <img
              className="w-full h-auto rounded-lg mb-4"
              src="/images/web3-bg.jpg"
              alt=""
            />
            <p className="text-gray-700">{reason.description}</p>
          </div>
        </div>
      </div>
    );
  };

  const reasons = [
    {
      title: "Funding Proposal Writer",
      description:
        "Are you seeking financial support for your project or organization? Let our experienced funding proposal writers craft compelling proposals that resonate with donors, investors, and grant-making bodies. With meticulous attention to detail and a deep understanding of what funders look for, we can help you secure the funding you need to bring your ideas to life.",
    },
    {
      title: "Grant Application Consultant",
      description:
        "Navigating the intricacies of grant applications can be daunting. Our seasoned consultants specialize in guiding you through the process, from identifying relevant funding opportunities to developing winning proposals. With our expertise, you can maximize your chances of securing grants that fuel your mission and drive positive change.",
    },
    {
      title: "Investor Pitch Deck Designer",
      description:
        "Captivate investors and stakeholders with visually stunning pitch decks that tell your story with clarity and impact. Our design team combines creativity and strategic thinking to craft compelling presentations that highlight your business's unique value proposition and growth potential. Let us help you make a lasting impression and secure the funding you need to thrive.",
    },
    {
      title: "Business Valuation Analyst",
      description:
        "Understanding the true value of your business is crucial for making informed decisions and attracting investors. Our team of skilled analysts employs rigorous methodologies to conduct comprehensive business valuations tailored to your specific needs. Whether you're seeking investment, planning an exit strategy, or undergoing a merger, we provide reliable insights to support your financial objectives.",
    },
    {
      title: " Fundraising Consultant",
      description:
        "Unlock the full potential of your fundraising efforts with expert guidance from our consultants. From donor engagement strategies to campaign planning and execution, we offer personalized solutions to help you achieve your fundraising goals. With a focus on building sustainable relationships and maximizing impact, we empower organizations to thrive in their philanthropic endeavors.",
    },
    {
      title: "Funding Strategy Advisor",
      description:
        "Developing a robust funding strategy is essential for sustaining and growing your organization. Our advisors work closely with you to assess your financial needs, identify opportunities for diversification, and optimize resource allocation. Whether you're a startup seeking seed funding or an established enterprise exploring new avenues for growth, we provide strategic guidance to drive long-term success.",
    },
    // Add more reasons as needed
  ];
  const [showModal, setShowModal] = useState(false);
  const [selectedReason, setSelectedReason] = useState({});

  // Function to toggle modal visibility
  const toggleModal = (reason) => {
    setSelectedReason(reason);
    setShowModal(!showModal);
  };

  return (
    <div>
      <div className="relative px-8 py-20 xl:px-20 2xl:mx-auto 2xl:container">
        <h1 className="hidden pb-10 text-6xl text-center text-orange-600 font-Poppins xl:block">
          Make Preparation For Fundings
        </h1>
        <h1 className="block text-5xl text-center font-bold leading-tight text-orange-600 xl:hidden lg:leading-10">
          Make Preparation For Fundings
        </h1>
      </div>
      <div className="relative mx-auto max-w-[90%] md:max-w-full mb-8 lg:mb-16">
        <div
          className="grid flex justify-center place-content-end rounded-[20px] bg-cover bg-center w-full h-[250px] md:h-[600px] relative"
          style={{ backgroundImage: "url('images/funding.jpg')" }}
        >
          <div className="absolute bottom-0 left-0 right-0 px-4 md:px-6 bg-slate-200/50 rounded-b-[20px]">
            <h2 className="text-4xl md:text-6xl font-extrabold text-blue-600 text-center font-Poppins">
              Transform Your Ideas into Products..!
            </h2>
            <p className="text-sm md:text-xl text-orange-600 md:line-clamp-2 hover:line-clamp-4 font-semibold text-center mt-2">
              At Altruisty, we specialize in guiding individuals like you
              through the process of transforming your innovative ideas into
              successful startups.
            </p>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <div className="py-12 ">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
              <h2 className="text-base text-orange-600 font-semibold tracking-wide uppercase">
                We Deliver Better Products
              </h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-green-600 sm:text-4xl">
                A better way to deliver
              </p>
              <p className="mt-4 max-w-2xl text-xl text-white lg:mx-auto">
                What We do With Your Idea
              </p>
            </div>

            <div className="mt-10">
              <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
                {reasons.map((reason) => (
                  <div className="flex flex-col items-center p-4 border-2 border-slate-300 hover:border-black transition-all duration-300 transform backdrop-blur shadow-lg rounded-xl hover:-translate-y-2 hover:shadow-2xl">
                    <span className="text-xl">{reason.icon}</span>

                    <h3 className="mb-2 text-green-600 text-xl font-bold py-4">
                      {reason.title}
                    </h3>
                    <p className="text-black">{reason.description}</p>

                    <button
                      className="mt-4"
                      onClick={() => toggleModal(reason)}
                    >
                      <div className="relative inline-block text-sm group">
                        <span className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-blue-900 transition-colors duration-300 ease-out border-2 border-blue-900 rounded-lg group-hover:text-white">
                          <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-gray-50"></span>
                          <span className="absolute left-0 w-40 h-40 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-blue-900 group-hover:-rotate-180 ease"></span>
                          <span className="relative">Explore more</span>
                        </span>
                        <span
                          className="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-blue-900 rounded-lg group-hover:mb-0 group-hover:mr-0"
                          data-rounded="rounded-lg"
                        ></span>
                      </div>
                    </button>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        handleClose={() => toggleModal(selectedReason)}
        reason={selectedReason}
      />
      <WhatsAppStickyButton />
      <Footer />
    </div>
  );
}
