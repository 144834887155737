
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaGlobe, FaCog, FaMobileAlt, FaBriefcase } from "react-icons/fa";

export default function Testimonials() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);

  const testimonials = [
    {
      title: "Innovative Solutions for Dynamic Markets",
      description: "In today's fast-paced and ever-changing markets, staying ahead requires agility and innovation. Our cutting-edge solutions are designed to help you navigate the complexities of dynamic markets, offering you the tools and strategies needed to adapt quickly and effectively. Partner with us to stay competitive and seize new opportunities as they arise.",
      icon: <FaGlobe className="text-[#17212E] text-4xl mb-4" />,
    },
    {
      title: "Solutions Designed for Your Needs",
      description: "At our core, we believe that one size does not fit all. Our solutions are meticulously crafted to address the specific needs and goals of your business. Whether you are looking to streamline processes, improve customer engagement, or boost productivity, our team of experts will work closely with you to develop customized strategies that deliver measurable results.",
      icon: <FaCog className="text-[#17212E] text-4xl mb-4" />,
    },
    {
      title: "Tailored Solutions for Your Business Needs",
      description: "At our core, we understand that every business is unique. Our solutions are meticulously crafted to address your specific goals, whether it is streamlining processes, enhancing customer engagement, or boosting productivity. Partner with us to develop customized strategies that deliver measurable results and keep you ahead in a fast-paced market.",
      icon: <FaMobileAlt className="text-[#17212E] text-4xl mb-4" />,
    },
    {
      title: "Driving Growth Through Digital Innovation",
      description: "Embrace the future with our transformative digital solutions tailored to future-proof your operations. From cloud computing to IoT and data analytics, we optimize workflows and connectivity to anticipate market shifts and capitalize on emerging opportunities. Join us on a digital journey that transforms challenges into competitive advantages, ensuring sustained growth in a dynamic marketplace.",
      icon: <FaBriefcase className="text-[#17212E] text-4xl mb-4" />,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
  };

  return (
    <div className="w-full pb-16 bg-gray-100">
      <div className="w-full py-4 lg:pt-20 flex flex-col items-center">
        <h1 className="text-lg py-2 px-4 md:text-4xl text-[#F5cF6B] md:px-10 mt-8 text-center border-2 bg-[#17212E] font-times mb-10">
          OUR STARTUP SERVICES
        </h1>
        <div className="w-full max-w-5xl px-4 md:px-8 lg:px-12">
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className="flex flex-col items-center text-center px-4"
                data-aos="fade-up"
              >
                <div className="flex justify-center mb-4">{testimonial.icon}</div>
                <div className="text-center">
                  <h2 className="text-xl md:text-2xl font-bold text-[#17212E] mb-4">
                    {testimonial.title}
                  </h2>
                  <p className="text-gray-600 text-base md:text-lg">
                    {testimonial.description}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
