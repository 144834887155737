
import React from 'react';
import { Link } from 'react-router-dom';
// import './Altruistyservices.css';
import './others.css';
const DigitalMarketing = () => {
  return (
    <>
      <section className="py-20 pt-32 text-white text-center digimark font-times" id="digimark">
        <h1 className="text-4xl leading-tight font-bold">Digital Marketing</h1>
        <p className="mt-4 text-lg max-w-2xl mx-auto">Digital marketing, also called online marketing, is the promotion of brands to connect with potential customers using the internet and other forms of digital communication.</p>
        <Link to="/form">
          <button className="butt">Contact</button>
        </Link>
      </section>

      <section className="py-20 bg-gray-100 text-center socialmed font-times" id="socialmed">
        <h1 className="text-4xl font-bold">Social Media Ads Campaign</h1>
        <p className="mt-4 text-lg max-w-2xl mx-auto">Reach your target audience effectively with strategic social media advertising campaigns. Whether it's Facebook, Instagram, or LinkedIn, our expert marketers create and optimize ads that drive results and maximize your ROI.</p>
      </section>

      <section className="py-20 bg-white text-center contentcre font-times " id="contentcre">
        <h1 className="text-4xl font-bold">Content Creation</h1>
        <p className="mt-4 text-lg max-w-2xl mx-auto">Create compelling and engaging content that resonates with your audience. Our creative team develops high-quality content, including articles, blog posts, videos, and graphics, tailored to your brand and objectives.</p>
      </section>

      <section className="py-20 bg-gray-100 text-center emailmark font-times" id="emailmark">
        <h1 className="text-4xl font-bold">Social Media & Email Marketing Campaigns</h1>
        <p className="mt-4 text-lg max-w-2xl mx-auto">Drive conversions and engagement with targeted social media and email marketing campaigns. Our expert marketers develop strategic campaigns tailored to your audience, maximizing reach and ROI across multiple channels.</p>
      </section>

      <section className="py-20 bg-white text-center leadgen font-times"  id="leadgen">
        <h1 className="text-4xl font-bold">Lead Generation Campaigns</h1>
        <p className="mt-4 text-lg max-w-2xl mx-auto">Generate quality leads and grow your customer base with our lead generation campaigns. Our targeted strategies leverage various channels and tactics to attract and convert prospects into valuable leads for your business.</p>
      </section>

      <section className="py-20 bg-gray-100 text-center searcheng font-times" id="searcheng">
        <h1 className="text-4xl font-bold">Search Engine Optimization (SEO) & Search Engine Marketing (SEM)</h1>
        <p className="mt-4 text-lg max-w-2xl mx-auto">Boost your online visibility and drive organic traffic with our SEO and SEM services. From keyword optimization to PPC campaigns, we employ proven strategies to improve your search engine rankings and drive qualified leads to your website.</p>
      </section>

      <section className="py-20 bg-white text-center mediamanage font-times" id="mediamanage">
        <h1 className="text-4xl font-bold">Social Media Management</h1>
        <p className="mt-4 text-lg max-w-2xl mx-auto">Maximize your online presence and engagement with our social media management services. From content creation to community engagement, we handle all aspects of your social media presence, ensuring consistent and effective communication with your audience.</p>
      </section>
    </>
  );
}

export default DigitalMarketing;
