import { createClient } from "contentful";
import { useEffect, useState } from "react";

const PastEvents = () => {
	const [pastEvents, setPastEvents] = useState([]);

	const client = createClient({
		space: process.env.REACT_APP_SPACE_ID,
		accessToken: process.env.REACT_APP_ACCESS_TOKEN,
	});

	useEffect(() => {
		const getAllEntries = async () => {
			try {
				const entries = await client.getEntries();
				const currentDate = new Date();

				const pastEvents = [];
				entries.items.forEach((event) => {
					const eventDate = new Date(event.fields.eventDate);
					if (eventDate < currentDate) {
						pastEvents.push(event);
					}
				});

				setPastEvents(pastEvents);
			} catch (error) {
				console.log(`Error fetching events: ${error}`);
			}
		};
		getAllEntries();
	}, []);

	function formatDate(timestamp) {
		const date = new Date(timestamp);
		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		const day = date.getDate();

		const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
			day < 10 ? "0" : ""
		}${day}`;

		return formattedDate;
	}

	return (
		<div>
			<div className="relative mx-auto px-4 md:px-12 lg:px-24 ">
				<div className="text-3xl font-semibold text-black py-4 text-center pt-28 font-mono">
					Past Events
				</div>
				<div className="flex flex-col gap-14 justify-center items-center lg:flex-row flex-wrap mt-4">
					{pastEvents.map((event) => (
						<div
							key={event.sys.id}
							className="bg-slate-100 rounded-lg xl:w-[45%] w-full h-48"
						>
							<div className="flex">
								<img
									className="h-48 object-cover w-5/12 rounded-l-lg rounded-b-none md:rounded-b-lg"
									src={event.fields.eventImage.fields.file.url}
									alt={event.fields.title}
								/>
								<div className="p-4 w-7/12">
									<div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
										{event.fields.title}
									</div>
									<div className="tracking-wide text-xs text-gray-500 font-semibold">
										{formatDate(event.fields.eventDate)}
									</div>
									<p className="mt-3 leading-tight text-black text-sm line-clamp-6">
										{event.fields.description}
									</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default PastEvents;
