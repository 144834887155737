import React from 'react';
import { Link } from 'react-router-dom';

const FinalProjectCarousel = ({ slides }) => {
  return (
    <section className="py-4 bg-gray-100 px-4 sm:px-8 lg:px-16">
      <div className="text-center mb-10">
      <h1 className="text-2xl md:text-4xl font-extrabold text-[#17212E] font-times mb-8">
      Final Year Project Training
        </h1>
        <p className="text-lg md:font-semibold text-gray-700 mb-6 mx-8 mt-4">
          Deliver fast, efficient service experiences without the complexity of traditional ITSM solutions. Streamline critical development tasks, eliminate toil, and deploy changes with ease.
        </p>
        <div className="flex justify-center">
          <Link to="/form">
          <button className="mt-4 bg-[#17212E] pt-2 pb-2   text-[#F5CF6B]  font-bold py-2 px-4 rounded-l hover:bg-[#F5CF6B] hover:text-black">
              Explore Services
            </button>
          </Link>
        </div>
      </div>

      {/* Cards Section */}
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8 max-w-screen-xl w-300 mx-auto justify-center">
  {slides.map((slide, index) => (
    <div
      key={index}
      className="relative rounded-lg overflow-hidden transition-transform transform hover:scale-105"
      data-aos="fade-up"
    >
      <img className="object-cover w-full h-64" src={slide.image} alt={slide.title} />
      <div className="absolute inset-0 bg-gray-900 bg-opacity-90 text-white opacity-0 hover:opacity-100 transition-opacity duration-300 ease-in-out flex flex-col items-center justify-center p-6">
        <ul className="text-center text-center space-y-2">
          <li>{slide.description}</li>
        </ul>
        <div className="mt-4 text-center">
          <Link to={"/form"}>
            <button className="px-4 py-2 bg-[#17212E]  text-[#F5CF6B] rounded-lg hover:bg-[#F5CF6B] hover:text-black transition duration-300">
              More →
            </button>
          </Link>
        </div>
      </div>
      <div className="p-6 bg-white">
        <h2 className="text-xl font-bold text-[#17212E] mb-2 text-center">{slide.title}</h2>
      </div>
    </div>
  ))}
</div>

      
    </section>
  );
};

export default FinalProjectCarousel;
