import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

const fields = [
  {
    label: "Name",
    type: "text",
    placeholder: "name",
    required: true,
    gridCols: 1,
  },
  {
    label: "Email",
    type: "email",
    placeholder: "mail id",
    required: true,
    gridCols: 2,
  },
  {
    label: "Address",
    type: "text",
    placeholder: "Enter your address",
    required: true,
    gridCols: 2,
  },
  {
    label: "Country",
    type: "text",
    placeholder: "Country",
    required: true,
    gridCols: 1,
  },
  {
    label: "City",
    type: "text",
    placeholder: "City",
    required: true,
    gridCols: 1,
  },
  {
    label: "Country Code",
    type: "tel",
    placeholder: "+91",
    required: true,
    gridCols: 1,
  },
  {
    label: "Phone Number",
    type: "tel",
    placeholder: "phone number",
    required: true,
    gridCols: 1,
  },
  {
    label: "Domain",
    type: "select",
    options: [
      "App Development",
      "Web Development",
      "Digital Marketing",
      "Graphic Designing",
      "Training Program",
      "Internship Program",
    ],
    placeholder: "Select Domain",
    required: true,
    gridCols: 2,
  },
  {
    label: "Graduating year",
    type: "month",
    placeholder: "graduation year",
    required: true,
    gridCols: 2,
  },
  {
    label: "College/University",
    type: "text",
    placeholder: "College/University",
    required: true,
    gridCols: 2,
  },
  {
    label: "Why we Need To Hire You",
    type: "textarea",
    placeholder: "",
    required: true,
    gridCols: 2,
  },
  {
    label: "Skills",
    type: "text",
    placeholder: "Your skills",
    required: true,
    gridCols: 2,
  },
  {
    label: "Department",
    type: "text",
    placeholder: "Your department",
    required: true,
    gridCols: 2,
  },
  {
    label: "Year",
    type: "text",
    placeholder: "Year",
    required: true,
    gridCols: 1,
  },
  {
    label: "LinkedIn Profile",
    type: "url",
    placeholder: "LinkedIn Profile URL",
    required: true,
    gridCols: 1,
  },
  {
    label: "GitHub URL",
    type: "url",
    placeholder: "GitHub URL",
    required: true,
    gridCols: 1,
  },
  {
    label: "Duration",
    type: "select",
    options: ["1 month", "3 months", "6 months"],
    placeholder: "Select duration",
    required: true,
    gridCols: 1,
  },
  {
    label: "Resume",
    type: "file",
    placeholder: "Upload your resume",
    required: true,
    gridCols: 2,
  },
];

const servicesOptions = [
  "App Development",
  "Web Development",
  "Digital Marketing",
  "Graphic Designing",
  "Training Program",
  "Internship Program",
];

const ServicesDropdown = ({ value, onChange }) => {
  const [open, setOpen] = useState(false);

  const toggleDropdown = () => {
    setOpen(!open);
  };

  const handleSelect = (service) => {
    onChange(service);
    setOpen(false);
  };

  return (
    <div className="relative">
      <input
        type="text"
        value={value}
        placeholder="Select Service"
        readOnly
        onClick={toggleDropdown}
        className="border border-gray-300 rounded-md py-3 px-4 outline-none focus:border-blue-500 cursor-pointer w-full"
      />
      {open && (
        <div className="absolute z-10 bg-white border border-gray-300 rounded-md mt-1 w-full max-h-40 overflow-y-auto shadow-md">
          {servicesOptions.map((service, index) => (
            <div
              key={index}
              className={`cursor-pointer py-3 px-4 ${
                value === service ? "bg-gray-100 font-semibold" : "hover:bg-gray-100"
              }`}
              onClick={() => handleSelect(service)}
            >
              {service}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default function RegistrationForm() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async (data) => {
    try {
      // Ensure the resume field is present
      console.log('Form data:', data); // Add this line for debugging
      if (!data.resume || data.resume.length === 0) {
        throw new Error('No file uploaded');
      }
  
      const file = data.resume[0];
      const storageRef = ref(storage, `resumes/${file.name}`);
  
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
  
      // Create a new object with the required fields
      const internData = {
        name: data.name,
        email: data.email,
        address: data.address,
        country: data.country,
        city: data.city,
        countryCode: data["country code"],
        phoneNumber: data["phone number"],
        domain: data.domain,
        graduatingYear: data["graduating year"],
        collegeUniversity: data["college/university"],
        whyHire: data["why we need to hire you"],
        skills: data.skills,
        department: data.department,
        year: data.year,
        linkedinProfile: data["linkedin profile"],
        githubUrl: data["github url"],
        duration: data.duration,
        resumeLink: downloadURL // Use the download URL for resumeLink
      };
      // Log internData for debugging purposes
      console.log('Intern data to be sent:', internData);
  
      const response = await fetch('http://localhost:5000/api/interns', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(internData),
      });
  
      console.log(response);
  
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
  
      const result = await response.json();
      console.log(result);
  
      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };


  return (
    <section className="relative overflow-hidden flex justify-center py-20 lg:py-[20px]">
      <div className="lg:w-1/2 w-3/4 pb-10 pt-5 p-4 flex flex-wrap justify-center items-center bg-Darkblue shadow-2xl my-20 rounded-md mx-auto">
        {submitted ? (
          <div className="flex justify-center items-center text-3xl font-bold">Login successful!</div>
        ) : (
          <>
            <h1 className="text-3xl font-bold mb-6 text-Yellow">Registration Form</h1>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                {fields.map((field, index) => (
                  <div key={index} className={`text-left flex flex-col gap-2 w-full ${field.gridCols === 2 ? "md:col-span-2" : ""}`}>
                    <label className="font-semibold text-Yellow">{field.label}</label>
                    {field.label === "Domain" ? (
                      <select
                        {...register(field.label.toLowerCase(), { required: field.required })}
                        className="border border-gray-300 rounded-md py-2 px-3 outline-none focus:border-blue-500"
                        defaultValue=""
                      >
                        <option value="" disabled>{field.placeholder}</option>
                        {field.options.map((option, i) => (
                          <option key={i} value={option}>{option}</option>
                        ))}
                      </select>
                    ) : field.label === "Services" ? (
                      <ServicesDropdown
                        value={""}
                        onChange={(value) => console.log(value)} // Handle service selection here
                      />
                    ) : field.type === "select" ? (
                      <select
                        {...register(field.label.toLowerCase(), { required: field.required })}
                        className="border border-gray-300 rounded-md py-2 px-3 outline-none focus:border-blue-500"
                        defaultValue=""
                      >
                        <option value="" disabled>{field.placeholder}</option>
                        {field.options.map((option, i) => (
                          <option key={i} value={option}>{option}</option>
                        ))}
                      </select>
                    ) : field.type === "textarea" ? (
                      <textarea
                        {...register(field.label.toLowerCase(), { required: field.required })}
                        className="border border-gray-300 rounded-md py-2 px-3 outline-none focus:border-blue-500"
                        placeholder={field.placeholder}
                      />
                    ) : (
                      <input
                        {...register(field.label.toLowerCase(), { required: field.required })}
                        className="border border-gray-300 rounded-md py-2 px-3 outline-none focus:border-blue-500"
                        type={field.type}
                        placeholder={field.placeholder}
                      />
                    )}
                    {errors[field.label.toLowerCase()] && <span className="text-blue-500 text-sm">This field is required</span>}
                  </div>
                ))}
              </div>
              <button
                type="submit"
                className="bg-Yellow text-Darkblue py-1 px-2 rounded-md font-semibold hover:bg-white hover:text-blue-500 border border-blue-500 transition duration-300 ease-in-out flex justify-center items-center"
              >
                <span>Register</span>
              </button>
            </form>
          </>
        )}
      </div>
    </section>
  );
}
