import React, { useEffect, useState } from "react";
import { IoMdMailOpen } from "react-icons/io";
import { FaLocationArrow } from "react-icons/fa";
import { LuPhoneCall } from "react-icons/lu";
import { FaLinkedin } from "react-icons/fa";
import './Contactcntxt.css'; // Import the CSS file
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Aos from "aos";
import 'aos/dist/aos.css'
export default function Contactcntxt() {
  const [loading, setLoading] = useState(true);
 
  useEffect(()=> {
    Aos.init({duration:2000});
  },[])

  useEffect(() => {
    // Simulate loading delay (you can remove this in actual implementation)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust as needed

    return () => clearTimeout(timer);
  }, []); 
  return (
    <section className="pt-10 bgcolor">
      <div  className=" px-6 py-10 mx-auto mx-3">
        <div data-aos="fade-down" className="bg-[#17212E]  rounded-lg shadow-xl p-8 mb-8 w-full z-5">
          <h1 className="text-xl sm:text-3xl md:text-3xl text-[#F5CF6B] text-center  mb-0 font-times">
            Get in Touch with Altruisty!
          </h1>
        </div>

        <div className="grid grid-cols-1 gap-12 mt-10 -mb-8 md:mb-0 lg:grid-cols-2">
        <div data-aos="fade-right">
            <div>
             {loading ? ( 
              <Skeleton baseColor="white" className="h-56 mb-10 w-full map rounded-xl" />
               ) : (
            
              <div className="h-56 mb-10 w-full map rounded-xl">
                 <iframe className="w-full h-full md:h-full rounded-xl"  frameborder="0" scrolling="no" marginheight="0" marginwidth="0" 
                     src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Besant%20Nagar%201st%20Cross%20Street,%20Velachery,%20Tamil%20Nadu%20600090,%20IN+(Altruistu)&amp;t=p&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                 <a href="https://www.gps.ie/">gps systems</a></iframe></div>
            
              )}
            </div> 

            <div className="hidden md:block" data-aos="fade-up">
            <div className="grid grid-cols-1  gap-6  sm:grid-cols-2  md:grid-cols-2 ">
            {/* Email */}
            <div className="contact-item">
              <div className="flex ">
              <IoMdMailOpen className="icon" />
              <h2 className="mt-3 ml-4  text-base font-bold  text-white">Email</h2>
              </div>
              <br />
              <p className="mt-2  text-[#F5CF6B] font-bold text-lg">
                <a href="mailto:altruistybusiness@gmail.com">
                  altruistybusiness@gmail.com
                </a>
              </p>
            </div>

            {/* LinkedIn */}
            <div className="contact-item">
              <div className="flex">
              <FaLinkedin className="icon" />
              <h2 className="mt-3 ml-4 text-base font-bold  text-white">LinkedIn</h2>
              </div>
              <br />
              <p className="mt-2  text-[#F5CF6B] font-bold text-lg">
                <a href="https://www.linkedin.com/company/altruisty/mycompany/">
                  Altruisty 
                </a>
              </p>
            </div>

            {/* Office */}
            <div className="contact-item">
              <div className="flex">
               <FaLocationArrow className="icon" />
               <h2 className="mt-3 ml-4 text-base font-bold  text-[white]">Office</h2>
              </div>
              <br />
              <p className="mt-2 text-lg font-bold  text-[#F5CF6B] ">
                16, 12, 2nd St Rd, Urrur Kuppam, <br />
                Besant Nagar, Chennai, Tamil Nadu 600090
              </p>
            </div>

            {/* Phone */}
            <div className="contact-item">
              <div className="flex">
               <LuPhoneCall className="icon" />
               <h2 className="mt-3 ml-4 text-base font-bold  text-[white]">Phone</h2>
              </div>
                            <br />
              <p className="mt-2 text-lg  text-[#F5CF6B] font-bold">
                Mon-Fri from 8am to 5pm.
              </p>
              <p className="mt-2 text-lg font-bold  text-[#F5CF6B] ">
                <a href="tel:+918667839838">+91 8667839838</a>
              </p>
            </div>
          </div>

            </div>
      
        </div >  
      
          {/* Form */}
          <div data-aos="fade-left" className="form-container bg-white rounded-xl -mt-16 -mb-4 md:mt-0 md:mb-0">
            <form
              action="https://formsubmit.co/altruistyservice@gmail.com"
              method="POST"
            >
              <input type="hidden" name="_captcha" value="false" />
              <input
                type="hidden"
                name="_next"
                value="https://altruisty.site/"
              />
              <input
                type="hidden"
                name="_subject"
                value="You Have Received a New Submission"
              ></input>
              {/* First and Last Name */}
              <div className="-mx-2 md:items-center md:flex">
                <div className="flex-1 px-2">
                  <label className="block font-bold  mb-2 text-sm text-[#17212E] ">
                    First Name
                  </label>
                  <input
                    type="text"
                   
                    className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bgcolorborder border-gray-200 rounded-lg focus:border-[#F5CF6B] dark:focus:border-[#F5CF6B] focus:ring-[#F5CF6B] focus:outline-none focus:ring focus:ring-opacity-40"
                    name="Firstname"
                  />
                </div>

                <div className="flex-1 px-2 mt-4 md:mt-0">
                  <label className="block font-bold  mb-2 text-sm text-[#17212E] ">
                    Last Name
                  </label>
                  <input
                    type="text"
                   
                    className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bgcolorborder border-gray-200 rounded-lg focus:border-[#F5CF6B] dark:focus:border-[#F5CF6B] focus:ring-[#F5CF6B] focus:outline-none focus:ring focus:ring-opacity-40"
                    name="Lastname"
                  />
                </div>
              </div>

              {/* Email Address */}
              <div className="mt-4">
                <label className="block font-bold  mb-2 text-sm text- text-[#17212E] ">
                  Email address
                </label>
                <input
                  type="email"
                  
                className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bgcolorborder border-gray-200 rounded-lg focus:border-[#F5CF6B] dark:focus:border-[#F5CF6B] focus:ring-[#F5CF6B] focus:outline-none focus:ring focus:ring-opacity-40"
                  name="E-mail"
                />
              </div>

              {/* Service Opted */}
              <div className="mt-4">
                <label className="block font-bold  mb-2 text-sm text-[#17212E] ">
                  Service Opted
                </label>
                <input
                  type="text"
                 
                  className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bgcolorborder border-gray-200 rounded-lg focus:border-[#F5CF6B] dark:focus:border-[#F5CF6B] focus:ring-[#F5CF6B] focus:outline-none focus:ring focus:ring-opacity-40"
                  name="Service opted"
                />
              </div>

              {/* Message */}
              <div className="w-full mt-4">
                <label className="block font-bold text-lg  mb-2 text-sm text-[#17212E]">
                  Message
                </label>
                <textarea
                  className="block w-full  h-32 px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bgcolorborder border-gray-200 rounded-lg md:h-52 focus:border-[#F5CF6B] dark:focus:border-[#F5CF6B] focus:ring-[#F5CF6B] focus:outline-none focus:ring focus:ring-opacity-40"
                  
                  name="Message"
                ></textarea>
              </div>

              {/* Send Button */}
              <button className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-[#F5CF6B] capitalize transition-colors duration-300 transform bg-[#17212E] rounded-lg  focus:outline-none focus:ring  focus:ring-opacity-50">
                Send message
              </button>
            </form>
          </div>
        </div>
      </div>

      <div className="md:hidden" data-aos="fade-up">
      <div className="grid grid-cols-1 sm:grid-cols-2 mx-5 md:grid-cols-2 lg:grid-cols-4 m-10 gap-6">
            {/* Email */}
            <div className="contact-item">
              <div className="flex ">
              <IoMdMailOpen className="icon" />
              <h2 className="mt-3 ml-4  text-base font-bold  text-white">Email</h2>
              </div>
              <br />
              <p className="mt-2  text-[#F5CF6B] font-bold text-lg">
                <a href="mailto:altruistybusiness@gmail.com">
                  altruistybusiness@gmail.com
                </a>
              </p>
            </div>

            {/* LinkedIn */}
            <div className="contact-item">
              <div className="flex">
              <FaLinkedin className="icon" />
              <h2 className="mt-3 ml-4 text-base font-bold  text-white">LinkedIn</h2>
              </div>
              <br />
              <p className="mt-2  text-[#F5CF6B] font-bold text-lg">
                <a href="https://www.linkedin.com/company/altruisty/mycompany/">
                  Altruisty
                </a>
              </p>
            </div>

            {/* Office */}
            <div className="contact-item">
              <div className="flex">
               <FaLocationArrow className="icon" />
               <h2 className="mt-3 ml-4 text-base font-bold  text-[white]">Office</h2>
              </div>
              <br />
              <p className="mt-2 text-lg font-bold  text-[#F5CF6B] ">
                16, 12, 2nd St Rd, Urrur Kuppam, <br />
                Besant Nagar, Chennai, Tamil Nadu 600090
              </p>
            </div>

            {/* Phone */}
            <div className="contact-item">
              <div className="flex">
               <LuPhoneCall className="icon" />
               <h2 className="mt-3 ml-4 text-base font-bold  text-[white]">Phone</h2>
              </div>
                            <br />
              <p className="mt-2 text-lg  text-[#F5CF6B] font-bold">
                Mon-Fri from 8am to 5pm.
              </p>
              <p className="mt-2 text-lg font-bold  text-[#F5CF6B] ">
                <a href="tel:+918667839838">+91 8667839838</a>
              </p>
            </div>
          </div>
        </div>
    </section>
  );
}
