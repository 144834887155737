import React from "react";
import Footer from "../UIcomp/Footer";
import StepsNavigation from "../SubPagesCompos/StepsNavigation.js";
import WhatsAppStickyButton from "../chunkpages/WhatsappStickyButton";
import Startupservices from "../chunkpages/Startupservices.js";
import Startupvision from "./Startupvision.js";
import Headlinestartup from "../chunkpages/Headlinestartup.js";
import Testimonials from "./Services/Headlinestartups.js";
export default function StartupLaunchpad() {
  return (
    <div className=" font-times">
      
     
        <Startupservices />
     {/* <Headlinestartup/> */}
     <Testimonials/>
      <StepsNavigation/>
      <WhatsAppStickyButton />
      <Startupvision/>
      <Footer />
    </div>
  );
}
