import React from "react";
import MusicalSlider from "../chunkpages/MusicalSlider";
import KeyFeatures from "../chunkpages/KeyFeatures";
import WhatsAppStickyButton from "../chunkpages/WhatsappStickyButton";
export default function MusicalHub() {
  return (
    <>
      <MusicalSlider />
      <section className="grid justify-center gap-8 lg:ml-[15em]">
        <div className="lg:flex lg:flex-col-2  gap-4 items-start">
          <div className=" flex flex-col  mt-6 mx-auto text-gray-700  backdrop-blur  shadow-md bg-clip-border items-start w-96 lg:w-[800px] lg:h-[400px] border-2 border-inherit  transition-all duration-300 transform bg-slate  place-items-center rounded-[50px] hover:-translate-y-2 hover:shadow-2xl">
            <h5 className="block mx-auto mt-10 font-sans text-3xl  font-bold  tracking-normal text-green-600">
              Only Here!!!
            </h5>
            <p className="block px-4 lg:mt-10 font-sans  py-4  leading-relaxed text-xl text-white">
              Learn Music Instruments at your own pace that favours your
              interest and growth.
            </p>
          </div>
          <div className="grid md:grid-cols-1 mt-10 gap-4 justify-between md:w-full w-96  md:pr-20 md:mx-20  ">
            <div className=" p-4 leading-normal pr-10 w-96 border-gray-200 rounded-lg shadow backdrop-blur  md:flex-row    border-2 border-inherit  transition-all duration-300 transform   place-items-center  hover:-translate-y-2 hover:shadow-2xl">
              <h5 className="mb-2 text-3xl font-bold tracking-tight text-green-600 text-center">
                1x1
              </h5>
              <p className="mb-3 text-xl font-normal text-blue-600 text-pretty text-center">
                Live Online Session
              </p>
            </div>

            <div className="flex justify-end">
              <div className=" p-4 leading-normal pr-10 w-96 border-gray-200 rounded-lg shadow backdrop-blur  md:flex-row  border-2 border-inherit  transition-all duration-300 transform   place-items-center  hover:-translate-y-2 hover:shadow-2xl">
                <h5 className="mb-2 text-3xl font-bold tracking-tight text-green-600 text-center">
                  Personalized
                </h5>
                <p className="mb-3 text-xl font-normal text-blue-600 text-pretty text-center">
                  Result Oriented Curriculum
                </p>
              </div>
            </div>

            <div className=" p-4 leading-normal pr-10 w-96 border-gray-200 rounded-lg shadow backdrop-blur  md:flex-row   border-2 border-inherit  transition-all duration-300 transform   place-items-center  hover:-translate-y-2 hover:shadow-2xl">
              <h5 className="mb-2 text-3xl font-bold tracking-tight text-green-600 text-center">
                Performance
              </h5>
              <p className="mb-3 text-xl font-normal text-blue-600 text-pretty text-center">
                in Competitions
              </p>
            </div>
            <div className="flex justify-end">
              <div className=" p-4 leading-normal pr-10 w-96 border-gray-200 rounded-lg shadow backdrop-blur  md:flex-row   border-2 border-inherit  transition-all duration-300 transform   place-items-center  hover:-translate-y-2 hover:shadow-2xl">
                <h5 className="mb-2 text-3xl font-bold tracking-tight text-green-600 text-center">
                  Graded
                </h5>
                <p className="mb-3 text-xl font-normal text-blue-600 text-pretty text-center">
                  Courses
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <KeyFeatures />
      <WhatsAppStickyButton />
    </>
  );
}
