
import { Link } from "react-router-dom";
import Service1 from '../../assets/Service1.png'; // Import the image

const ServiceBanner = () => {
  const firstWord = "We";
  const secondWord = "Improve";
  const thirdWord = "Sales Efficiency";

  const createLetterObjects = (word) => {
    return word.split("").map((char, index) => ({
      char,
      style: {
        animationDelay: `${index * 0.1}s`,
      },
    }));
  };

  const firstWordLetters = createLetterObjects(firstWord);
  const secondWordLetters = createLetterObjects(secondWord);
  const thirdWordLetters = createLetterObjects(thirdWord);

  return (
    <div className="relative w-full h-[450px] md:h-[750px] flex items-center  justify-center overflow-hidden">
      <div className="absolute w-full h-full bg-cover bg-center animate-zoomIn" style={{ backgroundImage: `url(${Service1})` }}></div>
      <div className="absolute w-full h-full bg-black opacity-40 backdrop-blur-md"></div>
      <div className="absolute w-full text-center z-10">
        <h1 className="md:mt-0 mt-7 text-2xl md:text-7xl md:mb-3 font-extrabold font-times text-white flex justify-center">
          {firstWordLetters.map((letter, index) => (
            <span key={index} className="inline-block opacity-0 animate-fade-in" style={letter.style}>
              {letter.char}
            </span>
          ))}
          <span className="mx-2 md:mx-3"></span>
          {secondWordLetters.map((letter, index) => (
            <span key={index} className="inline-block opacity-0 animate-fade-in" style={letter.style}>
              {letter.char}
            </span>
          ))}
          <span className="mx-2 md:mx-3"></span>
          {thirdWordLetters.map((letter, index) => (
            <span key={index} className="inline-block opacity-0 animate-fade-in" style={letter.style}>
              {letter.char}
            </span>
          ))}
        </h1>
        <p className="md:text-2xl text-xl mx-2 pt-2 font-bold text-white mb-6 mt-3">The government they survive artical of fortune</p>
        <div>
          <Link to="/Contactus" >
            <button className="mt-6 bg-[#17212E] text-[#F5CF6B] font-bold py-2 px-4 rounded transform transition-transform hover:scale-105 active:scale-105">
              CONTACT US
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ServiceBanner;
