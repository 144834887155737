import React from 'react';
import { Link } from 'react-router-dom';

import image2 from './image2.png';
import images from './images.png';

const Getservice = () => {
  return (
    <section className="font-times relative bg-[#FFFFFF] py-6 md:py-12 lg:py-16 md:mt-12 mt-12">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${images})`,
          backgroundAttachment: 'fixed', // Make background fixed
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
      >
        <div className="absolute inset-0 bg-white opacity-10"></div>
      </div>
      <div className="relative z-10 mx-4 md:mx-auto p-6 md:p-8 bg-white bg-opacity-40 backdrop-filter backdrop-blur-md rounded-lg shadow-lg md:max-w-7xl">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-[#FFFFFF] mb-6 text-center">
          Get Our Services
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8 items-center">
          {/* Left Column: Image */}
          <div className="flex justify-center" data-aos="fade-right">
            <img
              src={image2}
              alt="Service"
              className="w-full h-auto rounded-lg shadow-md"
              style={{ boxShadow: 'none' }} // Remove shadow if causing issues
            />
          </div>
          {/* Right Column: Description */}
          <div className="flex flex-col items-center md:items-start text-left md:text-left" data-aos="fade-left">
            <p className="text-base md:text-lg lg:text-xl text-[#17212E] mb-4 text-center md:text-left">
              At Altruisty, we specialize in transforming your digital dreams into reality. Our expert team is dedicated to providing top-notch services tailored to your unique needs.
            </p>
            <p className="text-base md:text-lg lg:text-xl text-[#17212E] mb-4 text-center md:text-left">
              Whether you're looking to develop a cutting-edge app, revamp your website, design eye-catching graphics, or boost your digital marketing efforts, we have the skills and experience to help you succeed.
            </p>
            <Link to="/form" data-aos="fade-up">
              <button className="px-4 py-2 md:px-6 md:py-3 bg-[#17212E] text-[#F5CF6B] font-semibold rounded-md hover:bg-[#F5CF6B] hover:text-black transition duration-300 mt-4">
                Get Services
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Getservice;
