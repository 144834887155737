

import React from "react";
import { Link } from "react-router-dom";

const SchoolCarousel = ({ slides }) => {
  return (
    <section className=" bg-gray-100 px-4 sm:px-8 lg:px-16">
      <div className="text-center mb-12">
        <h1 className="text-2xl md:text-4xl font-extrabold text-[#17212E] font-times mb-8">
          College Training
        </h1>
        <p className="text-lg md:font-semibold text-gray-700 mb-6 mx-8 mt-4">
         Empower Your College Students with Specialized training and Oppurtunities to excel in their fields. Our Programs are designed to enhance academic Knowledge and provide practical skills for future success
        </p>
        <div className="flex justify-center">
          <Link to="/form">
            <button className="mt-4 bg-[#17212E] text-[#F5CF6B] font-bold py-2 px-4 rounded-lg hover:bg-[#F5CF6B] hover:text-black">
              Explore Services
            </button>
          </Link>
        </div>
      </div>

      {/* Cards Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 max-w-screen-xl mx-auto">
        {slides.map((slide, index) => (
          <div
            key={index}
            className="relative bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-500 hover:scale-105"
            data-aos="fade-up"
          >
            <div className="relative h-64">
              <img
                className="object-cover w-full h-full"
                src={slide.image}
                alt={slide.title}
              />
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent opacity-75"></div>
              <div className="absolute bottom-0 left-0 p-6">
                <h2 className="text-2xl font-bold text-white">{slide.title}</h2>
              </div>
            </div>
            <div className="p-6">
              <p className="text-gray-700 text-center mb-4">{slide.description}</p>
              <Link to={slide.register}>
                <button className="px-4 py-2 bg-[#17212E] ml-28 text-[#F5CF6B] rounded-lg hover:bg-[#F5CF6B] hover:text-black transition duration-300">
                  More →
                </button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SchoolCarousel;