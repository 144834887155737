import React from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import WhatsAppStickyButton from "../chunkpages/WhatsappStickyButton";

function Pastprogramlog() {
  return (
    <section className="bgcolor py-10 sm:py-16 lg:py-32">


<Carousel 
infiniteLoop
autoPlay
interval={2000}
showThumbs={false}
showArrows={false}
showStatus={false}
>
      <div>
        <img src="https://www.rmit.edu.au/content/dam/rmit/au/en/students/images/student-life/events/events-activities-promo.jpg" alt="Slide 1" 
        className='w-128 rounded-xl max-h-[500px] object-cover'
        />
        
      </div>
      <div>
        <img src="https://media.istockphoto.com/id/499517325/photo/a-man-speaking-at-a-business-conference.jpg?s=612x612&w=0&k=20&c=gWTTDs_Hl6AEGOunoQ2LsjrcTJkknf9G8BGqsywyEtE=" alt="Slide 2" 
        className='w-128 rounded-xl max-h-[500px] object-cover'
        />
       
      </div>  
      {/* Add more slides as needed */}
    </Carousel>
<br>
</br>
<br></br>
     <div className=' W-128 max-h-[500px] relative'>
            {/* Overlay */}
            <div className='absolute w-128 h-full text-gray-200 max-h-[500px] bg-black/40 flex flex-col justify-center'>
                <h1 className='px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold'>The <span className='text-orange-500'> Past </span></h1>
                <h3 className='px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold'> <span className='text-orange-500'>Events</span> Arena</h3>
            </div>
            <img className='w-full rounded-xl max-h-[400px] object-cover' src="https://img.freepik.com/free-vector/dark-gradient-background-with-copy-space_53876-99548.jpg?size=626&ext=jpg&ga=GA1.1.871787871.1705766566&semt=ais" alt="/" />
        </div>
<br></br>
      
      <div className="relative mx-auto px-4 md:px-12 lg:px-24 ">
      <h1 className='font-bold text-blue-900 text-5xl'>PAST EVENTS</h1>
      <br></br>
      <br></br>
  
<div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full">
<div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
  <div class="md:flex">
    <div class="md:shrink-0">
      <img class="h-48 w-full object-cover md:h-full md:w-48" src="https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Modern building architecture"/>
    </div>
    <div class="p-8">
      <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Company retreats</div>
      <a href="#" class="block mt-1 text-lg leading-tight font-medium text-black hover:underline">Incredible accommodation for your team</a>
      <p class="mt-2 text-slate-500">Looking to take your team away on a retreat to enjoy awesome food and take in some sunshine? We have a list of places to do just that.</p>
      <a href="/appdevelopment" className="relative inline-block mt-4 text-sm group">
          <span className="block px-5 py-3 font-medium text-blue-900 transition-colors duration-300 ease-out border-2 border-blue-900 rounded-lg group-hover:text-white">
            Apply now
          </span>
        </a>
    </div>

  </div>
</div>
<div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
  <div class="md:flex">
    <div class="md:shrink-0">
      <img class="h-48 w-full object-cover md:h-full md:w-48" src="https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Modern building architecture"/>
    </div>
    <div class="p-8">
      <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Company retreats</div>
      
      <p class="mt-2 text-slate-500">Looking to take your team away on a retreat to enjoy awesome food and take in some sunshine? We have a list of places to do just that.</p>
      <a href="/appdevelopment" className="relative inline-block mt-4 text-sm group">
          <span className="block px-5 py-3 font-medium text-blue-900 transition-colors duration-300 ease-out border-2 border-blue-900 rounded-lg group-hover:text-white">
            Apply now
          </span>
        </a>
  
    </div>


  </div>
</div>
  </div>
</div>
<br></br>
<br></br>
<div className="relative mx-auto px-4 md:px-12 lg:px-24 ">
     
  
<div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full">
<div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
  <div class="md:flex">
    <div class="md:shrink-0">
      <img class="h-48 w-full object-cover md:h-full md:w-48" src="https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Modern building architecture"/>
    </div>
    <div class="p-8">
      <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Company retreats</div>
      <a href="#" class="block mt-1 text-lg leading-tight font-medium text-black hover:underline">Incredible accommodation for your team</a>
      <p class="mt-2 text-slate-500">Looking to take your team away on a retreat to enjoy awesome food and take in some sunshine? We have a list of places to do just that.</p>
      <a href="/appdevelopment" className="relative inline-block mt-4 text-sm group">
          <span className="block px-5 py-3 font-medium text-blue-900 transition-colors duration-300 ease-out border-2 border-blue-900 rounded-lg group-hover:text-white">
            Apply now
          </span>
        </a>
    </div>

  </div>
</div>
<div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
  <div class="md:flex">
    <div class="md:shrink-0">
      <img class="h-48 w-full object-cover md:h-full md:w-48" src="https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Modern building architecture"/>
    </div>
    <div class="p-8">
      <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Company retreats</div>
      
      <p class="mt-2 text-slate-500">Looking to take your team away on a retreat to enjoy awesome food and take in some sunshine? We have a list of places to do just that.</p>
      <a href="/appdevelopment" className="relative inline-block mt-4 text-sm group">
          <span className="block px-5 py-3 font-medium text-blue-900 transition-colors duration-300 ease-out border-2 border-blue-900 rounded-lg group-hover:text-white">
            Apply now
          </span>
        </a>
  
    </div>


  </div>
</div>
  </div>
</div>
<br></br>


<WhatsAppStickyButton />

    </section>
  );
}

export default Pastprogramlog;






