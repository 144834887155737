
import React from 'react';
import { Link } from 'react-router-dom';
// import './Altruistyservices.css';
import './others.css';
const GraphicDesigning = () => {
  return (
    <>
      <section className='bg-gray-100 p-8 pt-28 shadow-md graphic font-times' id='graphic'>
        <h1 className='text-2xl font-bold leading-tight mb-4'>Graphic Designing</h1>
        <p className='text-gray-700 mb-4'>A software solution is typically custom-built or configured to solve a specific customer problem. It could include: Custom software development. Customized Assembly of multiple software products.</p>
        <Link to='/form'>
          <button className='butt'>Contact</button>
        </Link>
      </section>
      
      <section className='bg-gray-100 p-8  shadow-md logo font-times' id='logo'>
        <h1 className='text-2xl font-bold mb-4'>Logo & Business Card Design</h1>
        <p className='text-gray-700 mb-4'>Make a lasting impression with a professionally designed logo and business card. Our creative team works closely with you to develop custom designs that capture your brand identity and leave a memorable impression on clients and customers.</p>
      </section>
      
      <section className='bg-gray-100 p-8  shadow-md animation font-times' id='animation'>
        <h1 className='text-2xl font-bold mb-4'>Animation</h1>
        <p className='text-gray-700 mb-4'>Bring your ideas to life with captivating animations that engage and entertain your audience. Our animators specialize in creating dynamic and compelling animations for various purposes, from promotional videos to explainer animations.</p>
      </section>
      
      <section className='bg-gray-100 p-8  shadow-md d font-times' id='d'>
        <h1 className='text-2xl font-bold mb-4'>3D Design</h1>
        <p className='text-gray-700 mb-4'>Explore new dimensions with our 3D design services. Whether you need architectural visualizations, product renderings, or character animations, our talented 3D artists bring your visions to life with stunning detail and realism.</p>
      </section>
      
      <section className='bg-gray-100 p-8  shadow-md editing font-times' id='editing'>
        <h1 className='text-2xl font-bold mb-4'>Photo & Video Editing</h1>
        <p className='text-gray-700 mb-4'>Enhance your visual content with our expert photo and video editing services. Whether you need retouching, color correction, or video production, our skilled editors ensure your content looks polished and professional.</p>
      </section>
      
      <section className='bg-gray-100 p-8   shadow-md poster font-times' id='poster'>
        <h1 className='text-2xl font-bold mb-4'>Poster & Brochure</h1>
        <p className='text-gray-700 mb-4'>From eye-catching posters to informative brochures, we design impactful print materials that effectively convey your message and captivate your audience. Our designs are crafted to stand out and leave a lasting impression.</p>
      </section>
      
      <section className='bg-gray-100 p-8  shadow-md prodectdeg font-times' id='prodectdeg'>
        <h1 className='text-2xl font-bold mb-4'>Product Design</h1>
        <p className='text-gray-700 mb-4'>From concept to production, we offer comprehensive product design services to bring your ideas to fruition. Our team works closely with you to develop innovative and functional product designs that meet your specific requirements and exceed your expectations.</p>
      </section>
    </>
  );
}

export default GraphicDesigning;
