import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from "react-router-dom";

import InpersonEvent from "./Pagecompos/Inpersonevent";
// Main Page Import
import Home from "../src/Pagecompos/Home";
// import Navbar from "./UIcomp/Navbar";
import Ipss from "./Pagecompos/Ipss";
import Services from "./Pagecompos/Services";
import Contactus from "./Pagecompos/Contactus";
import Aboutus from "./Pagecompos/Aboutus";
import AILaunchpad from "./Pagecompos/AILaunchpad";
import StartupLaunchpad from "./Pagecompos/StartupLaunchpad";
import Eventsprogram from "./Pagecompos/Eventsprogram";
import MusicalHub from "./Pagecompos/Musicalhub";
// SubPage Import
import AppDevelopment from "./SubPagesCompos/AltruistyServices/servicess/components/AppDevelopment.js";
import WebDevelopment from "./SubPagesCompos/AltruistyServices/servicess/components/WebDevelopment.js";
import DigitalMarketing from "./SubPagesCompos/AltruistyServices/servicess/components/DigitalMarketing.js";
import GraphicDesigning from "./SubPagesCompos/AltruistyServices/servicess/components/GraphicDesigning.js";
import Internshipprogram from "./SubPagesCompos/Internshipprogram";
import Eventsandcompetition from "./SubPagesCompos/Eventsandcompetition";
import Pastprogramlog from "./SubPagesCompos/Pastprogramlog";
import IdeatoProduct from "./SubPagesCompos/IdeatoProduct";
import PatentAndCopyrights from "./SubPagesCompos/PatentandCopyrights";
import Registrations from "./SubPagesCompos/Registrations";
import Fundings from "./SubPagesCompos/Fundings";
import InvestorConnect from "./SubPagesCompos/InvestorConnect";
import MyForm from "./unwanteds/form2";
import MusicalAbout from "./SubPagesCompos/MusicalAbout";
import MusicalCertifications from "./SubPagesCompos/MusicalCertifications";
import MusicalChoices from "./SubPagesCompos/MusicalChoices";
import MusicalCompetitions from "./SubPagesCompos/MusicalCompetitions";
import Symposiumevents from "./Pagecompos/Symposiumevents";
import Corporateevent from "./Pagecompos/Corporateevent";
import Otherevents from "./Pagecompos/Otherevents";
import UpcomingEvents from "./SubPagesCompos/Events and Competitions/UpcomingEvents";
import PastEvents from "./SubPagesCompos/Events and Competitions/PastEvents";
import EventPage from "./Pagecompos/EventPage";
import Package from "./Pagecompos/Package.js";
import SinglePrice from "./Pagecompos/SinglePrice.js";
import MapPage from "./UIcomp/MapPage";
import Verify from "./SubPagesCompos/Verify.js";
import Internbutton from "./SubPagesCompos/Internbutton.js"
import Altruistyservices from "./SubPagesCompos/AltruistyServices/servicess/components/Main.js";
import Servicebutton from "./Pagecompos/Servicebutton.js"
import Sidebar from "./UIcomp/Sidebar.js";
import Loadingpage from './SubPagesCompos/Loadingpage.js';
import Signin from './SubPagesCompos/Signin.js';
import Training from "./SubPagesCompos/Training.js";
import Mentorship from './SubPagesCompos/AltruistyServices/servicess/components/Mentorship.js';
import Incubation from './SubPagesCompos/AltruistyServices/servicess/components/Incubation.js';
//Login Page
import SkillCentre from "./Pages/SkillCentre.jsx";

//Font
import '@fontsource/poppins'; // Defaults to weight 400.
import '@fontsource/poppins/500.css'; // Weight 500.
import '@fontsource/poppins/600.css'; // Weight 600.
import '@fontsource/poppins/700.css'; // Weight 700.
import '@fontsource/inter'; // Defaults to weight 400.
import '@fontsource/inter/500.css'; // Weight 500.
import '@fontsource/inter/600.css'; // Weight 600.
import '@fontsource/inter/700.css'; // Weight 700.
import NavbarTemp from "./UIcomp/NavbarTemp.jsx";
import SkillCentreLogin from "./Pages/SkillCentreLogin.jsx";


import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';
import SkillCentreNavbar from "./UIcomp/SkillCentreNavbar.jsx";
import AltruistyStartupLaunchpad from "./Pages/AltruistyStartupLaunchpad.jsx";
import { AuthProvider, useAuth } from "./Components/AuthContext.jsx";
import SkillCentreTake from "./Pages/SkillCentreTake.jsx";


function Main({user}) {
	const location = useLocation();
	const isSkillCentreRoute = location.pathname.startsWith('/SkillCentre');
	const isSkillCentreLoginRoute = location.pathname === '/SkillCentreLogin';

	const PrivateRoute = ({ element, ...rest }) => {
        const { isLoggedIn } = useAuth();
        return isLoggedIn ? (
            <>
                {element}
            </>
        ) : (
            <Navigate to="/SkillCentreLogin" />
        );
    };

	return (
		<>
			{!isSkillCentreLoginRoute && (isSkillCentreRoute ? <SkillCentreNavbar /> : <NavbarTemp />)}
			{/* <Navbar /> */}
			<Routes>
			
				{/* Main Pages */}
				<Route path="/" element={<Home />} />
				<Route path="/Package" element={<Package />} />
				<Route path="/Package/singlePrice/:id" element={<SinglePrice />} />
				<Route path="/Services" element={<Services />} />
				<Route path="/Ipss" element={<Ipss />} />
				<Route path="/AILaunchPad" element={<AILaunchpad />} />
				<Route path="/StartupLaunchPad" element={<StartupLaunchpad />} />
				<Route path="/Eventsprogram" element={<Eventsprogram />} />
				<Route path="/Contactus" element={<Contactus />} />
				<Route path="/Aboutus" element={<Aboutus />} />
				<Route path="/MapPage" element={<MapPage />} />
				<Route path="/verify" element={<Verify/>} />
<Route path="/Altruistyservices" element={<Altruistyservices/>} />
				{/* Sub Pages */}
				<Route path="/training" element={<Training />} />
				<Route path="/appDevelopment" element={<AppDevelopment />} />
				<Route path="/webDevelopment" element={<WebDevelopment />} />
				<Route path="/graphicDesigning" element={<GraphicDesigning />} />
				<Route path="/digitalMarketing" element={<DigitalMarketing />} />
				<Route path="/internshipprogram" element={<Internshipprogram />} />
				<Route path="/servicebutton" element={<Servicebutton />} />
				<Route path="/Sidebar" element={<Sidebar />} />
				<Route path="/Loadingpage" element={<Loadingpage />} />
				<Route path="/Signin" element={<Signin />} />
        <Route path="/Mentorship" element={<Mentorship/>}/>
        <Route path="/Incubation" element={<Incubation/>}/>
<Route
					path="/eventsandcompetition"
					element={<Eventsandcompetition />}
				/>
				<Route path="/pastprogramlog" element={<Pastprogramlog />} />
				<Route path="/form" element={<MyForm />} />
				<Route path="/musicalhub" element={<MusicalHub />} />
				<Route path="/musicalAbout" element={<MusicalAbout />} />
				<Route path="/musicalCompetitions" element={<MusicalCompetitions />} />
				<Route
					path="/musicalCertifications"
					element={<MusicalCertifications />}
				/>
				<Route path="/musicalChoices" element={<MusicalChoices />} />
<Route path="/IdeatoProduct" element={<IdeatoProduct />}></Route>
				<Route
					path="/PatentAndCopyrights"
					element={<PatentAndCopyrights />}
				></Route>
				<Route path="/InvestorConnect" element={<InvestorConnect />}></Route>
				<Route path="/Registrations" element={<Registrations />}></Route>
				<Route path="/Fundings" element={<Fundings />}></Route>
				<Route path="/inperson" element={<InpersonEvent />} />
				<Route path="/symposium" element={<Symposiumevents />} />
				<Route path="/corporate" element={<Corporateevent />} />
				<Route path="/other" element={<Otherevents />} />
<Route path="/allUpcomingEvents" element={<UpcomingEvents />} />
				<Route path="/allPastEvents" element={<PastEvents />} />
				<Route path="/EventPage" element={<EventPage />} />
				<Route path="/Internbutton" element={<Internbutton/>}/>

				<Route path="/AltruistyStartupLaunchpad" element={<AltruistyStartupLaunchpad />} />

				<Route path="/SkillCentreLogin" element={<SkillCentreLogin />} />
                <Route path="/SkillCentre" element={<SkillCentre /> }/>
                <Route path="/SkillCentreTake" element={<PrivateRoute element={<SkillCentreTake />} />} />

			</Routes>
		</>
	)

}
function App() {
	const [user, setUser] = useState(null);


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
        });
        return () => unsubscribe();
    }, []);
	  

	return (
		<AuthProvider>
			<Router>
				<Main user={user} />
			</Router>
		</AuthProvider>
	);
}

export default App;
